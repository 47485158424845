import React from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import disciplinesService from "presentation/services/disciplines";

class ActivityCategoryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: true,
            localError: false,
            message: '',
            disciplines: []
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        try {
            await disciplinesService.findAll().then(async (result) => {
                if (result.error == true) {
                    this.setState({
                        localLoading: false,
                        localError: true,
                        message: result.message
                    });
                } else {
                    if (result.length == 0) {
                        this.setState({
                            localLoading: false,
                            localError: true,
                            message: "Não há disciplinas cadastradas. Para cadastrar categoria de atividades, por favor, cadastre ao menos uma disciplina...."
                        });
                    } else {
                        this.setState({ disciplines: result, localLoading: false, localError: false });
                    }
                }
            });
        } catch (err) {
            this.setState({
                localLoading: false,
                localError: true,
                message: err.message
            });
        }
    }

    render() {
        var { localLoading, localError, message, disciplines } = this.state;
        let { loading, form, handleChange, handleSubmit, component } = this.props;
        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (
                        <Form>
                            <h4>Informações da categoria de atividade</h4>
                            <div className="form mt-2">
                                <Form.Label htmlFor="discipline">Disciplina da categoria da atividade</Form.Label>
                                <Form.Select
                                    aria-label="Disciplina"
                                    defaultValue={form.disciplineId}
                                    id="disciplineId"
                                    name="disciplineId"
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="disciplineHelpBlock"
                                    required
                                >
                                    <option>Selecione uma disciplina</option>
                                    {disciplines.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                </Form.Select>
                                <Form.Text id="disciplineHelpBlock" muted>
                                    Defina a qual disciplina esse tópico pertence
                                </Form.Text>
                            </div>
                            <div className="form">
                                <Form.Label htmlFor="name">Nome</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="name"
                                    name="name"
                                    defaultValue={form.name}
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="nameHelpBlock"
                                />
                                <Form.Text id="nameHelpBlock" muted>
                                    Informe o nome da categoria
                                </Form.Text>
                            </div>
                            <div className="form mt-2">
                                <Form.Label htmlFor="displayName">Nome de exibição</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="displayName"
                                    name="displayName"
                                    defaultValue={form.displayName}
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="displayNameHelpBlock"
                                />
                                <Form.Text id="displayNameHelpBlock" muted>
                                    Escolha um nome de exibição
                                </Form.Text>
                            </div>
                            <div className="form mt-2">
                                <Form.Label htmlFor="code">Código</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="code"
                                    name="code"
                                    defaultValue={form.code}
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="codeHelpBlock"
                                />
                                <Form.Text id="codeHelpBlock" muted>
                                    Informe um código (opicional)
                                </Form.Text>
                            </div>
                            <div className="mt-3">
                                <Button as={Link} to={'/categorias-atividades'} variant="secondary">Mudei de ideia</Button>
                                <Button disabled={loading} variant="success" className="float-end" onClick={() => handleSubmit()}>Salvar</Button>
                            </div>
                        </Form>
                    )
                }
            </>
        );
    };

}

export default ActivityCategoryForm;