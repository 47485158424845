import React from "react";
import { Button } from "react-bootstrap";
import { setComponentFlag } from "presentation/utils/functions";
import { FaTrashAlt } from "react-icons/fa";

class RemoveAction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            waitingFeedback: false
        };
    }

    toggleActionInterface = () => {
        this.setState({ waitingFeedback: !this.state.waitingFeedback });
    }

    render() {
        let { deleteIsDisabled, loading, optionKey, removeAction, component } = this.props;
        let { waitingFeedback } = this.state;
        let { toggleActionInterface } = this;
        return (
            <div>
                {waitingFeedback === false && <Button
                    disabled={loading}
                    variant={deleteIsDisabled ? 'secondary' : 'danger'}
                    className="float-end"
                    
                    onClick={
                        () => deleteIsDisabled ? setComponentFlag(component, "deleteTip", true) : toggleActionInterface()}
                >
                    <FaTrashAlt />
                </Button>
                }
                {waitingFeedback === true && (
                    <div className="float-end">
                        <span>Quer mesmo remover? </span>
                        &nbsp;
                        <Button
                            variant='success'
                            onClick={() => { toggleActionInterface(); removeAction(optionKey); }}
                        >
                            Sim
                        </Button>
                        &nbsp;
                        <Button
                            variant='secondary'
                            onClick={toggleActionInterface}
                        >
                            Mudei de ideia
                        </Button>
                    </div>
                )}
            </div>
        );
    };
}

export default RemoveAction;


