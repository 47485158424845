import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FiMenu } from 'react-icons/fi';
import Nav from 'react-bootstrap/Nav';
import { Row, Col, Image, Form } from 'react-bootstrap';
import userPlaceHolder from 'presentation/assets/img/user.png';
import { getUser, logout } from 'presentation/utils/authentication';

import academicPeriodsService from "presentation/services/academic-periods";
import { handleResultData } from "presentation/utils/functions";

import { connect } from "react-redux";
import { add as addSessionParams } from "presentation/redux/modules/sessionParams/actions";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            academicPeriods: [],
            showSideBar: false,
            form: {
                academcPeriodId: null
            },
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    selectDefault = async () => {
        const { sessionParams } = this.props;
        const { academicPeriods } = this.state;

        let academicPeriodId = null;

        if (sessionParams.currentAcademicPeriod) {
            academicPeriodId = sessionParams.currentAcademicPeriod;
        } else {
            if (academicPeriods.length > 0) {
                academicPeriodId = academicPeriods[0].id;

                var data = { ...sessionParams };
                data.currentAcademicPeriod = academicPeriodId;
                await this.props.addSessionParams(data);
            }
        }

        this.setState({ form: { academicPeriodId: academicPeriodId }, loading: false });
    }

    loadData = async () => {
        const { enableAcademicPeriodSelect, selectDefault } = this;
        if (enableAcademicPeriodSelect()) {
            try {
                await academicPeriodsService.findAll().then(async (result) => {
                    let academicPeriods = handleResultData(result);
                    if (result.error == true) {
                        this.setState({
                            loading: false,
                            actionLoading: false,
                            error: true,
                            message: result.message
                        });
                    } else {
                        this.setState({ academicPeriods: academicPeriods }, async () => {
                            await selectDefault();
                        });
                    }

                });
            } catch (err) {
                this.setState({
                    loading: false,
                    actionLoading: false,
                    error: true,
                    message: err.message
                });
            }
        } else {
            this.setState({ loading: false });
        }

    }

    handleChange = async (e) => {
        const { sessionParams } = this.props;
        let { value } = e.target;
        var data = { ...sessionParams };

        data.currentAcademicPeriod = value;

        await this.props.addSessionParams(data);

        this.setState({ form: { academicPeriodId: value } });
    }

    toggleSidebar = () => {
        const { showSideBar } = this.state;
        this.setState({ ...this.state, showSideBar: !showSideBar });

    }

    appLogout = async () => {
        const { history } = this.props;
        await logout();
        history.push('/login');
    }

    enableAcademicPeriodSelect = () => {
        const user = getUser();

        return user.isMaster || user.isTeacher;
    }

    getMenuItems = () => {
        const user = getUser();

        const menuItems = [
            { id: 1, title: ' Alunos', destination: "/alunos", roles: ['master'] },
            { id: 2, title: ' Colaboradores', destination: "/colaboradores", roles: ['master'] },
            { id: 3, title: ' Cursos', destination: "/cursos", roles: ['master'] },
            { id: 4, title: ' Níveis/Segmentos', destination: "/niveis-segmentos", roles: ['master'] },
            { id: 5, title: ' Disciplinas', destination: "/disciplinas", roles: ['master'] },
            { id: 6, title: ' Turmas', destination: "/turmas", roles: ['master'] },
            { id: 7, title: ' Categorias de atividades', destination: "/categorias-atividades", roles: ['master', 'teacher'] },
            { id: 8, title: ' Atividades', destination: "/atividades", roles: ['master', 'teacher'] },
            { id: 9, title: ' Materiais', destination: "/materiais", roles: ['master', 'teacher'] },
            { id: 10, title: ' Dificudade de questões', destination: "/dificuldades-questoes", roles: ['master'] },
            { id: 11, title: ' Tópicos de questões', destination: "/topicos-questoes", roles: ['master'] },
            { id: 12, title: ' Origem de questões', destination: "/origens-questoes", roles: ['master'] },
            { id: 13, title: ' Questões', destination: "/questoes", roles: ['master'] },
            { id: 14, title: ' Períodos acadêmicos', destination: "/periodos-academicos", roles: ['master'] },
            { id: 15, title: ' Relatórios', destination: "/relatorios", roles: ['master'] },
        ];

        var userMenu = [];

        if (user.roles.length > 0) {
            user.roles.forEach((e, i) => {
                var roleMenu = menuItems.filter(x => x.roles.includes(e.machineName));
                roleMenu.forEach((j, i) => {
                    var currentWithoutItem = userMenu.filter(y => y.id !== j.id);
                    userMenu = [...currentWithoutItem, j]
                });
            });
        }

        return (
            <>
                {userMenu.map((e, i) => {
                    return (<Nav.Link className="sidebar-link font-promo" key={e.id} as={NavLink} to={e.destination}>{e.title}</Nav.Link>)
                })}
            </>
        );
    }

    render() {
        const { toggleSidebar, appLogout, handleChange, enableAcademicPeriodSelect, getMenuItems } = this;
        const { showSideBar, academicPeriods, form, loading } = this.state;
        const user = getUser();

        const isAcacademicPeriodSelectAvailable = enableAcademicPeriodSelect();

        return (
            <div>
                <Navbar bg="light" expand="xs">
                    <Container>
                        <Button size="lg" variant="link" className="link-primary" onClick={toggleSidebar}>
                            <FiMenu />
                        </Button>{' '}
                        <Navbar.Brand as={NavLink} to="/">
                            Basico
                        </Navbar.Brand>

                        {isAcacademicPeriodSelectAvailable == true ? (
                            <Form className="justify-content-end">
                                {loading == false && (<Form.Select
                                    aria-label="Período Acadêmico"
                                    defaultValue={form.academicPeriodId}
                                    id="academicPeriodId"
                                    name="academicPeriodId"
                                    onChange={(e) => { handleChange(e) }}
                                    required
                                >
                                    {academicPeriods.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                </Form.Select>)}
                            </Form>) : (<p> </p>)}

                    </Container>
                </Navbar>
                <Offcanvas show={showSideBar} onHide={toggleSidebar}>
                    <Offcanvas.Header closeButton closeVariant='white' id="side-menu-header">
                        <Row>
                            <Col xs={3}>
                                <Image src={userPlaceHolder} roundedCircle className='side-menu-header-avatar' />
                            </Col>
                            <Col xs={7}>
                                <p className="side-menu-header-user-name font-promo">{user.person ? user.person.displayName : user.email}<br />
                                    <small>
                                        <Button className="side-menu-header-logout font-promo-light" size="sm" variant='link' onClick={(e) => appLogout()}>Sair</Button>
                                    </small>
                                </p>
                            </Col>
                        </Row>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav defaultActiveKey="/" className="flex-column">
                            <Nav.Link as={NavLink} to="/" className="sidebar-link font-promo"> Principal</Nav.Link>
                            {getMenuItems()}
                        </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </div >
        );
    };
}

//Configs Redux para este component
//
//Define quais atributos vou pegar do
//state do Redux
const mapStateToProps = (state) => {
    const { data } = state.sessionParams;
    return { sessionParams: data };
};


//Define quais ações esse component
//vai usar para interagir com o Redux
const mapDispatchToProps = (dispatch) => {
    return {
        addSessionParams: (sessionParams) => dispatch(addSessionParams(sessionParams))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);