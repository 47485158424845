import React from "react";
import { Container, Row, Col, Button, Card, Badge, Form } from "react-bootstrap";
import { getUser } from "presentation/utils/authentication";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import { connect } from "react-redux";

import { handleResultData } from "presentation/utils/functions";

import { getReportTypes, renderReportFilters } from "presentation/pages/reports/functions/reports";

import classroomsService from "presentation/services/classrooms";
import disciplinesService from "presentation/services/disciplines";

import { EMPTY_SELECT_OPTION } from "presentation/model/constants";

class ReportMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            actionLoading: false,
            error: false,
            message: '',
            data: [],
            resultsData: [],
            showConfirmModal: false,
            showAddStudentModal: false,
            targetDeleteId: null,
            activityDates: [],
            activityDateform: {},
            activityData: {
                classroomName: '',
                activityData: '',
            }
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    async componentDidUpdate(prevProps, prevState) {
        const { sessionParams } = this.props;
        const { sessionParams: previousSessionParams } = prevProps;

        if (sessionParams.currentAcademicPeriod != previousSessionParams.currentAcademicPeriod) {
            await this.loadData();
        }
    }

    loadData = async () => {

        this.setState({ loading: true }, async () => {
            const { sessionParams } = this.props;
            if (sessionParams.currentAcademicPeriod) {
                let disciplines = [];
                let classrooms = [];
                let localError = false;
                let errorMessages = [];

                await disciplinesService.findAll().then((disciplinesResults) => {
                    if (disciplinesResults.error == true) {
                        localError = true;
                        errorMessages.push("Não foi possível recuperar as disciplinas...");
                    } else {
                        disciplines = [EMPTY_SELECT_OPTION, ...disciplinesResults];
                    }
                });

                await classroomsService.search({ academicPeriodId: [parseInt(sessionParams.currentAcademicPeriod)] }).then((result) => {
                    if (result.error == true) {
                        localError = true;
                        errorMessages.push("Não foi possível recuperar as turmas...");
                    } else {
                        let handledResult = handleResultData(result);
                        classrooms = [EMPTY_SELECT_OPTION, ...handledResult];
                    }

                });

                if (localError == false) {
                    this.setState({ loading: false, error: false, message: '', disciplines: disciplines, classrooms: classrooms });
                } else {
                    this.setState({ loading: false, error: true, message: errorMessages.join(" / ") });
                }
            } else {
                this.setState({ error: true, loading: false, message: "Não foi possível carregar as informações da turma..." });
            }
        });


    }

    changeReportType = (e) => {
        let value = e.target.value;
        this.setState({ reportType: value });
    }


    render() {

        const {
            loading,
            actionLoading,
            error,
            message,
            classrooms,
            disciplines,
            reportType
        } = this.state;

        const {history} = this.props;

        const { changeReportType } = this;

        const reportTypes = getReportTypes();

        return (
            <div>
                {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
                {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {loading === false && (
                    <Container>
                        <Row>
                            <Col>
                                <h3>Tipo de relatório</h3>
                            </Col>
                            <Col>
                                <form>
                                    <Form.Select
                                        aria-label="Relatório"
                                        id="reportType"
                                        name="reportType"
                                        onChange={changeReportType}
                                        value={reportType || undefined}
                                        aria-describedby="documentFieldTypeHelpBlock"
                                    >
                                        {reportTypes.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                    </Form.Select>

                                </form>
                            </Col>
                        </Row>
                        <Row>
                            {renderReportFilters(reportType, { classrooms, disciplines, history})}
                        </Row>
                    </Container>
                )}
            </div>
        );
    };
}

//Configs Redux para este component
//
//Define quais atributos vou pegar do
//state do Redux
const mapStateToProps = (state) => {
    const { data } = state.sessionParams;
    return { sessionParams: data };
};


//Define quais ações esse component
//vai usar para interagir com o Redux
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(ReportMain);