import React from "react";
import { Container, Row, Col, Button, Card, Badge, Form } from "react-bootstrap";
import { getUser } from "presentation/utils/authentication";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import { handleResultData } from "presentation/utils/functions";

import StudentActivitiesModal from 'presentation/pages/principal/dashboards/students/elements/activities-modal';
import StudentMediaFilesModal from 'presentation/pages/principal/dashboards/students/elements/media-files-modal';

import activitiesService from "presentation/services/activities";

import { checkAvailability } from 'presentation/pages/activities/functions/activities';

class ResponsibleDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            actionLoading: false,
            error: false,
            message: '',
            data: [],
            scoreData: [],
            dependents: [],
            selectedDependent: null,
            showStudentActivitiesModal: false,
            showStudentMediaFilesModal: false,
            directories: [],
            classroomName: '',
            disciplineName: '',
            classroomId: null,
            disciplineId: null
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        const { formatData } = this;
        let data = [];
        let scoreData = [];
        const { selectedDependent } = this.state;

        this.setState({ loading: true });
        const userInfo = getUser();

        if (userInfo.responsibleInfo != null && userInfo.responsibleInfo.dependents && userInfo.responsibleInfo.dependents.length > 0) {

            var dependents = userInfo.responsibleInfo.dependents;

            var studentId = dependents[0].id;

            if (selectedDependent) {
                studentId = selectedDependent;
            }

            var activities = await activitiesService.studentActivities(studentId).then((result) => {
                let handledResult = handleResultData(result);
                data = formatData(handledResult);
            });

            var scores = await activitiesService.studentActivitiesScores(studentId).then((scoresResults) => {
                if (scoresResults) {
                    scoreData = handleResultData(scoresResults);
                }

            });
            await Promise.all([activities, scores]);
            this.setState({ scoreData: scoreData, data: data, loading: false, dependents: dependents, selectedDependent: studentId });
        } else {

        }


    }

    formatData = (rawData) => {
        let data = [];

        rawData.map((e, i) => {
            if (!data[e.classroomId]) {
                data[e.classroomId] = {
                    id: e.classroomId,
                    name: e.classroomName,
                    disciplines: []
                };
            }

            if (!data[e.classroomId].disciplines[e.disciplineId]) {
                data[e.classroomId].disciplines[e.disciplineId] = {
                    id: e.disciplineId,
                    name: e.disciplineName,
                    availableActivities: 0,
                    directories: []
                };
            }

            var directoryId = e.activityCategoryId == null ? 0 : 1 + e.activityCategoryId;
            var directoryName = e.activityCategoryId == null ? " Principal " : e.activityCategoryName;

            if (!data[e.classroomId].disciplines[e.disciplineId].directories[directoryId]) {
                data[e.classroomId].disciplines[e.disciplineId].directories[directoryId] = {
                    id: directoryId,
                    name: directoryName,
                    activities: []
                };
            }

            var avInfo = checkAvailability(e);

            e.isAvailable = avInfo.isAvailable;
            e.availabilityMessage = avInfo.message ? avInfo.message : null;

            if (e.isAvailable == true) {
                data[e.classroomId].disciplines[e.disciplineId].availableActivities++;
            }

            data[e.classroomId].disciplines[e.disciplineId].directories[directoryId].activities.push(e);
        });
        return data;
    }

    openAcitvitiesModal = (classroomName, disciplineName, directories, scores) => {
        this.setState({
            showStudentActivitiesModal: true,
            classroomName: classroomName,
            disciplineName: disciplineName,
            directories: directories,
            scores: scores
        });
    }

    closeActivitiesModal = () => {
        this.setState({
            showStudentActivitiesModal: false,
            classroomName: '',
            disciplineName: '',
            directories: [],
            scores: []
        });
    }

    openMediaFilesModal = (classroomName, disciplineName, classroomId, disciplineId) => {
        this.setState({
            showStudentMediaFilesModal: true,
            classroomName: classroomName,
            disciplineName: disciplineName,
            classroomId: classroomId,
            disciplineId: disciplineId
        });
    }

    closeMediaFilesModal = () => {
        this.setState({
            showStudentMediaFilesModal: false,
            classroomName: '',
            disciplineName: '',
            classroomId: null,
            disciplineId: null
        });
    }

    changeDependent = (e) => {
        var selectedValue = e.target.value;
        this.setState({ selectedDependent: selectedValue }, () => {
            this.loadData();
        });
    }

    render() {

        const {
            openAcitvitiesModal,
            closeActivitiesModal,
            openMediaFilesModal,
            closeMediaFilesModal

        } = this;
        const {
            loading,
            actionLoading,
            error,
            message,
            data,
            scoreData,
            showStudentActivitiesModal,
            showStudentMediaFilesModal,
            directories,
            classroomId,
            classroomName,
            disciplineId,
            disciplineName,
            scores,
            selectedDependent,
            dependents
        } = this.state;
        const { changeDependent } = this;
        return (
            <div>
                {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
                {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
                {loading === false && (
                    <Container>
                        <Row>
                            <Col>
                                <h3>Visualizando informações do aluno</h3>
                            </Col>
                            <Col>
                                <form>
                                    <Form.Select
                                        aria-label="Aluno"
                                        defaultValue={selectedDependent}
                                        id="selectedDependent"
                                        name="selectedDependent"
                                        onChange={changeDependent}
                                        aria-describedby="documentFieldTypeHelpBlock"
                                    >
                                        {dependents.map((e, i) => { return <option key={e.id} value={e.id}>{e.person?.name}</option> })}
                                    </Form.Select>
                                </form>
                            </Col>
                        </Row>
                        {data.map((classroom, index) => {
                            return (
                                <Row key={index}>
                                    <h4 className={index > 0 ? "mt-5" : ""} >{classroom.name}</h4>
                                    {classroom.disciplines.map((discipline, disciplineIndex) => {
                                        var disciplineScores = scoreData.filter(x => x.disciplineId == discipline.id && x.classroomId == classroom.id);
                                        return (
                                            <Col key={disciplineIndex} className="mt-4" lg={4} md={4}>
                                                <Card border={discipline.border}>
                                                    <Card.Body>
                                                        <Card.Title>{discipline.name}</Card.Title>
                                                        <Card.Text>
                                                            {discipline.description}
                                                        </Card.Text>
                                                    </Card.Body>
                                                    <Card.Footer>
                                                        <Button className="float-end" size="sm" variant="outline-success" onClick={(e) => openAcitvitiesModal(classroom.name, discipline.name, discipline.directories, disciplineScores)}>
                                                            atividades&nbsp;
                                                            {discipline.availableActivities > 0 && (
                                                                <span>
                                                                    <Badge bg="secondary">{discipline.availableActivities}</Badge>
                                                                    <span className="visually-hidden">atividades disponíveis</span>
                                                                </span>
                                                            )}
                                                        </Button>
                                                        <Button className="float-start" size="sm" variant="outline-success" onClick={(e) => openMediaFilesModal(classroom.name, discipline.name, classroom.id, discipline.id)}>materiais</Button>
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            );
                        })}
                        {showStudentActivitiesModal == true && (
                            <StudentActivitiesModal
                                show={showStudentActivitiesModal}
                                onClose={closeActivitiesModal}
                                directories={directories}
                                scores={scores}
                                readOnly={true}
                                classroomName={classroomName}
                                disciplineName={disciplineName}
                            />
                        )}
                        {showStudentMediaFilesModal == true && (
                            <StudentMediaFilesModal
                                show={showStudentMediaFilesModal}
                                onClose={closeMediaFilesModal}
                                studentId={selectedDependent}
                                classroomId={classroomId}
                                disciplineId={disciplineId}
                                classroomName={classroomName}
                                disciplineName={disciplineName}
                            />
                        )}
                    </Container>
                )}
            </div>
        );
    };
}

export default ResponsibleDashboard;