import React from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";

import { handleResultData, handleTaleCheckAll, handleCheckTableItems } from "presentation/utils/functions";

import courseDisciplinesService from "presentation/services/course-disciplines";
import disciplinesEmployeesService from "presentation/services/disciplines-employees";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import EmployeeSearchBox from "presentation/pages/employees/elements/employee-search-box";

import PageAlert from "presentation/components/page-alert";


class AddTeacherModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            message: '',
            loading: false,
            filterForm: {},
            selectedItems: [],
            disciplines: [],
            employee: { id: null },
        };

        this.tDisciplineBodyRef = React.createRef(null);
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        const { entity } = this.props;
        const { courseId, academicPeriodId } = entity;

        await courseDisciplinesService.search({ courseId: [parseInt(courseId)], academicPeriodId: [parseInt(academicPeriodId)] }).then((result) => {
            let handledResult = handleResultData(result);
            this.setState({ disciplines: handledResult, loading: false });
        });
    }

    onSelectItem = (selectedItem) => {
        this.setState({ ...this.state, employee: selectedItem });
    }

    onSubmit = async () => {

        let { entityId, onClose, loadData } = this.props;
        let { selectedItems, employee } = this.state;

        this.setState({ loading: true, error: '' });

        let employeeId = (employee != null && employee.id != null) ? employee.id : null;

        if (employeeId == null) {

            this.setState({ loading: false, error: true, message: "Por favor, informe um professor..." });
        } else {
            if (selectedItems.length < 1) {
                this.setState({ loading: false, error: true, message: "Por favor, selecione ao menos uma disciplina..." });
            } else {
                let postData = selectedItems.map((e, i) => {
                    return {

                        employeeId: employeeId,
                        classroomId: entityId,
                        disciplineId: e,
                    }
                });

                try {
                    const response = await disciplinesEmployeesService.batchAdd(postData);
                    if (response.error == true) {
                        this.setState({
                            loading: false,
                            error: true,
                            message: response.message
                        });
                    } else {
                        this.setState({
                            loading: false,
                            error: '',
                        }, () => {
                            onClose();
                            loadData(entityId);
                        });
                    }
                } catch (err) {
                    this.setState({
                        loading: false,
                        actionLoading: false,
                        error: true,
                        message: err.message
                    });
                }
            }
        }
    };

    render() {
        let { show, onClose, entityName } = this.props;
        let { loading, selectedItems, disciplines, employee, error, message } = this.state;
        const { onSubmit, tDisciplineBodyRef, onSelectItem } = this;
        const hasSelection = (employee != null && employee.id != null && selectedItems.length > 0) ? true : false;
        return (
            <Modal fullscreen backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar professor na turma {entityName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                    {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                    {loading === false && (
                        <>
                            <EmployeeSearchBox onSelect={onSelectItem} />
                            <br />
                            {employee != null && employee.id != null && disciplines.length > 0 && (
                                <>
                                    <p className="text-center"><strong>Selecione um ou mais disciplinas para o professor</strong></p>
                                    <Table bordered borderless className="mt-4">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "5%" }}><Form.Check className="row-checkbox" type="checkbox" onChange={(e) => handleTaleCheckAll(e, this, tDisciplineBodyRef)} /></th>
                                                <th>Id</th>
                                                <th>Disciplina</th>
                                            </tr>
                                        </thead>
                                        <tbody ref={tDisciplineBodyRef}>
                                            {disciplines.length > 0 && disciplines.map((element, index) => {

                                                return (
                                                    <tr key={element.discipline.id}>
                                                        <td><Form.Check className="row-checkbox" type="checkbox" onChange={(e) => handleCheckTableItems(e, element.discipline.id, this)} data-option-value={element.discipline.id} /></td>
                                                        <td>{element.discipline.id}</td>
                                                        <td>{element.discipline.name}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={onClose}>
                        Mudei de ideia
                    </Button>
                    <Button disabled={loading || !hasSelection} variant="success" onClick={() => onSubmit()}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default AddTeacherModal;