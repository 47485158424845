import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { handleResultData, handleChange } from "presentation/utils/functions";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import ExcelExport from "presentation/components/excel-export";

import { EMPTY_SELECT_OPTION } from "presentation/model/constants";

import activitiesService from "presentation/services/activities";
import { Link } from "react-router-dom/cjs/react-router-dom";

class ActivityResultsReportFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterForm: {
        classroomId: null,
        activityId: null,
        disciplineId: null
      },
      loading: false,
      actionLoading: false,
      error: false,
      message: '',
      activities: [],
      data: [],
      resultsData: [],
      activityData: []
    };
  }
  loadActivities = async () => {
    let { filterForm } = this.state;
    let { disciplineId, classroomId, startDate, endDate } = filterForm;
    let loadedActivities = [];
    let localError = false;
    let errorMessage = '';

    this.setState({ loading: true });

    if (disciplineId != null && classroomId != null && disciplineId > 0 && classroomId > 0) {

      var searchForm = {
        disciplineId: [parseInt(disciplineId)],
        classroomId: [parseInt(classroomId)]
      }

      if (startDate != undefined && startDate != "") {
        searchForm.startDate = startDate;
      }

      if (endDate != undefined && endDate != "") {
        searchForm.endDate = endDate;
      }

      await activitiesService.search(searchForm).then((re) => {
        let handled = handleResultData(re);
        if (handled.error == true) {
          localError = true;
          errorMessage = handled.message;
        } else {
          if (handled.length > 0) {

            loadedActivities = [EMPTY_SELECT_OPTION, ...handled];
          } else {
            localError = true;
            errorMessage = 'Não há atividades para o filtro informado. Por favor, tente selecionar outras opções...'
          }

        }
      });
    }

    this.setState({ loading: false, error: localError, activities: loadedActivities, message: errorMessage });
  }

  loadActivity = async () => {
    var errorMessages = [];
    var errorMessage = '';
    var hasError = false;

    let { filterForm } = this.state;
    let { activityId } = filterForm;


    if (activityId != null && activityId > 0) {
      this.setState({ loading: true });
      let id = activityId;

      var baseData = await activitiesService.activityStudents(id).then((result) => {
        let response = handleResultData(result);

        let activityDates = [];

        if (response.length > 0) {
          response.map((e, i) => {
            let dateOverrideId = e.activityDateOverrideId || undefined;
            let item = {
              isRemove: false,
              id: dateOverrideId,
              key: e.studentId,
              studentId: e.studentId,
              activityId: e.activityId,
              classroomId: e.classroomId,
              disciplineId: e.disciplineId,
              startDate: dateOverrideId ? e.activityOverrideStartDate : undefined,
              endDate: dateOverrideId ? e.activityOverrideEndDate : undefined
            }
            activityDates.push(item);
          });
        }


        const activityData = response.length > 0 ? response[0] : {
          classroomName: null,
          disciplineName: null,
          classroomId: null,
          disciplineId: null,
          academicPeriodId: null,
          activityId: null,
          activityTitle: null,
          activityStartDate: null,
          activityEndDate: null,
        };

        const activityDateForm = {
          id: activityData.activityId,
          startDate: activityData.activityStartDate,
          endDate: activityData.activityEndDate,
          title: activityData.activityTitle,
          academicPeriodId: activityData.academicPeriodId,
          classroomId: activityData.classroomId,
          disciplineId: activityData.disciplineId,
        }

        if (response.error == true) {
          errorMessages.push(response.message);
        } else {
          this.setState({ data: response, activityDates: activityDates, activityData: activityData, activityDateForm: activityDateForm });
        }
      });

      var results = await activitiesService.activityResults({ activityId: [parseInt(id)] }).then((re) => {
        let handled = handleResultData(re);

        if (handled.error == true) {
          errorMessages.push(handled.message);
        } else {
          this.setState({ resultsData: handled });
        }

      });

      await Promise.all([results, baseData]);

      if (errorMessages.length > 0) {
        hasError = true;
        errorMessage = errorMessages.join(" / ");
      }

      this.setState({ loading: false, error: hasError, message: errorMessage });
    } else {
      this.setState({ activityData: [] });
    }

  }

  formatToExport = (data, handledResultData) => {
    let output = [];

    data.map((element, index) => {
      var hasAnswer = element.activityWasAnswered == true;
      var studentResults = handledResultData.filter(x => x.studentId == element.studentId)[0] || {};
      output.push({
        "Matrícula": element.studentRegistration,
        "Nome": element.studentName,
        "Realizada?": hasAnswer == true ? ' sim ' : 'não',
        "Nota": studentResults.evaluatedScore || ' - ',
        "Total acertos": studentResults.totalCorrectAnswers || ' - ',
        "% Aproveitamento": studentResults.evaluatedSuccessRate || ' - ',
      }
      );
    })
    return output;
  };

  render() {

    const { disciplines, classrooms } = this.props;
    const { activities, data, resultsData, activityData, loading, actionLoading, error, message, filterForm } = this.state;

    const { formatToExport, loadActivities, loadActivity } = this;
    const handledResultData = resultsData.length > 0 ? resultsData : [];
    var actionDisabled = activityData.activityId == null ? true : false;
    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <Container>
            <Row className="mt-4">
              <Col>
                <h3>Turma</h3>
                <Form.Select
                  aria-label="Turma"
                  id={`classroomId`}
                  name={`classroomId`}
                  onChange={(e) => handleChange(e, this, 'filterForm', loadActivities)}
                  value={filterForm.classroomId || undefined}
                  data-field-name="classroomId"
                >
                  {classrooms.map((e, i) => { return <option key={e.name} value={e.id}>{e.name}</option> })}
                </Form.Select>
              </Col>
              <Col>
                <h3>Disciplina</h3>
                <Form.Select
                  aria-label="Disciplina"
                  id={`disciplineId`}
                  name={`disciplineId`}
                  onChange={(e) => handleChange(e, this, 'filterForm', loadActivities)}
                  value={filterForm.disciplineId || undefined}
                  data-field-name="disciplineId"
                >
                  {disciplines.map((e, i) => { return <option key={e.name} value={e.id}>{e.name}</option> })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mt-4">
              <h3>Atividade</h3>
              <Col>
                <Row>
                  <Col size={6}>
                    <Form.Label htmlFor="code">Data de início</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      id="startDate"
                      name="startDate"
                      defaultValue={filterForm.startDate}
                      onChange={(e) => handleChange(e, this, 'filterForm')}
                      onBlur={(e) => loadActivities()}
                      aria-describedby="startDateHelpBlock"
                    />
                  </Col>
                  <Col size={6}>
                    <Form.Label htmlFor="code">Data fim</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      id="endDate"
                      name="endDate"
                      defaultValue={filterForm.endDate}
                      onChange={(e) => handleChange(e, this, 'filterForm')}
                      onBlur={(e) => loadActivities()}
                      aria-describedby="endDateHelpBlock"
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Label htmlFor={`classroomId`}>Atividade</Form.Label>
                <Form.Select
                  aria-label="Atividade"
                  id={`activityId`}
                  name={`activityId`}
                  onChange={(e) => handleChange(e, this, 'filterForm', loadActivity)}
                  value={filterForm.activityId || undefined}
                  data-field-name="activityId"
                >
                  {activities.map((e, i) => { return <option key={e.title} value={e.id}>{e.title}</option> })}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4">
                <Button as={Link} variant="outline-success" disabled={actionDisabled} className={`m-l-1 float-end${actionDisabled == true ? ' disabled': ''}`} target="_blank" to={{
                  pathname: `relatorios/atividades-aproveitamento/${activityData.activityId}`
                }}>Emitir PDF</Button>
                < ExcelExport disabled={actionDisabled} excelData={formatToExport(data, handledResultData)} fileName={`relatorio-aproveitamento-${activityData.classroomName}-atividade-${activityData.activityId}`
                } className="float-end" title="Emitir planilha" />
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  };
}

export default ActivityResultsReportFilter;
