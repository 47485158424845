import activitiesService from "presentation/services/activities";
import { goTo } from "presentation/utils/functions";
import { prepareInformationsRequests, preparePointsInformationsRequests } from 'presentation/pages/activities/functions/activity-questions';
import moment from "moment";


export const checkAvailability = (activityInformation) => {
    var output = {
        isAvailable: true,
        message: undefined
    }
    var startDate = undefined;
    var endDate = undefined;
    var today = moment();

    //Se tiver sobrescrita, o que vale é ela, independente se ser antes ou depois
    //do prazo padrão da atividade
    if (activityInformation.activityOverrideStartDate || activityInformation.activityOverrideEndDate) {

        startDate = activityInformation.activityOverrideStartDate ? moment(activityInformation.activityOverrideStartDate) : undefined;
        endDate = activityInformation.activityOverrideEndDate ? moment(activityInformation.activityOverrideEndDate) : undefined;
    } else {
        startDate = activityInformation.activityStartDate ? moment(activityInformation.activityStartDate) : undefined;
        endDate = activityInformation.activityEndDate ? moment(activityInformation.activityEndDate) : undefined;
    }

    if (startDate) {
        if (endDate) {
            output.message = `Disponível de ${startDate.format("DD/MM/YYYY HH:mm")} a ${endDate.format("DD/MM/YYYY HH:mm")}`;
        } else {
            output.message = `Disponível a partir de ${startDate.format("DD/MM/YYYY HH:mm")}`;
        }
    } else {
        if (endDate) {
            output.message = `Encerra em ${endDate.format("DD/MM/YYYY HH:mm")}`;
        }
    }


    //Se tiver data de início, e ainda a data atual não for maior
    //marca a atividade como indisponível
    if (startDate && today.isBefore(startDate)) {
        output.isAvailable = false;
        return output;
    }

    //Se não tem prazo padrão e nem sobrescrito, encerra após
    //ser responida a primeira vez
    if (!endDate && activityInformation.activityWasAnswered == true) {
        output.isAvailable = false;
        return output;
    }

    let term = activityInformation.activityOverrideEndDate ? activityInformation.activityOverrideEndDate : activityInformation.activityEndDate;
    //Se tem prazo, encerra após passar do mesmo
    if (term) {
        var termDateTime = moment(term);

        var isBefore = today.isSameOrBefore(termDateTime);

        output.isAvailable = isBefore;

    }

    return output;

}



export const mountAnswers = (rawAnswers) => {
    let answers = [];

    for (let i = 0; i < rawAnswers.length; i++) {
        let element = rawAnswers[i];
        let questionId = element.questionId;
        let answerItem = answers.filter(x => x.questionId == questionId)[0] || undefined;
        let questionOptionId = parseInt(element.questionOptionId);
        let observations = element.observations;
        let evaluationComments = element.evaluationComments;
        let successRate = element.successRate;

        if (answerItem) {
            if (!answerItem.questionOptionId.includes(questionOptionId)) {
                answerItem.questionOptionId.push(questionOptionId);
            } else {
                let currentOptions = answerItem.questionOptionId.filter(x => x !== questionOptionId);
                answerItem.questionOptionId = currentOptions;
            }
            answerItem.observations = observations;
            answerItem.evaluationComments = evaluationComments;
            answerItem.successRate = successRate;
        } else {
            answerItem = { questionId: questionId, questionOptionId: [questionOptionId], observations: observations, successRate: successRate, evaluationComments: evaluationComments };
        }

        var currentAnswers = answers.filter(x => x.questionId != answerItem.questionId);

        answers = [...currentAnswers, answerItem];

    }

    return answers;
}

export const handleSubmit = async (component, returnTo) => {
    const { form, selectedQuestions, questionInformations } = component.state;
    component.setState({ loading: true, actionLoading: true, error: '' });


    const {
        title,
        disciplineId,
        classroomId,
    } = form;

    let errors = [];

    if (!classroomId) {
        errors.push("Por favor, preencha uma turma");
    }

    if (!disciplineId) {
        errors.push("Por favor, preencha um disciplina");
    }

    if (!title) {
        errors.push("Por favor, preencha um título para a atividade");
    }

    let questionOptionsSize = selectedQuestions.filter(x => !(x.isDeleteRequested == true)).length;
    if (questionOptionsSize <= 0) {
        errors.push("Você precisa adicionar questões para essa atividade");
    }


    if (errors.length > 0) {
        component.setState({ loading: false, error: true, message: errors.join("; ") });
    } else {
        let postData = component.state.form;
        try {
            const response = await activitiesService.save(postData);
            if (response.error == true) {
                component.setState({
                    loading: false,
                    actionLoading: false,
                    error: true,
                    message: response.message
                });
            } else {
                await prepareInformationsRequests(response, selectedQuestions).then(async () => {
                    await preparePointsInformationsRequests(response, questionInformations);
                });

                goTo(returnTo, component);
            }
        } catch (err) {
            component.setState({
                loading: false,
                actionLoading: false,
                error: true,
                message: err.message
            });
        }
    }
};

const activitiesFunctions = {
    handleSubmit,
    mountAnswers,
    checkAvailability
}

export default activitiesFunctions;