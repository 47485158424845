import React from "react";
import PageHeader from "presentation/pages/layout/elements/page-header";
import { Container, Row, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import responsiblesService from "presentation/services/responsibles";
import studentsService from "presentation/services/students";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import ResponsibleForm from 'presentation/pages/responsibles/elements/form';

import { handleChange, getGender, getDocumentFieldType, dateIsInvalid } from "presentation/utils/functions";
import moment from "moment";
import form from "../activities/elements/form";

class ResponsibleEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
    };
    this.loadData = this.loadData.bind(this);

  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    await this.loadData(id);
  }

  loadData = async (id) => {
    const { dependentId, studentId } = this.props.match.params;
    var responsibleInfo = {};
    await studentsService.findById(studentId).then((studentResult) => {
      if (!(studentResult.error == true)) {
        responsibleInfo.dependentId = dependentId;
        responsibleInfo.studentName = studentResult.person?.name;
        responsibleInfo.studentRegistration = studentResult.registration;
      }

    });

    await responsiblesService.findById(id).then((result) => {
      responsibleInfo = {
        ...responsibleInfo,
        id: result.id,
        registration: result.registration,
        registrationDate: moment(result.registrationDate, 'YYYY-MM-DD').format("YYYY-MM-DD")
      }

      if (typeof (result.person) !== 'undefined') {
        var genderInfo = result.person.gender ? getGender(result.person.gender.toString().toLowerCase()) : undefined;
        var genderId = typeof (genderInfo) === 'undefined' ? -1 : genderInfo.id;

        var documentFieldTypeInfo = result.person.documentFieldType ? getDocumentFieldType(result.person.documentFieldType.toString().toLowerCase()) : undefined;
        var documentFieldTypeId = typeof (documentFieldTypeInfo) === 'undefined' ? -1 : documentFieldTypeInfo.id;

        responsibleInfo = {
          ...responsibleInfo,
          personId: result.person.id,
          userId: result.person.userId,
          name: result.person.name,
          displayName: result.person.displayName,
          gender: genderId,
          documentField: result.person.documentField,
          documentFieldType: documentFieldTypeId,
          birthdate: moment(result.person.birthdate, 'YYYY-MM-DD').format("YYYY-MM-DD")
        }
      }
      this.setState({ form: responsibleInfo, studentId: studentId, dependentId: dependentId, loading: false });
    });
  }

  goTo = (destination) => {
    const { history } = this.props;

    history.push(destination);
  };

  handleSubmit = async () => {
    const { dependentId, studentId } = this.props.match.params;
    this.setState({ loading: true, actionLoading: true, error: '' });

    const {
      name,
    } = this.state.form;

    if (!name) {
      this.setState({ loading: false, error: true, message: "Por favor, preencha um nome..." });
    } else {
      let postData = this.state.form;

      if (dateIsInvalid(postData.birthdate)) {
        postData.birthdate = null;
      }

      try {
        const response = await responsiblesService.saveAssociated(postData);
        if (response.error == true) {
          this.setState({
            loading: false,
            actionLoading: false,
            error: true,
            message: response.message
          });
        } else {
          this.goTo(`/responsaveis/${dependentId}/${studentId}`);
        }
      } catch (err) {
        this.setState({
          loading: false,
          actionLoading: false,
          error: true,
          message: err.message
        });
      }
    }
  };

  render() {
    let { location } = this.props;
    let { loading, error, message, actionLoading, form, dependentId, studentId } = this.state;
    let { handleSubmit } = this;

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <Container>
            <Row>
              <ResponsibleForm
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                dependentId={dependentId}
                studentId={studentId}
                loading={loading}
                form={form}
                component={this}
              />
            </Row>
          </Container>
        )
        }
      </div>
    );
  };
}

export default ResponsibleEdit;
