import questionsService from "presentation/services/questions";
import { goTo, getQuestionType } from "presentation/utils/functions";
import questionOptionsFunctions from 'presentation/pages/questions/functions/question-options';
import questionInformationsFunctions from 'presentation/pages/questions/functions/question-informations';
import questionDisciplineInformationsFunctions from 'presentation/pages/questions/functions/question-discipline-informations';

import moment from "moment";

export const yearsList = () => {
    let currentYear = moment().add(1, "year").format("yyyy");
    let output = [{ year: currentYear }];

    for (let i = 1; i <= 50; i++) {
        output.push({ year: moment(currentYear).add(-i, 'year').format("yyyy") });
    }
    return output;
}

export const handleSubmit = async (component) => {
    const { form, questionOptions, questionInformations, questionDisciplineInformations } = component.state;
    component.setState({ loading: true, actionLoading: true, error: '' });

    const {
        title,
        isDiscursive
    } = form;
    let questionIsDiscursive = isDiscursive || false;
    let errors = [];

    if (!title) {
        errors.push("Por favor, preencha um enunciado...");
    }

    let handledQuestionOptions = questionOptions;
    
    if (questionIsDiscursive == true) {
        var qOptions = questionOptions.filter(x => x.key == 1);
        qOptions[0].isCorrect = true;
        handledQuestionOptions = qOptions;
    }

    let questionOptionsSize = handledQuestionOptions.length;
    if (questionOptionsSize > 0) {
        let count = handledQuestionOptions.filter((option) => option.title === '').length;
        if (count > 0) {
            errors.push("Uma ou mais campos de resposta/alternativa estão vazios...");
        }
    } else {
        errors.push("Você precisa adicionar respostas/alternativas para essa questão...");
    }


    if (errors.length > 0) {
        component.setState({ loading: false, error: true, message: errors.join(" <br />") });
    } else {
        let postData = component.state.form;
        try {
            const response = await questionsService.save(postData);
            if (response.error == true) {
                component.setState({
                    loading: false,
                    actionLoading: false,
                    error: true,
                    message: response.message
                });
            } else {
                let promises = questionOptionsFunctions.prepareOptionsRequests(response, handledQuestionOptions);
                if (!promises) {
                    component.setState({
                        loading: false,
                        actionLoading: false,
                        error: true,
                        message: 'Erro ao tentar criar as alternativas da questão...'
                    });
                } else {
                    let infoPromises = questionInformationsFunctions.prepareInformationsRequests(response, questionInformations);
                    if (!infoPromises) {
                        component.setState({
                            loading: false,
                            actionLoading: false,
                            error: true,
                            message: 'Erro ao tentar cadastrar as informações da questão...'
                        });
                    } else {
                        let disciplineInfoPromises = questionDisciplineInformationsFunctions.prepareInformationsRequests(response, questionDisciplineInformations);
                        if (!disciplineInfoPromises) {
                            component.setState({
                                loading: false,
                                actionLoading: false,
                                error: true,
                                message: 'Erro ao tentar cadastrar as disciplinas da questão...'
                            });
                        } else {
                            await Promise.all(disciplineInfoPromises);
                        }
                        await Promise.all(infoPromises);
                        goTo("/questoes", component);
                    }
                }
            }
        } catch (err) {
            component.setState({
                loading: false,
                actionLoading: false,
                error: true,
                message: err.message
            });
        }
    }
};

export const handleChangeQuestionType = (e, component) => {
    const { value } = e.target;

    let changedValue = parseInt(value);

    let qt = getQuestionType('discursive');

    let isDiscursive = qt.id == changedValue ? true : false;

    component.setState({
        form: {
            ...component.state.form,
            questionTypeId: value,
            'isDiscursive': isDiscursive
        }
    });
}

const questionFunctions = {
    handleSubmit,
    handleChangeQuestionType,
    yearsList,
}

export default questionFunctions;