import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col, Breadcrumb, ProgressBar, Fade, InputGroup } from "react-bootstrap";
import parse from 'html-react-parser';
import PageAlert from "presentation/components/page-alert";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";

import activityAnswersService from "presentation/services/activity-answers";
import { goTo } from "presentation/utils/functions";

class ActivityViewForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: false,
            localError: false,
            message: '',
            activeKey: 0,
        }

    }

    setActive = (key) => {
        this.setState({ activeKey: parseInt(key) });
    }

    isActive = (itemKey) => {
        const { activeKey } = this.state;
        let response = itemKey == activeKey;
        return response;
    };


    loadQuestionAnswer = (questionId) => {
        const { component } = this.props;
        const { answers } = component.state;
        let answerItem = answers.filter(x => x.questionId == questionId)[0];
        return answerItem ? answerItem : undefined;
    }

    setAnswer = (event, question, rawQuestionOptionId, observations = "") => {
        const { loadQuestionAnswer } = this;
        const { component } = this.props;
        const { answers } = component.state;
        var questionId = question.id;

        let answerItem = loadQuestionAnswer(questionId);
        let questionOptionId = parseInt(rawQuestionOptionId);


        let isChecked = event.target.checked;

        if (answerItem) {
            let allowMultiple = question.allowMultipleOptionsAnswers;

            if (allowMultiple == true) {
                if (!answerItem.questionOptionId.includes(questionOptionId) && isChecked == true) {
                    answerItem.questionOptionId.push(questionOptionId);
                } else {
                    let currentOptions = answerItem.questionOptionId.filter(x => x !== questionOptionId);
                    answerItem.questionOptionId = currentOptions;
                }
            } else {
                answerItem.questionOptionId = [questionOptionId];
            }


            answerItem.observations = observations;
        } else {
            answerItem = { questionId: questionId, questionOptionId: [questionOptionId], observations: observations };
        }

        let filteredAnswers = answers.filter(x => x.questionId != answerItem.questionId);
        let addAnswer = true;

        if (question.questionTypeId == 2 && (answerItem.observations.trim().length === 0)) {
            addAnswer = false;
        }

        if (answerItem.questionOptionId.length <= 0) {
            addAnswer = false;
        }

        if (addAnswer) {
            filteredAnswers.push(answerItem);
        }


        component.setState({ answers: filteredAnswers });

    }

    getAnswer = (questionId) => {
        const { loadQuestionAnswer } = this;

        let answerItem = loadQuestionAnswer(questionId);
        return answerItem ? answerItem : undefined;

    };

    onSubmit = async () => {
        const { component, activityId, studentId } = this.props;
        const { answers, activity } = component.state;
        component.setState({ loading: true });
        var requests = undefined;
        var postData = [];
        if (answers.length > 0) {
            answers.map((e, i) => {
                if (e.questionOptionId) {
                    e.questionOptionId.map((o, j) => {
                        let answer = {
                            questionOptionId: o,
                            questionId: e.questionId,
                            observations: e.observations,
                            classroomId: activity.classroom.id,
                            disciplineId: activity.discipline.id,
                            studentId: studentId,
                            activityId: activityId
                        };
                        postData.push(answer);
                    });
                }
            });
        }
        if (postData.length > 0) {
            requests = [];
            postData.map(async (r, i) => {
                requests.push(await activityAnswersService.add(r));
            });
            if (Promise.all(requests)) {
                goTo("/", component);
            } else {
                component.setState({ loading: false, error: true, message: 'Erro ao enviar as respostas... Por favor, tente novamente mais tarde' });
            }

        } else {
            component.setState({ loading: false, error: true, message: 'Erro ao recuperar as respostas... Por favor, tente novamente mais tarde' });
        }

    };

    render() {

        let { component, returnTo } = this.props;

        const { loading, activity, questions: rawQuestions, answers, student } = component.state;

        const questions = rawQuestions.sort((a, b) => a.order - b.order);

        let { localError, localLoading, message, activeKey } = this.state;

        const { isActive, setActive, getAnswer, setAnswer } = this;

        const totalQuestions = questions.length;
        const totalAnswered = answers.length;

        const totalFilled = (totalAnswered * 100) / totalQuestions;

        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {(localLoading === false && localLoading === false && questions.length <= 0) && <PageAlert className="m-b-20" message={"Erro"} description={"A atividade selecionada não tem questões para responder..."} type="danger" />}
                {loading === false && localLoading === false && questions.length >= 0 && (
                    <Form>
                        <Container className="pb-5">
                            <Breadcrumb className="site-breadcrumb">
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.classroom.displayName}</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.discipline.displayName}</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.title}</span>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <Row className="m-b-4 activity-options-container">
                                <p className="text-muted">Aluno(a): {student.person.name} - {student.registration}</p>
                                {questions.map((e, i) => {
                                    var isActiveItem = isActive(i);
                                    var options = e.question.questionOptions;
                                    var firstOption = options[0];
                                    var questionType = e.question.questionTypeId;
                                    var allowMultipleAnswers = e.question.allowMultipleOptionsAnswers;
                                    var questionAnswer = getAnswer(e.question.id);
                                    return (
                                        <div key={e.id} className="activity-answers-view">
                                            <Fade in={isActiveItem}>
                                                <div>
                                                    {
                                                        isActiveItem &&
                                                        <>
                                                            <h6>Questão {i + 1}</h6>
                                                            <hr />
                                                            <div className="mb-4">
                                                                {parse(e.question.title)}
                                                            </div>
                                                            {questionType == 1 && allowMultipleAnswers == false && options.map((o, j) => {
                                                                return (
                                                                    <div key={`opt-${o.id}`} className={o.isCorrect == true ? "activity-answer-option-correct" : "activity-answer-option"}>
                                                                        <Form.Check
                                                                            disabled
                                                                            type="radio"
                                                                            label={parse(o.title)}
                                                                            name={`opt-${o.questionId}`}
                                                                            value={o.id}
                                                                            checked={questionAnswer ? questionAnswer.questionOptionId.includes(o.id) : false}
                                                                            onChange={(ev) => setAnswer(ev, e.question, ev.target.value)}
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                            {questionType == 1 && allowMultipleAnswers == true && options.map((o, j) => {
                                                                return (
                                                                    <div key={`opt-${o.id}`} className={o.isCorrect == true ? "activity-answer-option-correct" : "activity-answer-option"}>
                                                                        <Form.Check
                                                                            disabled
                                                                            type="checkbox"
                                                                            label={parse(o.title)}
                                                                            name={`opt-${o.questionId}-item${o.id}`}
                                                                            value={o.id}
                                                                            checked={questionAnswer ? questionAnswer.questionOptionId.includes(o.id) : false}
                                                                            onChange={(ev) => setAnswer(ev, e.question, ev.target.value)}
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                            {questionType == 2 && (

                                                                <div>
                                                                    <div key={`opt-${firstOption.id}`} className="activity-answer-option-correct">
                                                                        <strong>Gabarito:</strong>
                                                                        <br />
                                                                        {parse(firstOption.title || '- resposta padrão não cadastrada -')}
                                                                    </div>
                                                                    <br />
                                                                    <div className="activity-answer-option-evaluable">
                                                                        <strong>Resposta do aluno: </strong>
                                                                        <br />
                                                                        {parse(questionAnswer ? questionAnswer.observations : " - ")}
                                                                    </div>
                                                                    <br />
                                                                    <strong>Percentual de acerto: </strong>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            type="number"
                                                                            readOnly
                                                                            name={`opt-sr-${firstOption.questionId}`}
                                                                            value={questionAnswer ? questionAnswer.successRate : undefined}
                                                                        />
                                                                        <InputGroup.Text>%</InputGroup.Text>
                                                                    </InputGroup>
                                                                    <br />
                                                                    <strong>Comentário: </strong>
                                                                    <Form.Control
                                                                        className="activity-answer-comment"
                                                                        as={'textarea'}
                                                                        name={`opt-${firstOption.questionId}`}
                                                                        value={questionAnswer ? questionAnswer.evaluationComments : undefined}
                                                                        readOnly
                                                                    />

                                                                </div>
                                                            )}
                                                        </>
                                                    }
                                                </div>
                                            </Fade>
                                        </div>
                                    )
                                })}
                            </Row>
                        </Container>
                        <Container fluid className="align-middle activity-navigation">
                            <Row>
                                <Col>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <ProgressBar className="mt-2" now={totalFilled} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button size="sm" as={Link} to={returnTo || "/"} variant="secondary" className="float-start m-r-1 mt-4">Sair</Button>
                                                <Button size="sm" variant="success" className="float-start m-r-1 mt-4" disabled={activeKey == 0} onClick={() => setActive(activeKey - 1)}> Anterior</Button>
                                                <Form.Select size="sm" className="activity-select-question float-start m-r-1 mt-4" value={activeKey} onChange={(e) => setActive(e.target.value)}>
                                                    {questions.map((e, i) => {
                                                        var questionAnswer = getAnswer(e.question.id);
                                                        var key = i + 1;
                                                        return <option key={e.id} value={i}>{`${key}${!questionAnswer ? ' [sem resposta]' : ''}`}</option>
                                                    })}
                                                </Form.Select>
                                                <Button size="sm" variant="success" className="float-start mt-4" disabled={activeKey == (totalQuestions - 1)} onClick={() => setActive(activeKey + 1)} >Próxima</Button>

                                                <small className="text-muted float-end mt-4 m-r-1">{` ${totalAnswered} de ${totalQuestions} ${totalQuestions > 1 ? 'questões respondidas' : 'questões respondidas'}`}</small>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )
                }
            </>
        );
    };
}

export default ActivityViewForm;