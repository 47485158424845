import React from "react";
import { Container, Row, Col, Button, Card, Badge, Table } from "react-bootstrap";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import { handleResultData } from "presentation/utils/functions";

import StudentActivitiesModal from 'presentation/pages/principal/dashboards/students/elements/activities-modal';
import StudentMediaFilesModal from 'presentation/pages/principal/dashboards/students/elements/media-files-modal';

import activitiesService from "presentation/services/activities";

import { checkAvailability } from 'presentation/pages/activities/functions/activities';

import { getUser } from 'presentation/utils/authentication';

import { Heat } from '@alptugidin/react-circular-progress-bar';

import parse from 'html-react-parser';
import logoBicolor from 'presentation/assets/img/logo-bicolor.png';

class StudentDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            actionLoading: false,
            error: false,
            message: '',
            data: [],
            scoreData: [],
            studentId: null,
            totalCompleted: 0,
            totalAvailable: 0,
            totalActivities: 0,
            successRate: 0,
            completedRate: 0,
            showStudentActivitiesModal: false,
            showStudentMediaFilesModal: false,
            directories: [],
            classroomName: '',
            disciplineName: '',
            classroomId: null,
            disciplineId: null,
            userInfo: { person: { displayName: undefined }, email: undefined }
        };
        this.loadData = this.loadData.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        const { formatData } = this;
        let data = [];
        let scoreData = [];
        const user = getUser();

        this.setState({ loading: true });


        var activities = await activitiesService.myActivities().then((result) => {

            if (result) {
                let handledResult = handleResultData(result);
                data = formatData(handledResult);
            }

        });

        var scores = await activitiesService.myActivitiesScores().then((scoresResults) => {
            if (scoresResults) {
                scoreData = handleResultData(scoresResults);
            }

        });

        await Promise.all([activities, scores]);

        this.setState({ scoreData: scoreData, data: data, studentId: user.studentId, userInfo: user }, () => this.calculateStatistics());
    }

    calculateStatistics = () => {
        var totalAvailable = 0;
        var totalActivities = 0;
        var totalCompleted = 0;
        var totalQuestions = 0;
        var totalCorrectedAnswered = 0;
        var successRate = 0;
        var completedRate = 0;

        const { data, scoreData } = this.state;

        //Calculating total available

        data.map((classroom, index) => {
            if (classroom.disciplines != undefined && classroom.disciplines.length > 0) {
                classroom.disciplines.map((discipline, dindex) => {
                    totalAvailable = parseInt(totalAvailable) + parseInt(discipline.availableActivities);
                    totalActivities = parseInt(totalActivities) + parseInt(discipline.totalActivities);
                });
            }
        });

        console.log(data);

        //Calculating total completed and success rate
        if (scoreData.length > 0) {
            totalCompleted = scoreData.length;

            totalQuestions = scoreData.reduce((acc, o) => acc + parseInt(o.totalQuestions), 0);
            totalCorrectedAnswered = scoreData.reduce((acc, o) => acc + parseInt(o.totalCorrectAnswers), 0);

            if (totalQuestions > 0) {
                successRate = ((totalCorrectedAnswered / totalQuestions) * 100);
            }

        }

        if (totalActivities > 0) {
            completedRate = ((totalCompleted / totalActivities) * 100);
        }

        this.setState({ loading: false, totalAvailable: totalAvailable, totalCompleted: totalCompleted, successRate: successRate, completedRate: completedRate, totalActivities: totalActivities });
    }

    formatData = (rawData) => {
        let data = [];

        rawData.map((e, i) => {
            if (!data[e.classroomId]) {
                data[e.classroomId] = {
                    id: e.classroomId,
                    name: e.classroomName,
                    disciplines: []
                };
            }

            if (!data[e.classroomId].disciplines[e.disciplineId]) {
                data[e.classroomId].disciplines[e.disciplineId] = {
                    id: e.disciplineId,
                    name: e.disciplineName,
                    totalActivities: 0,
                    availableActivities: 0,
                    directories: []
                };
            }

            var directoryId = e.activityCategoryId == null ? 0 : 1 + e.activityCategoryId;
            var directoryName = e.activityCategoryId == null ? " Principal " : e.activityCategoryName;

            if (!data[e.classroomId].disciplines[e.disciplineId].directories[directoryId]) {
                data[e.classroomId].disciplines[e.disciplineId].directories[directoryId] = {
                    id: directoryId,
                    name: directoryName,
                    activities: []
                };
            }

            var avInfo = checkAvailability(e);

            e.isAvailable = avInfo.isAvailable;
            e.availabilityMessage = avInfo.message ? avInfo.message : null;

            if (e.isAvailable == true) {
                data[e.classroomId].disciplines[e.disciplineId].availableActivities++;
            }
            data[e.classroomId].disciplines[e.disciplineId].totalActivities++;

            data[e.classroomId].disciplines[e.disciplineId].directories[directoryId].activities.push(e);
        });
        return data;
    }

    openAcitvitiesModal = (classroomName, disciplineName, directories, scores) => {
        this.setState({
            showStudentActivitiesModal: true,
            classroomName: classroomName,
            disciplineName: disciplineName,
            directories: directories,
            scores: scores
        });
    }

    closeActivitiesModal = () => {
        this.setState({
            showStudentActivitiesModal: false,
            classroomName: '',
            disciplineName: '',
            directories: [],
            scores: []
        });
    }

    openMediaFilesModal = (classroomName, disciplineName, classroomId, disciplineId) => {
        this.setState({
            showStudentMediaFilesModal: true,
            classroomName: classroomName,
            disciplineName: disciplineName,
            classroomId: classroomId,
            disciplineId: disciplineId
        });
    }

    closeMediaFilesModal = () => {
        this.setState({
            showStudentMediaFilesModal: false,
            classroomName: '',
            disciplineName: '',
            classroomId: null,
            disciplineId: null
        });
    }


    render() {

        const {
            openAcitvitiesModal,
            closeActivitiesModal,
            openMediaFilesModal,
            closeMediaFilesModal

        } = this;
        const {
            loading,
            actionLoading,
            error,
            message,
            data,
            scoreData,
            showStudentActivitiesModal,
            showStudentMediaFilesModal,
            directories,
            classroomId,
            classroomName,
            studentId,
            disciplineId,
            disciplineName,
            scores,
            totalAvailable,
            totalCompleted,
            totalActivities,
            successRate,
            completedRate,
            userInfo
        } = this.state;

        const pendingLabel = totalAvailable == 1 ? 'atividade<br />pendente' : 'atividades <br />pendentes';
        const completedLabel = totalCompleted == 1 ? 'atividade realizada' : 'atividades realizadas';
        const totalActivitiesLabel = totalActivities == 1 ? 'atividade disponibilizada' : 'atividades disponibilizadas';

        return (
            <div>
                {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
                {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
                {loading === false && (
                    <>
                        <Container>
                            <Row>
                                <Col>
                                    <Card className="student-dashboard-welcome">
                                        <Card.Body>
                                            <Row>
                                                <Col className="welcome-headline">
                                                    <img src={logoBicolor} id="logo-bicolor" />
                                                    <h4 className="font-promo-bolder">Olá, {userInfo.person ? userInfo.person.displayName : userInfo.email} <br />Bem vindo de volta!</h4>
                                                    <p className="font-promo">Confira suas atividades pendentes, concluídas <br />e seu progresso aqui. Bom estudo!</p>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="dashboard-content">
                            <Row>
                                <Col>
                                    <Container>
                                        <Row className="mt-4">
                                            <Col xs={12} lg={6} xl={3} className="mt-4">
                                                <Card className="student-dashboard-welcome-indicator no-border text-center card-indicator-error">
                                                    <Card.Body><h4>{totalAvailable}</h4><small>{parse(pendingLabel)}</small></Card.Body>
                                                </Card>
                                            </Col>
                                            <Col xs={12} lg={6} xl={3} className="mt-4">

                                                <Card className="student-dashboard-welcome-indicator no-border text-center card-indicator-success">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col>
                                                                <h4>{totalCompleted}</h4><small>{parse(completedLabel)}</small>
                                                            </Col>
                                                            <Col>
                                                                <h4>{totalActivities}</h4><small>{parse(totalActivitiesLabel)}</small>
                                                            </Col>
                                                        </Row></Card.Body>
                                                </Card>
                                            </Col>
                                            <Col xs={12} lg={6} xl={3} className="mt-4">
                                                <Card className="student-dashboard-welcome-indicator">
                                                    <Card.Body>
                                                        <center>
                                                            <div className="progress-circle-container">
                                                                <Heat
                                                                    progress={completedRate}
                                                                    range={{ from: 0, to: 100 }}
                                                                    sign={{ value: '%', position: 'end' }}
                                                                    text={'realizado'}
                                                                    showMiniCircle={false}
                                                                    showValue={true}
                                                                    revertBackground={true}
                                                                    sx={{
                                                                        strokeColor: '#ff0000',
                                                                        barWidth: 2,
                                                                        bgStrokeColor: '#fefefe',
                                                                        bgColor: '#d1d1d1',
                                                                        shape: 'threequarters',
                                                                        strokeLinecap: 'round',
                                                                        valueSize: 20,
                                                                        valueColor: '#495057',
                                                                        textSize: 14,
                                                                        textColor: '#495057',
                                                                        loadingTime: 1000,
                                                                        miniCircleColor: '#ff0000',
                                                                        miniCircleSize: 1,
                                                                        valueAnimation: true,
                                                                        intersectionEnabled: true
                                                                    }}
                                                                />
                                                            </div>
                                                        </center>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col xs={12} lg={6} xl={3} className="mt-4">
                                                <Card className="student-dashboard-welcome-indicator">
                                                    <Card.Body>
                                                        <center>
                                                            <div className="progress-circle-container">
                                                                <Heat
                                                                    progress={successRate}
                                                                    range={{ from: 0, to: 100 }}
                                                                    sign={{ value: '%', position: 'end' }}
                                                                    text={'aproveitamento'}
                                                                    showMiniCircle={false}
                                                                    showValue={true}
                                                                    revertBackground={true}
                                                                    sx={{
                                                                        strokeColor: '#ff0000',
                                                                        barWidth: 2,
                                                                        bgStrokeColor: '#fefefe',
                                                                        bgColor: '#d1d1d1',
                                                                        shape: 'threequarters',
                                                                        strokeLinecap: 'round',
                                                                        valueSize: 20,
                                                                        valueColor: '#495057',
                                                                        textSize: 12,
                                                                        textColor: '#495057',
                                                                        loadingTime: 1000,
                                                                        miniCircleColor: '#ff0000',
                                                                        miniCircleSize: 1,
                                                                        valueAnimation: true,
                                                                        intersectionEnabled: true
                                                                    }}
                                                                />
                                                            </div>
                                                        </center>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className="mb-5">
                                            {data.map((classroom, index) => {
                                                return (
                                                    <Col lg={6} key={index}>
                                                        <h4 className="mb-4 mt-4" >{classroom.name}</h4>
                                                        <Card>
                                                            <Card.Body>
                                                                <Table borderless className="mt-4 dashboard-table">
                                                                    <tbody>
                                                                        {classroom.disciplines.map((discipline, disciplineIndex) => {
                                                                            var disciplineScores = scoreData.filter(x => x.disciplineId == discipline.id && x.classroomId == classroom.id);
                                                                            return (
                                                                                <tr key={disciplineIndex}>
                                                                                    <td width={"45%"} >{discipline.name}</td>
                                                                                    <td width={"25%"} className="text-end">
                                                                                        <Button size="sm" variant="outline-success" onClick={(e) => openMediaFilesModal(classroom.name, discipline.name, classroom.id, discipline.id, studentId)}>materiais</Button>
                                                                                    </td>
                                                                                    <td width={"25%"} className="text-end">
                                                                                        <Button className="button-with-badge" size="sm" variant="outline-success" onClick={(e) => openAcitvitiesModal(classroom.name, discipline.name, discipline.directories, disciplineScores)}>
                                                                                            atividades
                                                                                        </Button>
                                                                                    </td>
                                                                                    <td width={"5%"} className="text-start">
                                                                                        {discipline.availableActivities > 0 && (
                                                                                            <span>
                                                                                                <Badge bg="danger">{discipline.availableActivities}</Badge>
                                                                                                <span className="visually-hidden">atividades disponíveis</span>
                                                                                            </span>
                                                                                        )}
                                                                                    </td>

                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </Table>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                        {showStudentActivitiesModal == true && (
                                            <StudentActivitiesModal
                                                show={showStudentActivitiesModal}
                                                onClose={closeActivitiesModal}
                                                directories={directories}
                                                scores={scores}
                                                classroomName={classroomName}
                                                disciplineName={disciplineName}
                                            />
                                        )}
                                        {showStudentMediaFilesModal == true && (
                                            <StudentMediaFilesModal
                                                show={showStudentMediaFilesModal}
                                                onClose={closeMediaFilesModal}
                                                studentId={studentId}
                                                classroomId={classroomId}
                                                disciplineId={disciplineId}
                                                classroomName={classroomName}
                                                disciplineName={disciplineName}
                                            />
                                        )}
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </>

                )
                }
            </div>
        );
    };
}

export default StudentDashboard;