import React from 'react';
import { Modal, Button, } from "react-bootstrap";
import PersonSearchBox from "presentation/pages/persons/elements/person-search-box";

import { PERSON_LOOKUP_OPTION_NONE } from 'presentation/model/constants';

class ResponsiblesLookUpModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastValidText: '',
            newRegistration: false,
            personId: null,
            selectedItem: { documentField: null, employee: { id: null, registration: null }, student: { id: null, registration: null } }
        };

    }

    onFieldChange = (fieldValue) => {
        if (fieldValue != PERSON_LOOKUP_OPTION_NONE) {
            this.setState({ lastValidText: fieldValue, personId: null });
        }
    }

    onSelectItem = (selectedItem) => {

        this.setState({ ...this.state, selectedItem: selectedItem }, () => {
            if (!selectedItem) {
                this.setState({ ...this.state, lastValidText: '', newRegistration: false, personId: null });
            } else {
                if (selectedItem.name == PERSON_LOOKUP_OPTION_NONE) {
                    this.setState({ ...this.state, newRegistration: true, personId: null });
                } else {
                    this.setState({ ...this.state, newRegistration: false, personId: selectedItem.id });
                }
            }
        });
    }

    onSubmit = async () => {
        let { history, baseRedirectUrl, dependentName, dependentRegistration, dependentId, studentId } = this.props;
        let { personId, lastValidText, newRegistration, selectedItem } = this.state;
        history.push({
            pathname: baseRedirectUrl,
            state: {
                personId: personId,
                personEntity: selectedItem,
                newRegistration: newRegistration,
                personName: lastValidText,
                studentId: studentId,
                dependentId: dependentId,
                dependentName: dependentName,
                dependentRegistration: dependentRegistration
            }
        });

    };


    render() {
        let { show, onClose, modalTitle} = this.props;
        let { loading, lastValidText, newRegistration, selectedItem, personId } = this.state;
        const { onSubmit, onSelectItem, onFieldChange } = this;

        return (
            <Modal backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle || "Buscando cadastro de pessoas"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PersonSearchBox onSelect={onSelectItem} onChange={onFieldChange} />
                    {newRegistration == true &&
                        (
                            <>
                                <br />
                                <p>Sem problemas! Clique em prosseguir e vamos cadastrar o(a) <strong>{lastValidText}</strong></p>
                            </>
                        )
                    }
                    {selectedItem != null && selectedItem.id != null &&
                        (
                            <>
                                <br />
                                <p>Documento: <strong> {selectedItem.documentField || " - Não informado -"}</strong></p>
                                {selectedItem.dependents && selectedItem.dependents.length > 0 && (
                                    <>
                                        Outros dependentes dessa pessoa:
                                        {selectedItem.dependents.map((d, idx) => { return <p>- {d.name}</p> })}
                                    </>
                                )}
                                <p>
                                    Tudo certo?
                                </p>
                                <p>Se sim, clique em <strong><em>Prosseguir</em></strong>.</p>
                                <p>Se não,</p>
                                <ul>
                                    <li>tente digitar um nome mais completo, ou</li>
                                    <li>É um cadastro totalmetne novo? Digite o nome que deseja cadastrar e escolha a opção "<em>{PERSON_LOOKUP_OPTION_NONE}</em>" na listagem que aparecer...</li>
                                </ul>
                            </>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={onClose}>
                        Mudei de ideia
                    </Button>
                    <Button disabled={(!personId && !newRegistration)} variant="success" onClick={() => onSubmit()}>
                        Prosseguir
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default ResponsiblesLookUpModal;