import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { handleResultData, handleChange } from "presentation/utils/functions";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import { EMPTY_SELECT_OPTION } from "presentation/model/constants";

import ExcelExport from "presentation/components/excel-export";

import mediaFilesService from "presentation/services/media-files";
import mediaAccessHistoryService from "presentation/services/media-access-histories";
import moment from "moment";

class MediaAccessHistoryReportFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterForm: {
        classroomId: null,
        mediaFileId: null,
        disciplineId: null
      },
      loading: false,
      actionLoading: false,
      error: false,
      message: '',
      mediaFiles: [],
      reportData: [],
    };
  }
  loadMediaFiles = async () => {
    let { filterForm } = this.state;
    let { disciplineId, classroomId } = filterForm;
    let mainData = [];
    let localError = false;
    let errorMessage = '';
    let me = this;

    me.setState({ loading: true, reportData: [], mediaFiles: [], filterForm: { ...me.state.filterForm, mediaFileId: null } }, async () => {
      if (disciplineId != null && classroomId != null && disciplineId > 0 && classroomId > 0) {
        await mediaFilesService.getMediaListOf(filterForm).then((result) => {
          let handled = handleResultData(result);
          if (handled.error == true) {
            localError = true;
            errorMessage = handled.message;
          } else {
            if (handled.length > 0) {

              mainData = [EMPTY_SELECT_OPTION, ...handled];
            } else {
              localError = true;
              errorMessage = 'Não há materiais para o filtro informado. Por favor, tente selecionar outras opções...'
            }

          }
        });
      }
      me.setState({ loading: false, error: localError, mediaFiles: mainData, message: errorMessage });
    });

  }

  loadMediaFile = async () => {
    var errorMessages = [];
    var errorMessage = '';
    var hasError = false;

    let { filterForm } = this.state;
    let { mediaFileId, classroomId, disciplineId } = filterForm;


    if (mediaFileId != null && mediaFileId > 0) {
      this.setState({ loading: true });

      await mediaAccessHistoryService.search({ mediaId: [parseInt(mediaFileId)], classroomId: [parseInt(classroomId)], disciplineId: [parseInt(disciplineId)] }).then((result) => {
        let response = handleResultData(result);

        if (response.error == true) {
          errorMessages.push(response.message);
        } else {
          this.setState({ reportData: response, loading: false });
        }
      });


    } else {
      this.setState({ reportData: [], loading: false });
    }

  }

  formatToExport = (data) => {
    let output = [];

    data.map((element, index) => {
      output.push({
        "Material": element.mediaTitle,
        "Turma": element.classroomName,
        "Disciplina": element.disciplineName,
        "Matrícula": element.studentRegistration,
        "Nome": element.studentName,
        "Data acesso": moment(element.insertAt).format("DD/MM/YYYY HH:mm:ss")
      }
      );
    })
    console.log(output);
    return output;
  };

  render() {

    const { disciplines, classrooms } = this.props;
    const { mediaFiles, reportData, loading, actionLoading, error, message, filterForm } = this.state;

    const { formatToExport, loadMediaFiles, loadMediaFile } = this;
    var actionDisabled = reportData.length > 0 ? false : true;
    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <Container>
            <Row className="mt-4">
              <Col>
                <h3>Turma</h3>
                <Form.Select
                  aria-label="Turma"
                  id={`classroomId`}
                  name={`classroomId`}
                  onChange={(e) => handleChange(e, this, 'filterForm', loadMediaFiles)}
                  value={filterForm.classroomId || undefined}
                  data-field-name="classroomId"
                >
                  {classrooms.map((e, i) => { return <option key={e.name} value={e.id}>{e.name}</option> })}
                </Form.Select>
              </Col>
              <Col>
                <h3>Disciplina</h3>
                <Form.Select
                  aria-label="Disciplina"
                  id={`disciplineId`}
                  name={`disciplineId`}
                  onChange={(e) => handleChange(e, this, 'filterForm', loadMediaFiles)}
                  value={filterForm.disciplineId || undefined}
                  data-field-name="disciplineId"
                >
                  {disciplines.map((e, i) => { return <option key={e.name} value={e.id}>{e.name}</option> })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <h3>Material</h3>
                <Form.Select
                  aria-label="Material"
                  id={`mediaFileId`}
                  name={`mediaFileId`}
                  onChange={(e) => handleChange(e, this, 'filterForm', loadMediaFile)}
                  value={filterForm.mediaFileId || undefined}
                  data-field-name="mediaFileId"
                >
                  {mediaFiles.map((e, i) => { return <option key={e.title} value={e.id}>{e.title}</option> })}
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col className="mt-4">
                <ExcelExport disabled={actionDisabled} excelData={formatToExport(reportData)} fileName={`relatorio-acesso-materiais-${filterForm.mediaFileId}`} className="float-end" title="Emitir" />
                {actionDisabled == true && (<small className="text-muted">O botão de emitir está desabilitado pois não há informações de acesso para esse material...</small>)}
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  };
}

export default MediaAccessHistoryReportFilter;
