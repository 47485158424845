import React from "react";
import { Container, Row, Form, Button } from "react-bootstrap";

import mediaFilesService from "presentation/services/media-files";
import mediaDirectoriesService from "presentation/services/media-directories";
import mediaDirectoryPermissionsService from "presentation/services/media-directory-permissions";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import MediaFileForm from 'presentation/pages/media-files/elements/form';

import { getMediaType, getMediaDirectoryPermission, getMediaDirectoryCustomPermission } from "presentation/utils/functions";

import { MEDIA_DIRECTORY_MACRO_DENY, MEDIA_DIRECTORY_STUDENT_ALLOW } from "presentation/model/constants";

class MediaFileEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      form: {
        mediaType: 1
      },
      actionLoading: false,
      error: false,
      message: '',
      selectedDirectories: [],
      directoriesPermissions: []
    };
    this.loadData = this.loadData.bind(this);

  }

  async componentDidMount() {
    const { id } = this.props.match.params
    await this.loadData(id);
  }

  loadData = async (id) => {
    var selectedDirectories = [];
    var directoriesPermissions = [];
    var form = {};
    await mediaFilesService.findById(id).then((result) => {
      var mediaTypeInfo = result.mediaType ? getMediaType(result.mediaType.toString()) : 1;
      var mediaTypeId = typeof (mediaTypeInfo) === 'undefined' ? 1 : mediaTypeInfo.id;

      result.mediaType = mediaTypeId;

      form = result;
    });

    await mediaDirectoriesService.search({ mediaId: [id], classroomId: [], disciplineId: [] }).then((directoriesResult) => {
      if (!(directoriesResult.error == true)) {

        directoriesResult.map((e, i) => {
          let key = `${e.classroomId}-${e.disciplineId}`;
          let permissionType = getMediaDirectoryPermission(e.permissionType);
          let permissionTypeId = typeof (permissionType) === 'undefined' ? MEDIA_DIRECTORY_MACRO_DENY : permissionType.id;

          var selected = {
            id: e.id,
            key: key,
            classroomId: e.classroomId,
            disciplineId: e.disciplineId,
            mediaId: e.mediaId,
            permissionType: permissionTypeId
          };
          selectedDirectories.push(selected);
        });
      }
    });

    await mediaDirectoryPermissionsService.search({ mediaId: [id], classroomId: [], disciplineId: [] }).then((directoriesResult) => {
      if (!(directoriesResult.error == true)) {
        directoriesResult.map((p, i) => {
          let key = `${p.classroomId}-${p.disciplineId}-${p.studentId}`;
          let permissionType = getMediaDirectoryCustomPermission(p.permissionType);
          let permissionTypeId = typeof (permissionType) === 'undefined' ? MEDIA_DIRECTORY_STUDENT_ALLOW : permissionType.id;

          var permission = {
            id: p.id,
            key: key,
            classroomId: p.classroomId,
            disciplineId: p.disciplineId,
            studentId: p.studentId,
            mediaId: p.mediaId,
            permissionType: permissionTypeId
          };
          directoriesPermissions.push(permission);
        });
      }
    });

    this.setState({ form: form, selectedDirectories: selectedDirectories, directoriesPermissions: directoriesPermissions, loading: false });
  }

  goTo = (destination) => {
    const { history } = this.props;

    history.push(destination);
  };

  handleSubmit = async () => {

    this.setState({ loading: true, actionLoading: true, error: '' });

    const {
      title,
      uploadMediaFile,
      mediaType,
      id,
    } = this.state.form;

    const { selectedDirectories, directoriesPermissions } = this.state;


    var validationError = false;
    var validationErrorMessages = [];

    if (!title) {
      validationError = true;
      validationErrorMessages.push("Por favor, preencha um título...");
    }

    if (!id && mediaType == 1 && (!uploadMediaFile || !uploadMediaFile.name)) {
      validationError = true;
      validationErrorMessages.push("O campo de arquivo é obrigatório");
    }

    if (!(selectedDirectories.length > 0)) {
      validationError = true;
      validationErrorMessages.push("Por favor, selecione ao menos um diretório");
    }


    if (validationError) {
      this.setState({ loading: false, error: true, message: validationErrorMessages.join("<br />") });
    } else {
      try {

        const form = this.state.form;

        let postData = {
          mediaFile: {
            id: form.id,
            userId: form.userId,
            title: form.title,
            description: form.description,
            mediaType: form.mediaType,
            storageUri: form.storageUri,
            storageIdentification: form.storageIdentification
          },
          directoriesPermissions: directoriesPermissions,
          selectedDirectories: selectedDirectories
        };

        const response = await mediaFilesService.save(postData);
        if (response.error == true) {
          this.setState({
            loading: false,
            actionLoading: false,
            error: true,
            message: response.message
          });
        } else {
          this.goTo("/materiais");
        }



      } catch (err) {
        this.setState({
          loading: false,
          actionLoading: false,
          error: true,
          message: err.message
        });
      }
    }
  };

  render() {
    let { loading, error, message, actionLoading, form, selectedDirectories } = this.state;
    let { handleSubmit } = this;


    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <Container>
            <Row>
              <MediaFileForm
                handleSubmit={handleSubmit}
                loading={loading}
                error={error}
                form={form}
                selectedDirectories={selectedDirectories}
                component={this}
              />
            </Row>
          </Container>
        )
        }
      </div>
    );
  };
}

export default MediaFileEdit;
