import React from 'react';
import { Button } from "react-bootstrap";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const ExcelExport = ({ excelData, fileName, className, title, disabled }) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8";
    const fileExtension = ".xlsx";
    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
            <Button disabled={disabled} variant="outline-primary" onClick={(e) => exportToExcel(fileName)} className={className}>
                {title || "Exportar planilha"}
            </Button>
        </>
    );
};

export default ExcelExport;