import React from "react";
import { Modal, Button, Table, Form, Container, Row, Col } from "react-bootstrap";
import PageAlert from "presentation/components/page-alert";

import { handleChange } from "presentation/utils/functions";

import activitiesService from "presentation/services/activities";
import activityDateOverridesService from "presentation/services/activity-date-overrides";


import {
    activityDatesFormValue,
    handleChangeActivityDates
} from 'presentation/pages/activities/functions/activity-dates';

import PageLoadingIndicator from "presentation/components/page-loading-indicator";

class ActivityDatesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            message: ''
        };
    }

    onSubmit = async () => {

        const { afterSubmit, component } = this.props;
        const { activityDates, activityDateForm } = component.state;

        this.setState({ loading: true, message: '', error: false });


        let activityPostData = activityDateForm;
        if (!activityPostData.startDate) {
            activityPostData.startDate = undefined;
        }

        if (!activityPostData.endDate) {
            activityPostData.endDate = undefined;
        }

        var validActivityDates = activityDates.filter(x => (x.startDate != undefined || x.endDate != undefined || x.id != undefined));
        
        var activityDatesResponse = { error: false };
        try {
            const response = await activitiesService.update(activityPostData);
            if (validActivityDates.length > 0) {
                activityDatesResponse = await activityDateOverridesService.batchManagement(validActivityDates);
            }

            if (response.error == true || activityDatesResponse.error == true) {
                let errorMessages = [];

                if (response.error == true) {
                    errorMessages.push(response.message);
                }

                if (activityDatesResponse.error == true) {
                    errorMessages.push(response.message);
                }

                this.setState({
                    loading: false,
                    actionLoading: false,
                    error: true,
                    message: errorMessages.join(' | ')
                });
            } else {
                this.setState({
                    loading: false,
                    actionLoading: false,
                    error: false,
                    message: ''
                }, () => afterSubmit());

            }
        } catch (err) {
            this.setState({
                loading: false,
                actionLoading: false,
                error: true,
                message: err.message
            });
        }


    };


    render() {
        let { show, onClose, students, component } = this.props;
        let form = component.state.activityDateForm;


        let { loading, error, message } = this.state;
        const { onSubmit } = this;

        return (
            <Modal fullscreen backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Prazos da atividade</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                    {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                    {loading === false && (
                        <Form>
                            <Container>
                                <p className="text-center"><strong>Você pode alterar o prazo original...</strong></p>
                                <Row>
                                    <Col size={6}>
                                        <Form.Label htmlFor="code">Data de início</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            id="startDate"
                                            name="startDate"
                                            defaultValue={form.startDate}
                                            onChange={(e) => handleChange(e, component, 'activityDateForm')}
                                            aria-describedby="startDateHelpBlock"
                                        />
                                    </Col>
                                    <Col size={6}>
                                        <Form.Label htmlFor="code">Data fim</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                            id="endDate"
                                            name="endDate"
                                            defaultValue={form.endDate}
                                            onChange={(e) => handleChange(e, component, 'activityDateForm')}
                                            aria-describedby="endDateHelpBlock"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="pt-4 text-center"><strong>Ou criar exceções por aluno...</strong></p>
                                        <Table bordered borderless className="mt-4">
                                            <thead>
                                                <tr>

                                                    <th>Matrícula</th>
                                                    <th>Aluno</th>
                                                    <th>Data de abertura</th>
                                                    <th>Data de encerramento</th>
                                                    <th>Excluir exceção?</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                {students.map((el, index) => {
                                                    let key = el.studentId;
                                                    let startDate = activityDatesFormValue(key, 'startDate', component);
                                                    let endDate = activityDatesFormValue(key, 'endDate', component);
                                                    let isRemove = activityDatesFormValue(key, 'isRemove', component);
                                                    let overrideId = activityDatesFormValue(key, 'id', component);
                                                    let extra = { studentId: el.studentId, classroomId: el.classroomId, activityId: el.activityId, disciplineId: el.disciplineId };
                                                    return (
                                                        <tr key={key}>
                                                            <td>{el.studentRegistration}</td>
                                                            <td>{el.studentName}</td>
                                                            <td>
                                                                <Form.Control
                                                                    type="datetime-local"
                                                                    id="startDate"
                                                                    name="startDate"
                                                                    data-field-name="startDate"
                                                                    defaultValue={startDate}
                                                                    disabled={isRemove == true}
                                                                    onChange={(e) => handleChangeActivityDates(key, e, component, extra)}
                                                                    aria-describedby="startDateHelpBlock"
                                                                />
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    type="datetime-local"
                                                                    id="endDate"
                                                                    name="endDate"
                                                                    data-field-name="endDate"
                                                                    defaultValue={endDate}
                                                                    disabled={isRemove == true}
                                                                    onChange={(e) => handleChangeActivityDates(key, e, component, extra)}
                                                                    aria-describedby="endDateHelpBlock"
                                                                />
                                                            </td>
                                                            <td>
                                                                {overrideId && (
                                                                    <Form.Check
                                                                        type="switch"
                                                                        checked={isRemove}
                                                                        label={isRemove == true ? "Sim" : "Não"}
                                                                        id={`isRemove-${key}`}
                                                                        name={`isRemove-${key}`}
                                                                        data-field-name="isRemove"
                                                                        onChange={(event) => handleChangeActivityDates(key, event, component, extra)}
                                                                    />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>

                            </Container>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={onClose}>
                        Mudei de ideia
                    </Button>
                    <Button disabled={loading} variant="success" onClick={() => onSubmit()}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default ActivityDatesModal;