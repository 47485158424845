export const EMPTY_SELECT_OPTION = { id: -9999, key: -9999, name: 'Selecione', title: 'Selecione', description: 'Selecione' };

export const questionTypes = [
    { id: 1, name: 'Multipla escolha', machineName: 'multipleOptions' },
    { id: 2, name: 'Dissertativa', machineName: 'discursive' },
];


//Tipos de media
export const MEDIA_FILE_TYPE_FILE = 1;
export const MEDIA_FILE_TYPE_LINK = 2;
export const MEDIA_FILE_TYPE_YOUTUBE = 3;


export const mediaTypes = [
    { id: MEDIA_FILE_TYPE_FILE, name: 'Arquivo', machineName: 'File' },
    { id: MEDIA_FILE_TYPE_LINK, name: 'Link', machineName: 'Link' },
    { id: MEDIA_FILE_TYPE_YOUTUBE, name: 'Youtube', machineName: 'Youtube' },
];

export const genders = [
    { id: 1, name: 'Masculino', machineName: 'male' },
    { id: 2, name: 'Feminino', machineName: 'female' },
    { id: 3, name: 'Outros', machineName: 'other' }
];

export const documentFieldTypes = [
    { id: 1, name: 'CPF', machineName: 'cpf' },
    { id: 2, name: 'CNPJ', machineName: 'cnpj' }
];
export const entitiesLabel = {
    student: "Aluno",
    employee: "Colaborador"
};


//Status do aluno na turma
export const CLASSROOM_STUDENT_STATUS_ACTIVE = 1;
export const CLASSROOM_STUDENT_STATUS_LOCKED = 2;
export const CLASSROOM_STUDENT_STATUS_REMOVED = 3;
export const CLASSROOM_STUDENT_STATUS_TRANSFERED = 4;
export const CLASSROOM_STUDENT_STATUS_COMPLETED = 5;

//Permission Types de diretórios (turma)
export const MEDIA_DIRECTORY_MACRO_ALLOW = 1;
export const MEDIA_DIRECTORY_MACRO_DENY = 2;
export const MEDIA_DIRECTORY_MACRO_CUSTOM = 3;

export const mediaDirectoryPermissions = [
    { id: MEDIA_DIRECTORY_MACRO_ALLOW, name: 'Permitir todos', machineName: 'Allow' },
    { id: MEDIA_DIRECTORY_MACRO_DENY, name: 'Negar todos', machineName: 'Deny' },
    { id: MEDIA_DIRECTORY_MACRO_CUSTOM, name: 'Customizar', machineName: 'Custom' }
];

//Permission Types de diretórios (aluno)
export const MEDIA_DIRECTORY_STUDENT_ALLOW = 1;
export const MEDIA_DIRECTORY_STUDENT_DENY = 2;

export const mediaDirectoryCustomPermissions = [
    { id: MEDIA_DIRECTORY_STUDENT_ALLOW, name: 'Permitir todos', machineName: 'Allow' },
    { id: MEDIA_DIRECTORY_STUDENT_DENY, name: 'Negar todos', machineName: 'Deny' }
];

//Opções padrão
export const PERSON_LOOKUP_OPTION_NONE = "Não encontrei quem eu queria...";



export const constants = {
    questionTypes: questionTypes,
    genders: genders,
    documentFieldTypes: documentFieldTypes,
    entitiesLabel: entitiesLabel,
    mediaDirectoryPermissions: mediaDirectoryPermissions,
    mediaDirectoryCustomPermissions: mediaDirectoryCustomPermissions,
}

export default constants;