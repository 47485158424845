import React from "react";
import PageHeader from "presentation/pages/layout/elements/page-header";
import { Container, Row, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import activityCategoriesService from "presentation/services/activity-categories";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import ActivityCategoryForm from 'presentation/pages/activity-categories/elements/form';

import { handleChange } from "presentation/utils/functions";

class ActivityCategoryEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
    };
    this.loadData = this.loadData.bind(this);

  }

  async componentDidMount() {
    const { id } = this.props.match.params
    await this.loadData(id);
  }

  loadData = async (id) => {
    await activityCategoriesService.findById(id).then((result) => {
      this.setState({ form: result, loading: false });
    });
  }

  goTo = (destination) => {
    const { history } = this.props;

    history.push(destination);
  };

  handleSubmit = async () => {

    this.setState({ loading: true, actionLoading: true, error: '' });

    const {
      name,
    } = this.state.form;

    if (!name) {
      this.setState({ loading: false, error: true, message: "Por favor, preencha um nome..." });
    } else {
      let postData = this.state.form;
      try {
        const response = await activityCategoriesService.update(postData);
        if (response.error == true) {
          this.setState({
            loading: false,
            actionLoading: false,
            error: true,
            message: response.message
          });
        } else {

          this.goTo("/categorias-atividades");
        }
      } catch (err) {
        this.setState({
          loading: false,
          actionLoading: false,
          error: true,
          message: err.message
        });
      }
    }
  };

  render() {
    let { location } = this.props;
    let { loading, error, message, actionLoading, form } = this.state;
    let { handleSubmit } = this;


    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <Container>
            <Row>
              <ActivityCategoryForm
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                loading={loading}
                form={form}
                component={this}
              />
            </Row>
          </Container>
        )
        }
      </div>
    );
  };
}

export default ActivityCategoryEdit;
