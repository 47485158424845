import React from "react";
import { Modal, Button, Table, Container } from "react-bootstrap";

import { handleResultData, getMediaType, getYoutubeId, getMediaDirectoryCustomPermission } from "presentation/utils/functions";

import mediaFilesService from "presentation/services/media-files";
import mediaAccessHistoriesService from "presentation/services/media-access-histories";

import {
    MEDIA_FILE_TYPE_FILE,
    MEDIA_FILE_TYPE_LINK,
    MEDIA_FILE_TYPE_YOUTUBE
} from "presentation/model/constants";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import Paginator from "presentation/components/paginator";

import { getUser } from 'presentation/utils/authentication';

class StudentMediaFilesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            actionLoading: false,
            error: false,
            message: '',
            filterForm: {},
            data: { items: [], totalItems: 0 },
            filterForm: {
                classroomId: [],
                disciplineId: [],
                searchTitle: "",
                isPaginatedSearch: true,
                pageNumber: 1,
            },
            showYoutube: false,
            youtubeId: null
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        var { filterForm } = this.state;
        let { disciplineId, classroomId, studentId } = this.props;

        filterForm.classroomId = classroomId;
        filterForm.disciplineId = disciplineId;
        filterForm.studentId = studentId;

        await mediaFilesService.getMediaOf(filterForm).then((result) => {
            let handledResult = handleResultData(result);

            this.setState({ data: handledResult, loading: false });
        });
    }

    loadPage = (page) => {

        this.setState({
            ...this.state,
            filterForm: {
                ...this.state.filterForm,
                pageNumber: page
            }
        }, () => this.loadData());
    }

    accessMediaFile = async (element) => {
        let { disciplineId, classroomId, studentId } = this.props;
        let user = getUser();
        let userId = user.id;

        var uri = element.storageUri;
        var elementMediaType = getMediaType(element.mediaType);

        var mediaType = typeof (elementMediaType) === 'undefined' ? MEDIA_FILE_TYPE_LINK : elementMediaType.id;

        await mediaAccessHistoriesService.register({ studentId: studentId, userId: userId, classroomId: classroomId, disciplineId: disciplineId, mediaId: element.id });

        switch (mediaType) {
            case MEDIA_FILE_TYPE_FILE:
            case MEDIA_FILE_TYPE_LINK:
                {
                    let destination = uri.match(/^http[s]?:\/\//) ? uri : 'http://' + uri;
                    window.open(destination);
                } break;
            case MEDIA_FILE_TYPE_YOUTUBE: {
                this.setState({ showYoutube: true, youtubeId: getYoutubeId(uri) });
            } break;
        }

    }

    onCloseYoutube = () => {
        this.setState({ showYoutube: false, youtubeId: null });
    }

    render() {
        let { show, onClose, classroomName, disciplineName } = this.props;

        const { loading, actionLoading, error, message, data, youtubeId, showYoutube } = this.state;

        const { loadPage, accessMediaFile, onCloseYoutube } = this;

        let hasData = data.items != null && data.items.length > 0;

        return (
            <Modal fullscreen backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title> {classroomName} / {disciplineName} / Materiais </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
                    {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                    {loading === false && error === false && !hasData && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
                    {loading === false && (
                        <Container className="mt-4">
                            {hasData == true && (
                                <div className="mt-4 mb-4" >
                                    <Paginator paginatorData={data} goToPage={loadPage} />
                                </div>
                            )}
                            <h6 className="float-end">Total de arquivos: {data.totalItems}</h6>
                            <div className="clearfix"></div>
                            <Table bordered borderless className="mt-4">
                                <thead>
                                    <tr>
                                        <th>Título</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {hasData == true && data.items.map((element, index) => {
                                        return (
                                            <tr key={element.id}>
                                                <td><div className="table-cell-height-limited">{element.title} <br /> <small>{element.description}</small></div></td>
                                                <td className="text-end">
                                                    <Button variant="success" onClick={async (e) => { await accessMediaFile(element) }}>Acessar</Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            {hasData == true && <Paginator paginatorData={data} goToPage={loadPage} />}
                        </Container>
                    )}
                    {showYoutube == true && (
                        <Modal size="lg" backdrop="static" show={showYoutube || false} onHide={onCloseYoutube}>
                            <Modal.Header closeButton>
                                <Modal.Title> Assistir vídeo </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <center>
                                    <iframe width="100%" height="400" src={`https://www.youtube-nocookie.com/embed/${youtubeId}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </center>
                            </Modal.Body>
                        </Modal>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Mudei de ideia
                    </Button>
                </Modal.Footer>
            </Modal >
        );
    };
}

export default StudentMediaFilesModal;