import React from "react";
import { Form, Button, Container, Row, Nav, Col, Tab } from "react-bootstrap";

import Tree from "rc-tree";
import PageAlert from "presentation/components/page-alert";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";

import { handleChange, treeView, handleChangeTree } from "presentation/utils/functions";

import { yearsList } from 'presentation/pages/questions/functions/questions';

import { questionTypes } from 'presentation/model/constants';

import questionDifficultiesService from "presentation/services/question-difficulties";
import questionOriginsService from "presentation/services/question-origins";
import questionOriginVariationsService from "presentation/services/question-origin-variations";
import questionTopicsService from "presentation/services/question-topics";
import disciplinesService from "presentation/services/disciplines";


class QuestionFilterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: false,
            localError: false,
            message: '',
            disciplines: [],
            questionOrigins: [],
            questionOriginVariations: [],
            questionTopics: [],
            questionDifficulties: [],
        }

    }

    async componentDidMount() {
        await this.loadLocalData();
    }

    loadLocalData = async () => {
        let questionDifficulties = [];
        let questionOrigins = [];
        let questionOriginVariations = [];
        let questionTopics = [];
        let disciplines = [];
        let localError = false;
        let localLoading = true;
        let errorMessages = [];
        let message = '';

        try {

            await questionDifficultiesService.findAll().then((difficultiesResult) => {
                if (difficultiesResult.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar os níveis de dificuldade...");
                } else {
                    questionDifficulties = difficultiesResult;
                }
            });


            await questionOriginsService.findAll().then((originsResults) => {
                if (originsResults.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar as origens das questões...");
                } else {
                    questionOrigins = originsResults;
                }
            });

            await questionOriginVariationsService.findAll().then((originVariationsResults) => {
                if (originVariationsResults.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar as variações de origens das questões...");
                } else {
                    questionOriginVariations = originVariationsResults;
                }
            });


            await questionTopicsService.findAllWithDisciplineAsRoot().then((questionTopicsResults) => {
                if (questionTopicsResults.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar os tópicos das questões...");
                } else {
                    questionTopics = questionTopicsResults;
                }
            });

            await disciplinesService.findAll().then((disciplinesResults) => {
                if (disciplinesResults.error == true) {
                    localError = true;
                    errorMessages.push("Não foi possível recuperar as disciplinas...");
                } else {
                    disciplines = disciplinesResults;
                }
            });

            if (localError === true) {
                localLoading = false;
                message = errorMessages.join(" / ");
            } else {
                localError = false;
                localLoading = false;
            }

            this.setState({
                ...this.state,
                questionDifficulties: questionDifficulties,
                questionOrigins: questionOrigins,
                questionOriginVariations: questionOriginVariations,
                questionTopics: questionTopics,
                disciplines: disciplines,
                localError: localError,
                localLoading: localLoading,
                message: message
            });
        } catch (err) {
            this.setState({
                localLoading: false,
                localError: true,
                message: err.message
            });
        }

    }

    render() {

        let { component, onSubmit, onClearFilter, submitTitle, clearFilterTitle } = this.props;

        const { filterForm, loading } = component.state;

        const {
            questionDifficulties,
            questionOrigins,
            questionOriginVariations,
            questionTopics,
            disciplines,
            localError,
            localLoading,
            message
        } = this.state;
        const years = yearsList();

        let filteredDisciplines = filterForm.disciplineId || [];
        let filteredQuestionTopics = questionTopics;

        if (filteredDisciplines.length > 0) {
            filteredQuestionTopics = filteredQuestionTopics.filter(x => filteredDisciplines.includes(x.disciplineId.toString()));
        }

        let disciplineQuestionTopics = treeView(filteredQuestionTopics);


        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (
                        <Form>

                            <Container>
                                <Row>
                                    <Col xs={12}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor="searchText">Que no Enunciado contenham o texto</Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="searchText"
                                                name="searchText"
                                                value={filterForm.searchText}
                                                onChange={(e) => handleChange(e, component, 'filterForm')}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor={`disciplineId`}>Discilina</Form.Label>
                                            <Form.Select
                                                aria-label="Disciplina"
                                                id={`disciplineId`}
                                                name={`disciplineId`}
                                                multiple={true}
                                                data-field-name="disciplineId"
                                                value={filterForm.disciplineId}
                                                onChange={(e) => handleChange(e, component, 'filterForm')}
                                                style={{ minHeight: 200 }}
                                            >
                                                {disciplines.map((e, i) => { return <option key={`question-filter-discipline-${e.id}`} value={e.id}>{e.name}</option> })}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor={`questionTopicId`}>Tópico</Form.Label>
                                            <Tree
                                                multiple={true}
                                                selectedKeys={filterForm.questionTopicId}
                                                height={200}
                                                itemHeight={20}
                                                style={{ border: '1px solid #eee', padding: '10px', minHeight: 200 }}
                                                treeData={disciplineQuestionTopics}
                                                onSelect={(selectedNodes, e) => handleChangeTree('questionTopicId', selectedNodes, component, 'filterForm')}
                                            />
                                        </div>
                                    </Col>

                                    <Col xs={6}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor={`questionOriginId`}>Origem</Form.Label>
                                            <Form.Select
                                                aria-label="Origem da questão"
                                                multiple={true}
                                                id={`questionOriginId`}
                                                name={`questionOriginId`}
                                                data-field-name="questionOriginId"
                                                value={filterForm.questionOriginId}
                                                onChange={(e) => handleChange(e, component, 'filterForm')}
                                            >
                                                {questionOrigins.map((e, i) => { return <option key={`question-filter-origin-${e.id}`} value={e.id}>{e.name}</option> })}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor={`questionOriginVariationId`}>Variação</Form.Label>
                                            <Form.Select
                                                aria-label="Variação da origem da questão"
                                                multiple={true}
                                                id={`questionOriginVariationId`}
                                                name={`questionOriginVariationId`}
                                                data-field-name="questionOriginVariationId"
                                                value={filterForm.questionOriginVariationId}
                                                onChange={(e) => handleChange(e, component, 'filterForm')}
                                            >
                                                {questionOriginVariations.map((e, i) => { return <option key={`question-filter-variation-${e.id}`} value={e.id}>{e.name}</option> })}
                                            </Form.Select>
                                        </div>
                                    </Col>


                                    <Col xs={4}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor={`questionYear`}>Ano</Form.Label>
                                            <Form.Select
                                                aria-label="Ano da questão"
                                                id={`questionYear`}
                                                name={`questionYear`}
                                                multiple={true}
                                                data-field-name="questionYear"
                                                value={filterForm.questionYear}
                                                onChange={(e) => handleChange(e, component, 'filterForm')}
                                            >
                                                {years.map((e, i) => { return <option key={`question-filter-year-${e.year}`} value={e.year}>{e.year}</option> })}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor={`questionDifficultyId`}>Nível de dificuldade</Form.Label>
                                            <Form.Select
                                                aria-label="Dificuldade da questão"
                                                multiple={true}
                                                id={`questionDifficultyId`}
                                                name={`questionDifficultyId`}
                                                data-field-name="questionDifficultyId"
                                                value={filterForm.questionDifficultyId}
                                                onChange={(e) => handleChange(e, component, 'filterForm')}
                                            >
                                                {questionDifficulties.map((e, i) => { return <option key={`question-filter-difficulty-${e.id}`} value={e.id}>{e.name}</option> })}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div className="form mt-2">
                                            <Form.Label htmlFor="questionTypeId">Tipo de questão</Form.Label>
                                            <Form.Select

                                                aria-label="Tipo de questão"
                                                multiple={true}
                                                id="questionTypeId"
                                                name="questionTypeId"
                                                value={filterForm.questionTypeId}
                                                onChange={(e) => handleChange(e, component, 'filterForm')}
                                            >
                                                {questionTypes.map((e, i) => { return <option key={`question-filter-question-type-${e.id}`} value={e.id}>{e.name}</option> })}
                                            </Form.Select>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Container>
                                <Row className="mt-2">
                                    <div className="d-flex justify-content-end">
                                        <Button style={{ marginRight: '10px' }} disabled={loading || localLoading || localError} variant="light" onClick={() => onClearFilter()}>{clearFilterTitle || "Limpar filtros"}</Button>
                                        <Button disabled={loading || localLoading || localError} variant="success" onClick={() => onSubmit()}>{submitTitle || "Filtrar"}</Button>
                                    </div>
                                </Row>
                            </Container>
                        </Form >
                    )
                }
            </>
        );
    };
}

export default QuestionFilterForm;