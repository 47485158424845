import React from "react";
import { Container, Row, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import Paginator from "presentation/components/paginator";
import PageModal from "presentation/components/page-modal";

import { handleResultData } from "presentation/utils/functions";

import mediaFilesService from "presentation/services/media-files";
import { getUser } from 'presentation/utils/authentication';

class MediaFileMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      actionLoading: false,
      error: false,
      message: '',
      data: { items: [], totalItems: 0 },
      showConfirmModal: false,
      targetDeleteId: null,
      filterForm: {
        classroomId: [],
        disciplineId: [],
        searchTitle: "",
        isPaginatedSearch: true,
        pageNumber: 1,
      }
    };
    this.loadData = this.loadData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    var { filterForm } = this.state;

    const user = getUser();

    if (user.isTeacher && !user.isMaster) {
      filterForm.userId = [user.id];
    }

    await mediaFilesService.search(filterForm).then((result) => {
      let handledResult = handleResultData(result);

      this.setState({ data: handledResult, loading: false });
    });
  }

  loadPage = (page) => {

    this.setState({
      ...this.state,
      filterForm: {
        ...this.state.filterForm,
        pageNumber: page
      }
    }, () => this.loadData());
  }

  openModal = (id) => {
    this.setState({ targetDeleteId: id, showConfirmModal: true });
  }

  closeModal = () => {
    this.setState({ showConfirmModal: false, targetDeleteId: null });
  }

  deleteItem = () => {
    const { loadData } = this;
    if (this.state.targetDeleteId) {
      this.setState({ loading: true, actionLoading: true });
      mediaFilesService.remove(this.state.targetDeleteId).then((result) => {
        this.setState({ loading: false, actionLoading: false, targetDeleteId: null, showConfirmModal: false }, () => {
          loadData();
        });
      });
    }
  }

  render() {
    let { location } = this.props;

    const { loading, actionLoading, error, message, data, showConfirmModal } = this.state;

    const { deleteItem, closeModal, openModal, loadPage } = this;

    let hasData = data.items != null && data.items.length > 0;

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error === false && !hasData && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
        {loading === false && (
          <Container>
            <Row>
              <h4>Listagem de materiais <Button as={Link} to={'/materiais/adicionar'} variant="outline-success" className="float-end">Adicionar</Button></h4>
              <Container className="mt-4">
                {hasData == true && (
                  <div className="mt-4 mb-4" >
                    <Paginator paginatorData={data} goToPage={loadPage} />
                  </div>
                )}
                <h6 className="float-end">Total de arquivos: {data.totalItems}</h6>
                <div className="clearfix"></div>
                <Table bordered borderless className="mt-4">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Título</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {hasData == true && data.items.map((element, index) => {
                      return (
                        <tr key={element.id}>
                          <td>{element.id}</td>
                          <td><div className="table-cell-height-limited">{element.title}</div></td>
                          <td className="text-end">
                            <Button as={Link} to={`/materiais/editar/${element.id}`} variant="outline-success">Editar</Button>{' '}
                            <Button variant="outline-secondary" onClick={() => openModal(element.id)}>Remover</Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {hasData == true && <Paginator paginatorData={data} goToPage={loadPage} />}
              </Container>
            </Row>
            <PageModal
              show={showConfirmModal}
              onClose={closeModal}
              onConfirm={deleteItem} />
          </Container>
        )}
      </div>
    );
  };
}

export default MediaFileMain;
