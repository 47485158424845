//ReportType Filters
import ActivityResultsReportFilter from 'presentation/pages/reports/filters/activity-results-report-filter'
import MediaAccessHistoryReportFilter from 'presentation/pages/reports/filters/media-access-history-report-filter'

export const REPORT_TYPE_EMPTY = -1;
export const REPORT_TYPE_ACTIVITY_RESULTS = 1;
export const REPORT_TYPE_MEDIA_ACCESS_HISTORY = 2;

export const getReportTypes = () => {
    return [
        {
            id: REPORT_TYPE_EMPTY,
            name: 'Selecione',
        },
        {
            id: REPORT_TYPE_ACTIVITY_RESULTS,
            name: 'Relação de atividades x Aproveitamento',
        },
        {
            id: REPORT_TYPE_MEDIA_ACCESS_HISTORY,
            name: 'Acesso aos materiais',
        }

    ]
}

export const renderReportFilters = (reportType, params) => {
    let output = null;
    var reportFilters = [
        {
            key: REPORT_TYPE_ACTIVITY_RESULTS,
            element: <ActivityResultsReportFilter disciplines={params.disciplines} classrooms={params.classrooms} history={params.history} />
        },
        {
            key: REPORT_TYPE_MEDIA_ACCESS_HISTORY,
            element: <MediaAccessHistoryReportFilter disciplines={params.disciplines} classrooms={params.classrooms} history={params.history} />
        }
    ];
    if (reportFilters.filter(x => x.key == reportType)[0]) {
        output = reportFilters.filter(x => x.key == reportType)[0].element;
    }
    return output;
}

const reportFunctions = {
    getReportTypes,
    renderReportFilters
}

export default reportFunctions;