import React from "react";
import { Modal, Button, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

class StudentActivitiesModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filterForm: {},
            selectedItems: [],
            data: []
        };
    }

    render() {
        let { show, onClose, directories, classroomName, disciplineName, scores, readOnly } = this.props;

        return (
            <Modal fullscreen backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title> {classroomName} / {disciplineName} / Atividades </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Tabs
                            id="activity-directories"
                            className="mb-3"
                        >
                            {directories.length > 0 && directories.map((directory, di) => {
                                return (
                                    <Tab key={`activity-tab-${directory.id}`} eventKey={directory.id} title={directory.name}>
                                        {directory.activities.length > 0 && directory.activities.map((element, index) => {
                                            let alreadySelected = false;
                                            let isAvailable = element.isAvailable;
                                            let scoreData = scores.filter(x => x.activityId == element.activityId)[0];

                                            return (
                                                <Card key={element.activityId} className={`${alreadySelected ? 'active-card' : ''} mt-5`} >
                                                    <Card.Body>
                                                        <p className="lead">{element.activityTitle}</p>
                                                        {element.availabilityMessage && <small className="text-muted">{element.availabilityMessage}</small>}
                                                        {isAvailable == false && scoreData && (
                                                            <div>
                                                                <br />
                                                                Essa atividade contém {scoreData.totalQuestions} {scoreData.totalQuestions == 1 ? "questão" : "questões"}
                                                                <strong>{scoreData.maxObtainableScore > 0 && ` valendo ${scoreData.maxObtainableScore} ponto(s)`}</strong>
                                                                <br />
                                                                <br />
                                                                {scoreData.maxObtainableScore > 0 && <p>Sua pontuação total foi: <strong>{scoreData.evaluatedScore}</strong></p>}
                                                                Você acertou <strong>{scoreData.totalCorrectAnswers}</strong> {scoreData.totalCorrectAnswers == 1 ? "questão" : "questões"}
                                                            </div>
                                                        )}
                                                        <Button disabled={isAvailable == false || readOnly == true} className="float-end" as={Link} to={{ pathname: `/atividades/responder/`, state: { activityId: element.activityId, studentId: element.studentId } }} variant={isAvailable == false ? (element.activityWasAnswered == true ? "success" : "danger") : "outline-success"}>{isAvailable === false ? (element.activityWasAnswered == true ? "Concluída" : "Encerrada") : (readOnly == true? "Disponível para o aluno": "Responder")}</Button>{' '}
                                                    </Card.Body>

                                                </Card>
                                            );
                                        })}
                                    </Tab>
                                )
                            })}
                        </Tabs>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onClose}>
                        Mudei de ideia
                    </Button>
                </Modal.Footer>
            </Modal >
        );
    };
}

export default StudentActivitiesModal;