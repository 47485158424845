import React from "react";
import PageHeader from "presentation/pages/layout/elements/page-header";
import { Container, Row, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import PageModal from "presentation/components/page-modal";

import { handleResultData } from "presentation/utils/functions";

import classroomsService from "presentation/services/classrooms";
import activitiesService from "presentation/services/activities";

class ClassroomActivitiesMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      actionLoading: false,
      error: false,
      message: '',
      data: [],
      classroom: {
        name: ''
      },
      showConfirmModal: false,
      showAddActivityModal: false,
      targetDeleteId: null,
    };
    this.loadData = this.loadData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  async componentDidMount() {
    const { id } = this.props.match.params
    await this.loadData(id);
  }

  loadData = async (id) => {

    await classroomsService.findById(id).then((result) => {
      let handledResult = handleResultData(result);

      this.setState({ classroom: handledResult });
    });

    await activitiesService.search({ classroomId: [id] }).then((result) => {
      let handledResult = handleResultData(result);

      this.setState({ data: handledResult, loading: false });
    });
  }

  openModal = (id) => {
    this.setState({ targetDeleteId: id, showConfirmModal: true });
  }

  closeModal = () => {
    this.setState({ showConfirmModal: false, targetDeleteId: null });
  }

  openAddActivityModal = () => {
    this.setState({ showAddActivityModal: true });
  }

  closeAddActivityModal = () => {
    this.setState({ showAddActivityModal: false });
  }

  deleteItem = () => {
    const { id } = this.props.match.params
    const { loadData } = this;
    if (this.state.targetDeleteId) {
      this.setState({ loading: true, actionLoading: true });
      activitiesService.remove(this.state.targetDeleteId).then((result) => {
        this.setState({ loading: false, actionLoading: false, targetDeleteId: null, showConfirmModal: false }, () => {
          loadData(id);
        });
      });
    }
  }

  render() {
    const { id } = this.props.match.params;

    const { loading, actionLoading, error, message, data, showConfirmModal, showAddActivityModal, classroom } = this.state;

    const { deleteItem, closeModal, openModal } = this;

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
        {loading === false && (
          <Container>
            <Row>
              <h4>Atividades da turma {classroom.name} <Button as={Link} to={`/atividades/adicionar/${classroom.id}`} variant="outline-success" className="float-end">Adicionar</Button></h4>
              <Container>
                <Table bordered borderless className="mt-4">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Titulo</th>
                      <th>Disciplina</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 && data.map((element, index) => {
                      return (
                        <tr key={element.id}>
                          <td>{element.id}</td>
                          <td>{element.title}</td>
                          <td>{element.discipline.name}</td>
                          <td className="text-end">
                            <Button as={Link} to={`/atividades/editar/${element.id}/${classroom.id}`} variant="outline-success">Editar</Button>{' '}
                            <Button variant="outline-secondary" onClick={() => openModal(element.id)}>Remover</Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Container>
              <div className="mt-3">
                <Button as={Link} to={`/turmas`} className="float-end" variant="secondary">Mudei de ideia</Button>
              </div>
            </Row>
            <PageModal
              show={showConfirmModal}
              onClose={closeModal}
              onConfirm={deleteItem} />
          </Container>
        )}
      </div>
    );
  };
}

export default ClassroomActivitiesMain;
