import React from "react";

import activitiesService from "presentation/services/activities";
import activitiesQuestionsService from "presentation/services/activity-questions";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import AnswersSheetForm from 'presentation/pages/activities/elements/answers-sheet-form';

class ActivityAnswersSheet extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
      activity: [],
      questions: [],
      answers: [],
    };

    this.loadData = this.loadData.bind(this);

  }

  async componentDidMount() {
    const { id } = this.props.match.params;


    if (id) {
      await this.loadData(id);
    } else {
      this.setState({ error: true, loading: false, message: "Não foi possível identificar a atividade" });
    }

  }


  loadData = async (rawId) => {

    var activityId = parseInt(rawId);
    var actityResults = await activitiesService.findById(activityId);
    var actityQuestionsResults = await activitiesQuestionsService.search({ activityId: [activityId] });
    await Promise.all([actityResults, actityQuestionsResults]);

    this.setState({ activity: actityResults, questions: actityQuestionsResults, loading: false });
  }

  render() {
    let { loading, error, message, actionLoading, activity } = this.state;
    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error == false && (
          <AnswersSheetForm
            component={this}
            returnTo={`/atividades/entregas/${activity.id}/${activity.classroomId}/${activity.disciplineId}`}
          />
        )
        }
      </div>
    );
  };
}

export default ActivityAnswersSheet;
