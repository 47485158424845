import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Col } from "react-bootstrap";

import activitiesService from "presentation/services/activities";
import activitiesQuestionsService from "presentation/services/activity-questions";
import activitiesQuestionCommentsService from "presentation/services/activity-question-comments";
import activitiesAnswersService from "presentation/services/activity-answers";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import CorrectionsForm from 'presentation/pages/activities/elements/corrections-form';

class ActivityCorrections extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
      activity: [],
      questions: [],
      answers: [],
      students: [],
      corrections: [],
    };

    this.loadData = this.loadData.bind(this);

  }

  async componentDidMount() {
    const { id } = this.props.match.params;


    if (id) {
      await this.loadData(id);
    } else {
      this.setState({ error: true, loading: false, message: "Não foi possível identificar a atividade" });
    }

  }

  mountAnswers = (rawAnswers) => {
    let answers = [];

    for (let i = 0; i < rawAnswers.length; i++) {
      let element = rawAnswers[i];
      let questionId = element.questionId;
      let studentId = element.studentId;
      let answerItem = answers.filter(x => x.questionId == questionId && x.studentId == studentId)[0] || undefined;
      let questionOptionId = parseInt(element.questionOptionId);
      let observations = element.observations || "";
      let evaluationComments = element.evaluationComments || "";
      let successRate = typeof (element.successRate) === 'undefined' ? undefined : "" + element.successRate;

      if (answerItem) {
        if (!answerItem.questionOptionId.includes(questionOptionId)) {
          answerItem.questionOptionId.push(questionOptionId);
        } else {
          let currentOptions = answerItem.questionOptionId.filter(x => x !== questionOptionId);
          answerItem.questionOptionId = currentOptions;
        }
        answerItem.observations = observations;
        answerItem.successRate = successRate;
        answerItem.evaluationComments = evaluationComments;
      } else {
        answerItem = { studentId: studentId, questionId: questionId, questionOptionId: [questionOptionId], observations: observations, successRate: successRate, evaluationComments: evaluationComments };
      }

      var currentAnswers = answers.filter(x => !(x.questionId == answerItem.questionId && x.studentId == answerItem.studentId));

      answers = [...currentAnswers, answerItem];

    }

    return answers;
  }

  loadQuestionsComments = async (quesitonIds) => {
    var questionsComments = await activitiesQuestionCommentsService.myComments({ questionId: quesitonIds });
    this.setState({ questionsComments: questionsComments });
  }

  loadData = async (rawId) => {

    var activityId = parseInt(rawId);
    var actityResult = await activitiesService.findById(activityId);
    var activityQuestionResults = await activitiesQuestionsService.search({ activityId: [activityId] });
    var studentsResults = await activitiesService.activityStudents(activityId);
    var activityAnswerResults = await activitiesAnswersService.search({ activityId: [activityId] });

    await Promise.all([actityResult, activityQuestionResults, studentsResults, activityAnswerResults]);

    var questionIds = activityQuestionResults.length > 0 ? activityQuestionResults.map((x, i) => { return x.questionId }) : [];

    await this.loadQuestionsComments(questionIds);

    var answers = this.mountAnswers(activityAnswerResults);

    let error = false;
    let message = '';

    const validStudents = studentsResults.filter(x => x.activityWasAnswered == true);

    if (validStudents.length <= 0) {
      error = true;
      message = 'Os alunos ainda não preencheram essa atividade...';
    }

    this.setState({ activity: actityResult, questions: activityQuestionResults, students: studentsResults, answers: answers, rawAnswers: activityAnswerResults, loading: false, error: error, message: message });
  }

  render() {
    let { loading, error, message, actionLoading, activity } = this.state;
    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && (
          <>
            <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />
            <Container>
              <Row>
                <Col>
                  <Button size="sm" as={Link} to={`/atividades/entregas/${activity.id}/${activity.classroomId}/${activity.disciplineId}`} variant="secondary" className="float-start m-r-1 mt-4">Voltar</Button>
                </Col>
              </Row>
            </Container>
          </>
        )}
        {loading === false && error == false && (
          <CorrectionsForm
            component={this}
            loadQuestionsComments={this.loadQuestionsComments}
            returnTo={`/atividades/entregas/${activity.id}/${activity.classroomId}/${activity.disciplineId}`}
          />
        )
        }
      </div>
    );
  };
}

export default ActivityCorrections;
