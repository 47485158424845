import { questionTypes, genders, documentFieldTypes, mediaTypes, mediaDirectoryPermissions, mediaDirectoryCustomPermissions } from 'presentation/model/constants';

export const getMediaType = (search) => {
    return mediaTypes.filter((option) => option.machineName.toLowerCase() == search.toLowerCase())[0];
}

export const getQuestionType = (search) => {
    return questionTypes.filter((option) => option.machineName.toLowerCase() == search.toLowerCase())[0];
}

export const getGender = (search) => {
    return genders.filter((option) => option.machineName.toLowerCase() == search.toLowerCase())[0];
}

export const getMediaDirectoryPermission = (search) => {
    return mediaDirectoryPermissions.filter((option) => option.machineName.toLowerCase() == search.toLowerCase())[0];
}

export const getMediaDirectoryCustomPermission = (search) => {
    return mediaDirectoryCustomPermissions.filter((option) => option.machineName.toLowerCase() == search.toLowerCase())[0];
}

export const getDocumentFieldType = (search) => {
    return documentFieldTypes.filter((option) => option.machineName.toLowerCase() == search.toLowerCase())[0];
}

export const dateIsInvalid = (verifyDate) => {
    var invalid = ["", "Invalid date"]

    if (invalid.includes(verifyDate)) {
        return true;
    }

    return false;
};

export function treeView(flatList, topicId = null) {

    let tree = [];
    let lookup = {};
    flatList.forEach((o) => {
        o.key = o.id;
        o.title = o.name;
        if (o.id === topicId) {
            o.disabled = true;
            o.selectable = false;
        }

        if (typeof (o.id) === "string" && o.id.includes("disc")) {
            o.disabled = true;
            o.selectable = false;
        }

        lookup[o.id] = o;
        lookup[o.id].children = [];
    });
    flatList.forEach((o) => {
        if (o.parentId !== null) {
            lookup[o.parentId].children.push(o);
        } else {
            tree.push(o);
        }
    });
    return tree;
};

export const isNullOrEmpty = (searchString) => {
    let output = false;
    if (searchString === null) {
        output = true;
    } else {
        if (typeof (searchString) === "string") {
            if (searchString.trim() === "") {
                output = true;
            }
        }
    }
    return output;
}

export const handleResultData = (result) => {
    //Adicionando a o campo 'key' pra permitir a renderização da tabela sem erros
    //Adicionando a o campo 'index' pra permitir a ordenação da tabela (caso necessário)
    if (typeof (result) !== 'undefined' && result.length > 0) {
        for (var i = 0; i < result.length; i++) {
            result[i].key = result[i].id;
            result[i].index = i;
        }
    }
    return result;
}

const getInputValue = (e) => {
    const { value, checked, type, selectedOptions } = e.target;

    if (type == "checkbox") {
        return checked;
    }

    if (type == 'select-multiple') {
        let values = [];
        if (selectedOptions.length > 0) {

            for (let i = 0; i < selectedOptions.length; i++) {
                values.push(selectedOptions[i].value)
            }
        }
        return values;
    }

    return value;


}

export const handleChange = async (e, component, stateFormKey = 'form', callBack = null) => {
    const { name } = e.target;

    const changeValue = getInputValue(e);

    await component.setState({
        [stateFormKey]: {
            ...component.state[stateFormKey],
            [name]: changeValue
        }
    });
    
    if (typeof (callBack) === 'function') {
        callBack();
    }
}

export const handleChangeFile = async (e, component, stateFormKey = 'form') => {
    const { name } = e.target;

    const changeValue = e.target.files[0];

    await component.setState({
        [stateFormKey]: {
            ...component.state[stateFormKey],
            [name]: changeValue
        }
    });
}

export const handleChangeTree = (formField, selectedNodes, component, stateFormKey = 'form') => {

    component.setState({
        [stateFormKey]: {
            ...component.state[stateFormKey],
            [formField]: selectedNodes
        }
    });
}


export const handleChangeEditor = (formField, event, editor, component) => {
    const changeValue = editor.getData();

    component.setState({
        form: {
            ...component.state.form,
            [formField]: changeValue
        }
    });
}

export const handleTaleCheckAll = (e, component, ref) => {
    let checked = e.target.checked == true;
    let checkboxes = ref.current.querySelectorAll('input:not([disabled])[type=checkbox]');

    var checkedOptions = [];
    for (var i = 0; i < checkboxes.length; i++) {
        var checkbox = checkboxes[i];
        checkbox.checked = checked;

        if (checked) {
            checkedOptions.push(checkbox.getAttribute('data-option-value'));
        }
    }
    component.setState({ selectedItems: checkedOptions });
}

export const handleCheckTableItems = (e, item, component) => {
    let { selectedItems } = component.state;
    let selected = e.target.checked == true;


    let newSelected = selectedItems;
    if (selected) {
        newSelected.push(item);
    } else {
        newSelected = selectedItems.filter(x => x != item);
    }

    component.setState({ selectedItems: newSelected });
}

export const handleRadioTableItems = (key, fieldName, value, extraFields, component, selectedItemsKey = 'selectedItems') => {
    let selectedItems = component.state[selectedItemsKey];

    let newSelected = [];

    let newItem = { [fieldName]: value, key: key, ...extraFields };

    if (selectedItems != null) {
        newSelected = selectedItems.filter(x => x.key != key);
    }


    newSelected.push(newItem);

    component.setState({ [selectedItemsKey]: newSelected });
}

export const setComponentFlag = (component, flag, value) => {

    component.setState({
        componentFlags: {
            ...component.state.componentFlags,
            [flag]: value
        }
    });
}

export const componentFlagValue = (component, flag) => {
    let { componentFlags } = component.state;
    let output = (componentFlags ? (componentFlags[flag] ? true : false) : false);
    return output;
}

export const goTo = (destination, component) => {
    const { history } = component.props;

    history.push(destination);
};

export const getYoutubeId = (url) => {
    url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
}

export const functions = [
    handleResultData,
    handleChange,
    handleChangeFile,
    handleChangeTree,
    handleChangeEditor,
    handleTaleCheckAll,
    handleCheckTableItems,
    handleRadioTableItems,
    goTo,
    setComponentFlag,
    componentFlagValue,
    getQuestionType,
    isNullOrEmpty,
    treeView,
    dateIsInvalid,
    getGender,
    getMediaDirectoryPermission,
    getMediaDirectoryCustomPermission,
    getYoutubeId
];

export default functions;