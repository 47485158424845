import React from "react";

import activitiesService from "presentation/services/activities";
import activitiesQuestionsService from "presentation/services/activity-questions";
import activitiesAnswersService from "presentation/services/activity-answers";
import studentsService from "presentation/services/students";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import ActivityViewForm from 'presentation/pages/activities/elements/view-form';

import { mountAnswers } from 'presentation/pages/activities/functions/activities';

class ActivityView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
      activity: [],
      questions: [],
      answers: [],
    };

    this.loadData = this.loadData.bind(this);

  }

  async componentDidMount() {
    const { id, studentId } = this.props.match.params;


    if (id && studentId) {
      await this.loadData(id, studentId);
    } else {
      this.setState({ error: true, loading: false, message: "Não foi possível identificar a atividade/aluno" });
    }

  }

  loadData = async (rawId, rawStudentId) => {

    var activityId = parseInt(rawId);
    var studentId = parseInt(rawStudentId);
    var actityResults = await activitiesService.findById(activityId);
    var actityQuestionsResults = await activitiesQuestionsService.search({ activityId: [activityId] });
    var activityAnswerResults = await activitiesAnswersService.search({ activityId: [activityId], studentId: [studentId] });
    var studentResult = await studentsService.findById(studentId);
    await Promise.all([actityResults, actityQuestionsResults, activityAnswerResults, studentResult]);

    var answers = mountAnswers(activityAnswerResults);

    this.setState({ activity: actityResults, questions: actityQuestionsResults, answers: answers, student: studentResult, loading: false });
  }

  render() {
    let { loading, error, message, actionLoading, activity } = this.state;
    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error == false && (
          <ActivityViewForm
            component={this}
            returnTo={`/atividades/entregas/${activity.id}/${activity.classroomId}/${activity.disciplineId}`}
          />
        )
        }
      </div>
    );
  };
}

export default ActivityView;
