import React from "react";
import { Form, Button, Container, Row, Col, InputGroup } from "react-bootstrap";

import PageAlert from "presentation/components/page-alert";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";

import { handleChange } from "presentation/utils/functions";


class StudentFilterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: false,
            localError: false,
            message: ''
        }

    }

    render() {

        let { component, onSubmit, onSubmitTitle } = this.props;

        const { filterForm, loading } = component.state;

        const {
            localError,
            localLoading,
            message
        } = this.state;

        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (
                        <Form>

                            <Row>
                                <Col xs={7}>
                                    <div className="form mt-2">
                                        <Form.Label htmlFor="searchName">Nome</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="searchName"
                                            name="searchName"
                                            value={filterForm.searchName}
                                            onChange={(e) => handleChange(e, component, 'filterForm')}
                                        />
                                    </div>
                                </Col>
                                <Col xs={5}>
                                    <div className="form mt-2">
                                        <Form.Label htmlFor="searchRegistration">Matrícula</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                id="searchRegistration"
                                                name="searchRegistration"
                                                value={filterForm.searchRegistration}
                                                onChange={(e) => handleChange(e, component, 'filterForm')}
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="form mt-2">
                                    <Button disabled={loading || localLoading || localError} variant="success" className="float-end" onClick={() => onSubmit()}>{onSubmitTitle || 'Filtrar'}</Button>
                                </div>
                            </Row>
                        </Form >
                    )
                }
            </>
        );
    };
}

export default StudentFilterForm;