import React from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

class AcademicPeriodForm extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        let { loading, form, handleChange, handleSubmit, component } = this.props;
        return (
            <Form>
                <h4>Informações do período acadêmico</h4>
                <div className="form">
                    <Form.Label htmlFor="name">Nome</Form.Label>
                    <Form.Control
                        type="text"
                        id="name"
                        name="name"
                        defaultValue={form.name}
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="nameHelpBlock"
                    />
                    <Form.Text id="nameHelpBlock" muted>
                        Informe o nome do período acadêmico
                    </Form.Text>
                </div>
                <div className="form mt-2">
                    <Form.Label htmlFor="displayName">Nome de exibição</Form.Label>
                    <Form.Control
                        type="text"
                        id="displayName"
                        name="displayName"
                        defaultValue={form.displayName}
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="displayNameHelpBlock"
                    />
                    <Form.Text id="displayNameHelpBlock" muted>
                        Escolha um nome de exibição
                    </Form.Text>
                </div>
                <div className="form mt-2">
                    <Form.Label htmlFor="startDate">Data de início</Form.Label>
                    <Form.Control
                        type="date"
                        id="startDate"
                        name="startDate"
                        defaultValue={form.startDate}
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="startDateHelpBlock"
                    />
                    <Form.Text id="startDateHelpBlock" muted>
                        Escolha a data de início
                    </Form.Text>
                </div>
                <div className="form mt-2">
                    <Form.Label htmlFor="endDate">Data de encerramento</Form.Label>
                    <Form.Control
                        type="date"
                        id="endDate"
                        name="endDate"
                        defaultValue={form.endDate}
                        onChange={(e) => handleChange(e, component)}
                        aria-describedby="endDateHelpBlock"
                    />
                    <Form.Text id="endDateHelpBlock" muted>
                        Escolha a data de encerramento
                    </Form.Text>
                </div>
                <div className="mt-3">
                    <Button as={Link} to={'/periodos-academicos'} variant="secondary">Mudei de ideia</Button>
                    <Button disabled={loading} variant="success" className="float-end" onClick={() => handleSubmit()}>Salvar</Button>
                </div>
            </Form>
        );
    };
}

export default AcademicPeriodForm;