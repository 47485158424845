import React from "react";
import { Container, Row, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import ExcelExport from "presentation/components/excel-export";

import ActivityDatesModal from "presentation/pages/activities/elements/activity-dates-modal";

import { handleResultData } from "presentation/utils/functions";
import activitiesService from "presentation/services/activities";

class ActivityStudentsMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      actionLoading: false,
      error: false,
      message: '',
      data: [],
      resultsData: [],
      showConfirmModal: false,
      showAddStudentModal: false,
      targetDeleteId: null,
      activityDates: [],
      activityDateform: {}
    };
    this.loadData = this.loadData.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.afterSubmitModal = this.afterSubmitModal.bind(this);
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    await this.loadData(id);
  }

  loadData = async (id) => {
    var errorMessages = [];
    var errorMessage = '';
    var hasError = false;

    var baseData = await activitiesService.activityStudents(id).then((result) => {
      let response = handleResultData(result);

      let activityDates = [];

      if (response.length > 0) {
        response.map((e, i) => {
          let dateOverrideId = e.activityDateOverrideId || undefined;
          let item = {
            isRemove: false,
            id: dateOverrideId,
            key: e.studentId,
            studentId: e.studentId,
            activityId: e.activityId,
            classroomId: e.classroomId,
            disciplineId: e.disciplineId,
            startDate: dateOverrideId ? e.activityOverrideStartDate : undefined,
            endDate: dateOverrideId ? e.activityOverrideEndDate : undefined
          }
          activityDates.push(item);
        });
      }


      const activityData = response.length > 0 ? response[0] : {
        classroomName: null,
        disciplineName: null,
        classroomId: null,
        disciplineId: null,
        academicPeriodId: null,
        activityId: null,
        activityTitle: null,
        activityStartDate: null,
        activityEndDate: null,
      };

      const activityDateForm = {
        id: activityData.activityId,
        startDate: activityData.activityStartDate,
        endDate: activityData.activityEndDate,
        title: activityData.activityTitle,
        academicPeriodId: activityData.academicPeriodId,
        classroomId: activityData.classroomId,
        disciplineId: activityData.disciplineId,
      }

      if (response.error == true) {
        errorMessages.push(response.message);
      } else {
        this.setState({ data: response, activityDates: activityDates, activityData: activityData, activityDateForm: activityDateForm });
      }
    });

    var results = await activitiesService.activityResults({ activityId: [parseInt(id)] }).then((re) => {
      let handled = handleResultData(re);

      if (handled.error == true) {
        errorMessages.push(handled.message);
      } else {
        this.setState({ resultsData: handled });
      }

    });

    await Promise.all([results, baseData]);

    if (errorMessages.length > 0) {
      hasError = true;
      errorMessage = errorMessages.join(" / ");
    }

    this.setState({ loading: false, error: hasError, message: errorMessage });

  }

  openModal = () => {
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  afterSubmitModal = () => {
    const { id } = this.props.match.params
    this.setState({ showModal: false }, async () => await this.loadData(id));
  }

  formatToExport = (data, handledResultData) => {
    let output = [];

    data.map((element, index) => {
      var hasAnswer = element.activityWasAnswered == true;
      var studentResults = handledResultData.filter(x => x.studentId == element.studentId)[0] || {};
      output.push({
        "Matrícula": element.studentRegistration,
        "Nome": element.studentName,
        "Realizada?": hasAnswer == true ? ' sim ' : 'não',
        "Nota": studentResults.evaluatedScore || ' - ',
        "Total acertos": studentResults.totalCorrectAnswers || ' - ',
        "% Aproveitamento": studentResults.evaluatedSuccessRate || ' - ',
      }
      );
    })
    return output;
  };

  render() {
    const { id } = this.props.match.params;

    const { loading, actionLoading, error, message, data, showModal, activityData, resultsData } = this.state;

    const { closeModal, openModal, afterSubmitModal, formatToExport } = this;

    const studentsAnswered = data.filter(x => x.activityWasAnswered == true);
    const noAnswers = studentsAnswered.length <= 0;
    const handledResultData = resultsData.length > 0 ? resultsData : [];
    const maxObtainableScore = handledResultData[0] ? handledResultData[0].maxObtainableScore : " - ";

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />}
        {loading === false && (
          <Container>
            <Row>
              <Container>
                {activityData.activityId != null && (
                  <div>
                    <h6>{activityData.classroomName} / {activityData.disciplineName} / {activityData.activityTitle}</h6>
                    <p>
                      Data de abertura: <strong>{activityData.activityStartDate ? moment(activityData.activityStartDate).format("DD/MM/YYYY - HH:mm") : " - "}</strong>
                      <br />
                      Encerra em: <strong>{activityData.activityEndDate ? moment(activityData.activityEndDate).format("DD/MM/YYYY - hh:mm") : " - "}</strong>
                      <br />
                      Pontuação máxima: <strong>{maxObtainableScore}</strong>
                    </p>

                    <Button as={Link} disabled={noAnswers} to={`/atividades/avaliacao/${activityData.activityId}`} variant={`outline-${noAnswers ? "secondary" : "success"}`} className="float-end">Avaliação</Button>
                    <Button as={Link} to={`/atividades/gabarito/${activityData.activityId}`} variant="outline-success" className="float-end m-r-1">Gabarito</Button>
                    <Button onClick={() => openModal()} variant="outline-success" className="float-end m-r-1">Prazos</Button>
                    <ExcelExport excelData={formatToExport(data, handledResultData)} fileName={`relatorio-aproveitamento-${activityData.classroomName}-atividade-${activityData.activityId}`} className="float-end m-r-1" />
                    <br />
                  </div>
                )}
                {data.length > 0 && (
                  <>
                    <Table bordered borderless className="mt-4">
                      <thead>
                        <tr>
                          <th>Matrícula</th>
                          <th>Nome</th>
                          <th>Realizada?</th>
                          <th>Nota</th>
                          <th>Total acertos</th>
                          <th>% Aproveitamento</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((element, index) => {
                          var hasAnswer = element.activityWasAnswered == true;
                          var studentResults = handledResultData.filter(x => x.studentId == element.studentId)[0] || {};
                          return (
                            <tr key={element.studentId}>
                              <td>{element.studentRegistration}</td>
                              <td>{element.studentName}</td>
                              <td>{hasAnswer == true ? ' sim ' : 'não'}</td>
                              <td> {studentResults.evaluatedScore || ' - '} </td>
                              <td> {studentResults.totalCorrectAnswers || ' - '} </td>
                              <td> {studentResults.evaluatedSuccessRate || ' - '} </td>
                              <td className="text-end">
                                <Button as={Link} to={`/atividades/entregas/ver/${element.activityId}/${element.studentId}`} disabled={!hasAnswer} variant={`outline-${hasAnswer ? 'success' : 'secondary'}`} >Ver</Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>

                )}

              </Container>
              <div className="mt-3">
                <Button as={Link} to={`/atividades`} className="float-end" variant="secondary">Mudei de ideia</Button>
              </div>
            </Row>
            <ActivityDatesModal
              show={showModal}
              onClose={closeModal}
              afterSubmit={afterSubmitModal}
              students={data}
              activityData={activityData}
              component={this}
            />
          </Container>
        )}
      </div>
    );
  };
}

export default ActivityStudentsMain;
