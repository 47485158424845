import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col, Breadcrumb, ProgressBar, Fade } from "react-bootstrap";
import parse from 'html-react-parser';
import PageAlert from "presentation/components/page-alert";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";

class AnswersSheetForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: false,
            localError: false,
            message: '',
            activeKey: 0,
        }

    }

    setActive = (key) => {
        this.setState({ activeKey: parseInt(key) });
    }

    isActive = (itemKey) => {
        const { activeKey } = this.state;
        let response = itemKey == activeKey;
        return response;
    };

    render() {

        let { component, returnTo } = this.props;

        const { loading, activity, questions: rawQuestions } = component.state;

        const questions = rawQuestions.sort((a, b) => a.order - b.order);

        let { localError, localLoading, message, activeKey } = this.state;

        const { isActive, setActive } = this;

        const totalQuestions = questions.length;


        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {(localLoading === false && localLoading === false && questions.length <= 0) && <PageAlert className="m-b-20" message={"Erro"} description={"A atividade selecionada não tem questões para responder..."} type="danger" />}
                {loading === false && localLoading === false && questions.length >= 0 && (
                    <Form>
                        <Container className="pb-5">
                            <Breadcrumb className="site-breadcrumb">
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.classroom.displayName}</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.discipline.displayName}</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.title}</span>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <Row className="m-b-4 activity-options-container">
                                <p className="text-muted"><strong>Gabarito</strong></p>
                                {questions.map((e, i) => {
                                    var isActiveItem = isActive(i);
                                    var options = e.question.questionOptions;
                                    var firstOption = options[0];
                                    var questionType = e.question.questionTypeId;
                                    var allowMultipleAnswers = e.question.allowMultipleOptionsAnswers;
                                    return (
                                        <div key={e.id} className="activity-answers-view">
                                            <Fade in={isActiveItem}>
                                                <div>
                                                    {
                                                        isActiveItem &&
                                                        <>
                                                            <h6>Questão {i + 1}</h6>
                                                            <hr />
                                                            <div className="mb-4">
                                                                {parse(e.question.title)}
                                                            </div>
                                                            {questionType == 1 && allowMultipleAnswers == false && options.map((o, j) => {
                                                                return (
                                                                    <div key={`opt-${o.id}`} className={o.isCorrect == true ? "activity-answer-option-correct" : "activity-answer-option"}>
                                                                        <Form.Check
                                                                            disabled
                                                                            type="radio"
                                                                            label={parse(o.title)}
                                                                            name={`opt-${o.questionId}`}
                                                                            value={o.id}
                                                                            checked={o.isCorrect}

                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                            {questionType == 1 && allowMultipleAnswers == true && options.map((o, j) => {
                                                                return (
                                                                    <div key={`opt-${o.id}`} className={o.isCorrect == true ? "activity-answer-option-correct" : "activity-answer-option"}>
                                                                        <Form.Check
                                                                            disabled
                                                                            type="checkbox"
                                                                            label={parse(o.title)}
                                                                            name={`opt-${o.questionId}-item${o.id}`}
                                                                            value={o.id}
                                                                            checked={o.isCorrect}
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                            {questionType == 2 && (
                                                                <div key={`opt-${firstOption.id}`} className="activity-answer-option-correct">
                                                                    {parse(firstOption.title || '- resposta padrão não cadastrada -')}
                                                                </div>
                                                            )}
                                                        </>
                                                    }
                                                </div>
                                            </Fade>
                                        </div>
                                    )
                                })}
                            </Row>
                        </Container>
                        <Container fluid className="align-middle activity-navigation">
                            <Row>
                                <Col>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <ProgressBar className="mt-2" now={100} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button size="sm" as={Link} to={returnTo || "/"} variant="secondary" className="float-start m-r-1 mt-4">Sair</Button>
                                                <Button size="sm" variant="success" className="float-start m-r-1 mt-4" disabled={activeKey == 0} onClick={() => setActive(activeKey - 1)}> Anterior</Button>
                                                <Form.Select size="sm" className="activity-select-question float-start m-r-1 mt-4" value={activeKey} onChange={(e) => setActive(e.target.value)}>
                                                    {questions.map((e, i) => {

                                                        var key = i + 1;
                                                        return <option key={e.id} value={i}>{`${key}`}</option>
                                                    })}
                                                </Form.Select>
                                                <Button size="sm" variant="success" className="float-start mt-4" disabled={activeKey == (totalQuestions - 1)} onClick={() => setActive(activeKey + 1)} >Próxima</Button>

                                                <small className="text-muted float-end mt-4 m-r-1">{` ${totalQuestions} questões`}</small>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )
                }
            </>
        );
    };
}

export default AnswersSheetForm;