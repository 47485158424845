import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, ButtonGroup, Container, Row, Col, Breadcrumb, Fade, InputGroup, CardGroup, Card } from "react-bootstrap";
import parse from 'html-react-parser';
import PageAlert from "presentation/components/page-alert";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import { goTo, isNullOrEmpty } from "presentation/utils/functions";

import activityAnswersService from "presentation/services/activity-answers";
import activityQuestionCommentsService from "presentation/services/activity-question-comments";

import { getUser } from 'presentation/utils/authentication';

import InlineRemove from "presentation/pages/layout/elements/inline-remove";

class CorrectionsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            localLoading: false,
            localError: false,
            isCommentsPanelOpen: false,
            message: '',
            activeKey: 0,
            studentActiveKey: 0,
        }

    }

    toggleCommentsPanel = () => {
        const { isCommentsPanelOpen } = this.state;
        this.setState({ isCommentsPanelOpen: !isCommentsPanelOpen });
    }

    setActive = (key) => {
        this.setState({ activeKey: parseInt(key) });
    }

    isActive = (itemKey) => {
        const { activeKey } = this.state;
        let response = itemKey == activeKey;
        return response;
    };

    setActiveStudent = (key) => {
        this.setState({ studentActiveKey: parseInt(key) });
    }

    isStudentActive = (itemKey) => {
        const { studentActiveKey } = this.state;
        let response = itemKey == studentActiveKey;
        return response;
    };

    loadQuestionAnswer = (questionId, studentId) => {
        const { component } = this.props;
        const { answers } = component.state;
        let answerItem = answers.filter(x => x.questionId == questionId && x.studentId == studentId)[0];
        return answerItem ? answerItem : undefined;
    }

    saveMyComment = async (student, question) => {
        const { loadQuestionAnswer } = this;
        const { component, loadQuestionsComments } = this.props;
        const { questionsComments, questions } = component.state;

        var questionId = question.id;
        var studentId = student.studentId;

        let answerItem = loadQuestionAnswer(questionId, studentId);

        //tratar pra evitar duplicatas
        if (answerItem.evaluationComments && !isNullOrEmpty(answerItem.evaluationComments)) {
            var comment = answerItem.evaluationComments;
            var alreadyExists = questionsComments.filter(x => x.questionId == questionId && x.observations == comment);

            var currentUser = getUser();


            if (alreadyExists.length <= 0) {
                component.setState({ loading: true });

                var questionIds = questions.length > 0 ? questions.map((x, i) => { return x.questionId }) : [];

                await activityQuestionCommentsService.add({ questionId: questionId, observations: comment, userId: currentUser.id }).then((result) => {
                    loadQuestionsComments(questionIds);
                }
                );

                component.setState({ loading: false });

            }
        }
    }

    removeMyComment = async (commentId) => {
        const { component, loadQuestionsComments } = this.props;
        const { questionsComments, questions } = component.state;

        component.setState({ loading: true });

        var questionIds = questions.length > 0 ? questions.map((x, i) => { return x.questionId }) : [];

        await activityQuestionCommentsService.remove(commentId).then((result) => {
            loadQuestionsComments(questionIds);
        }
        );
        component.setState({ loading: false });
    }

    setComment = (student, question, rawQuestionOptionId, evaluationComments = "") => {
        const { loadQuestionAnswer } = this;
        const { component } = this.props;
        const { answers } = component.state;
        var questionId = question.id;
        var studentId = student.studentId;

        let answerItem = loadQuestionAnswer(questionId, studentId);
        let questionOptionId = parseInt(rawQuestionOptionId);

        if (answerItem) {

            answerItem.questionOptionId = [questionOptionId];
            answerItem.evaluationComments = evaluationComments;

            let filteredAnswers = answers.filter(x => !(x.questionId == answerItem.questionId && x.studentId == answerItem.studentId));

            filteredAnswers.push(answerItem);

            component.setState({ answers: filteredAnswers });
        }
    }

    setSuccessRate = (student, question, rawQuestionOptionId, successRate = 100) => {
        const { loadQuestionAnswer } = this;
        const { component } = this.props;
        const { answers } = component.state;
        var questionId = question.id;
        var studentId = student.studentId;

        let answerItem = loadQuestionAnswer(questionId, studentId);
        let questionOptionId = parseInt(rawQuestionOptionId);

        if (answerItem) {

            answerItem.questionOptionId = [questionOptionId];
            answerItem.successRate = successRate;

            let filteredAnswers = answers.filter(x => !(x.questionId == answerItem.questionId && x.studentId == answerItem.studentId));

            filteredAnswers.push(answerItem);

            component.setState({ answers: filteredAnswers });
        }
    }

    getAnswer = (questionId, studentId) => {
        const { loadQuestionAnswer } = this;

        let answerItem = loadQuestionAnswer(questionId, studentId);
        return answerItem ? answerItem : undefined;

    };

    onSubmit = async () => {
        const { component, returnTo } = this.props;
        const { answers, rawAnswers, activity } = component.state;

        component.setState({ loading: true });
        var requests = undefined;
        var postData = [];
        if (answers.length > 0) {
            answers.map((e, i) => {
                if (e.questionOptionId) {
                    e.questionOptionId.map((o, j) => {

                        let entity = rawAnswers.filter(x => x.studentId == e.studentId && x.questionId == e.questionId && x.questionOptionId == o)[0] || null;

                        if (entity) {
                            let answer = {
                                questionOptionId: o,
                                questionId: e.questionId,
                                observations: e.observations,
                                classroomId: activity.classroom.id,
                                disciplineId: activity.discipline.id,
                                studentId: e.studentId,
                                activityId: activity.id,
                                evaluationComments: e.evaluationComments,
                                successRate: e.successRate,
                                id: entity.id,
                            };
                            postData.push(answer);
                        }
                    });
                }
            });
        }

        if (postData.length > 0) {
            requests = [];
            postData.map(async (r, i) => {
                requests.push(await activityAnswersService.save(r));
            });
            if (Promise.all(requests)) {
                goTo(returnTo, component);
            } else {
                component.setState({ loading: false, error: true, message: 'Erro ao enviar as respostas... Por favor, tente novamente mais tarde' });
            }

        } else {
            component.setState({ loading: false, error: true, message: 'Erro ao recuperar as respostas... Por favor, tente novamente mais tarde' });
        }

    };

    render() {

        let { component, returnTo } = this.props;

        const { loading, activity, questions: rawQuestions, students: rawStudents, questionsComments } = component.state;

        const questions = rawQuestions.sort((a, b) => a.order - b.order);
        const students = rawStudents.filter(x => x.activityWasAnswered == true).sort((a, b) => a.order - b.order);

        let { localError, localLoading, message, activeKey, studentActiveKey, isCommentsPanelOpen } = this.state;

        const { isActive, setActive, isStudentActive, setActiveStudent, getAnswer, setComment, setSuccessRate, onSubmit, toggleCommentsPanel, saveMyComment } = this;

        const totalQuestions = questions.length;
        const totalStudents = students.length;



        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {(localLoading === false && localLoading === false && questions.length <= 0) && <PageAlert className="m-b-20" message={"Erro"} description={"A atividade selecionada não tem questões para responder..."} type="danger" />}
                {loading === false && localLoading === false && questions.length >= 0 && (
                    <Form>
                        <Container className="pb-5">
                            <Breadcrumb className="site-breadcrumb">
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.classroom.displayName}</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.discipline.displayName}</span>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <span className="mt-1">{activity.title}</span>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <Row className="m-b-4 activity-options-container">
                                {students.map((s, j) => {
                                    var isStudentActiveItem = isStudentActive(j);
                                    return (
                                        <div key={s.studentId}>
                                            <Fade in={isStudentActiveItem}>
                                                <div>
                                                    {isStudentActiveItem == true && (
                                                        <div>
                                                            <p className="text-muted">Aluno: <strong>{s.studentName}</strong></p>
                                                            {questions.map((e, i) => {
                                                                var isActiveItem = isActive(i);
                                                                var options = e.question.questionOptions;
                                                                var firstOption = options[0];
                                                                var questionType = e.question.questionTypeId;
                                                                var allowMultipleAnswers = e.question.allowMultipleOptionsAnswers;
                                                                var questionAnswer = getAnswer(e.question.id, s.studentId);
                                                                var questionComments = questionsComments.filter(x => x.questionId == e.questionId);

                                                                return (
                                                                    <div key={e.id} className="activity-answers-view">
                                                                        <Fade in={isActiveItem}>
                                                                            <div>
                                                                                {
                                                                                    isActiveItem &&
                                                                                    <>
                                                                                        <h6>Questão {i + 1}</h6>
                                                                                        <hr />
                                                                                        <div className="mb-4">
                                                                                            {parse(e.question.title)}
                                                                                        </div>
                                                                                        {questionType == 1 && allowMultipleAnswers == false && options.map((o, j) => {
                                                                                            return (
                                                                                                <div key={`opt-${o.id}`} className={o.isCorrect == true ? "activity-answer-option-correct" : "activity-answer-option"}>
                                                                                                    <Form.Check
                                                                                                        disabled
                                                                                                        type="radio"
                                                                                                        label={parse(o.title)}
                                                                                                        name={`opt-${o.questionId}`}
                                                                                                        checked={questionAnswer ? questionAnswer.questionOptionId.includes(o.id) : false}

                                                                                                    />
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                        {questionType == 1 && allowMultipleAnswers == true && options.map((o, j) => {
                                                                                            return (
                                                                                                <div key={`opt-${o.id}`} className={o.isCorrect == true ? "activity-answer-option-correct" : "activity-answer-option"}>
                                                                                                    <Form.Check
                                                                                                        disabled
                                                                                                        type="checkbox"
                                                                                                        label={parse(o.title)}
                                                                                                        name={`opt-${o.questionId}-item${o.id}`}
                                                                                                        value={o.id}
                                                                                                        checked={questionAnswer ? questionAnswer.questionOptionId.includes(o.id) : false}
                                                                                                    />
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                        {questionType == 2 && (
                                                                                            <div>
                                                                                                <div key={`opt-${firstOption.id}`} className="activity-answer-option-correct">
                                                                                                    <strong>Gabarito:</strong>
                                                                                                    <br />
                                                                                                    {parse(firstOption.title || '- resposta padrão não cadastrada -')}
                                                                                                </div>
                                                                                                <br />
                                                                                                <div className="activity-answer-option-evaluable">
                                                                                                    <strong>Resposta do aluno: </strong>
                                                                                                    <br />
                                                                                                    {parse(questionAnswer ? questionAnswer.observations : " - ")}
                                                                                                </div>
                                                                                                <br />
                                                                                                <strong>Percentual de acerto: </strong>
                                                                                                <InputGroup>
                                                                                                    <Form.Control
                                                                                                        type="number"
                                                                                                        min="0"
                                                                                                        max="100"
                                                                                                        name={`opt-sr-${firstOption.questionId}`}
                                                                                                        value={questionAnswer ? questionAnswer.successRate : undefined}
                                                                                                        onChange={(ev) => setSuccessRate(s, e.question, firstOption.id, ev.target.value)}
                                                                                                    />
                                                                                                    <InputGroup.Text>%</InputGroup.Text>
                                                                                                    <ButtonGroup>
                                                                                                        <Button variant="outline" className="link-without-decoration">Preenchimento rápido:</Button>
                                                                                                        <Button onClick={(ev) => setSuccessRate(s, e.question, firstOption.id, 0)}>0%</Button>
                                                                                                        <Button onClick={(ev) => setSuccessRate(s, e.question, firstOption.id, 25)}>25%</Button>
                                                                                                        <Button onClick={(ev) => setSuccessRate(s, e.question, firstOption.id, 50)}>50%</Button>
                                                                                                        <Button onClick={(ev) => setSuccessRate(s, e.question, firstOption.id, 75)}>75%</Button>
                                                                                                        <Button onClick={(ev) => setSuccessRate(s, e.question, firstOption.id, 100)}>100%</Button>
                                                                                                    </ButtonGroup>
                                                                                                </InputGroup>
                                                                                                <br />
                                                                                                <strong>Comentário: </strong>
                                                                                                <InputGroup>
                                                                                                    <Form.Control
                                                                                                        className="activity-answer-comment"
                                                                                                        as={'textarea'}
                                                                                                        name={`opt-${firstOption.questionId}`}
                                                                                                        value={questionAnswer ? questionAnswer.evaluationComments : undefined}
                                                                                                        onChange={(ev) => setComment(s, e.question, firstOption.id, ev.target.value)}
                                                                                                    />
                                                                                                    <ButtonGroup>
                                                                                                        <Button variant="outline-success" className="m-l-2" onClick={async () => await saveMyComment(s, e.question)}>Guardar esse comentário</Button>
                                                                                                        <Button variant="outline-success" className="m-l-2" onClick={() => toggleCommentsPanel()}>{!isCommentsPanelOpen ? 'Ver' : 'Ocultar'} meus comentários</Button>
                                                                                                    </ButtonGroup>
                                                                                                </InputGroup>
                                                                                                <Fade in={isCommentsPanelOpen}>
                                                                                                    <div>
                                                                                                        {questionComments.length <= 0 && (<p className="m-t-1 text-center">Sua lista de comentários salvos para essa questão está vazia...</p>)}
                                                                                                        {questionComments.length > 0 && (
                                                                                                            <Row xs={1} md={3} className="g-4">
                                                                                                                {questionComments.map((qt, idx) => {
                                                                                                                    return (
                                                                                                                        <Col key={qt.id}>
                                                                                                                            <Card className="m-t-1 p-r-1">
                                                                                                                                <Card.Body>
                                                                                                                                    <Card.Text>
                                                                                                                                        {qt.observations}
                                                                                                                                    </Card.Text>
                                                                                                                                </Card.Body>
                                                                                                                                <Card.Footer>
                                                                                                                                    <Button size="sm" className="float-end" variant="success" onClick={() => setComment(s, e.question, firstOption.id, qt.observations)} >Usar</Button>
                                                                                                                                    <div className="float-start">
                                                                                                                                        <InlineRemove
                                                                                                                                            buttonSize="sm"
                                                                                                                                            component={this}
                                                                                                                                            optionId={qt.id}
                                                                                                                                            loading={loading}
                                                                                                                                            deleteIsDisabled={false}
                                                                                                                                            removeAction={this.removeMyComment} />
                                                                                                                                    </div>
                                                                                                                                </Card.Footer>
                                                                                                                            </Card>
                                                                                                                        </Col>
                                                                                                                    )
                                                                                                                })}
                                                                                                            </Row>
                                                                                                        )
                                                                                                        }
                                                                                                    </div>
                                                                                                </Fade>
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </Fade>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                            </Fade>
                                        </div>
                                    );
                                })}
                            </Row>
                        </Container>
                        <Container fluid className="align-middle activity-navigation">
                            <Row>
                                <Col>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Button size="sm" as={Link} to={returnTo || "/"} variant="secondary" className="float-start m-r-1 mt-4">Sair</Button>
                                                <Button size="sm" variant="success" className="float-start m-r-1 mt-4" disabled={activeKey == 0} onClick={() => setActive(activeKey - 1)}> Anterior</Button>
                                                <Form.Select size="sm" className="activity-select-question float-start m-r-1 mt-4" value={activeKey} onChange={(e) => setActive(e.target.value)}>
                                                    {questions.map((e, i) => {

                                                        var key = i + 1;
                                                        return <option key={e.id} value={i}>{`Questão ${key}`}</option>
                                                    })}
                                                </Form.Select>
                                                <Button size="sm" variant="success" className="float-start mt-4" disabled={activeKey == (totalQuestions - 1)} onClick={() => setActive(activeKey + 1)} >Próxima</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button size="sm" variant="success" className="float-start m-r-1 mt-4" disabled={studentActiveKey == 0} onClick={() => setActiveStudent(studentActiveKey - 1)}> Anterior</Button>
                                                <Form.Select size="sm" className="activity-select-question float-start m-r-1 mt-4" value={studentActiveKey} onChange={(e) => setActiveStudent(e.target.value)}>
                                                    {students.map((s, j) => {
                                                        return <option key={s.studentId} value={j}>{`${s.studentName}`}</option>
                                                    })}
                                                </Form.Select>
                                                <Button size="sm" variant="success" className="float-start mt-4" disabled={studentActiveKey == (totalStudents - 1)} onClick={() => setActiveStudent(studentActiveKey + 1)} >Próximo</Button>
                                                <Button variant="success" size="sm" className="float-end mt-4" onClick={onSubmit}>Finalizar</Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )
                }
            </>
        );
    };
}

export default CorrectionsForm;