import React from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import courseLevelsService from "presentation/services/course-levels";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import { handleChange } from "presentation/utils/functions";

class CourseForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            localLoading: true,
            localError: false,
            message: '',
            courseLevels: [],
            topics: []
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        try {

            await courseLevelsService.findAll().then((result) => {
                if (result.error == true) {
                    this.setState({
                        localLoading: false,
                        localError: true,
                        message: result.message
                    });
                } else {
                    this.setState({ courseLevels: result, localLoading: false, localError: false });
                }
            });
        } catch (err) {
            this.setState({
                localLoading: false,
                localError: true,
                message: err.message
            });
        }
    }

    render() {
        var { localLoading, localError, message, courseLevels } = this.state;
        var { loading, form, handleSubmit, component } = this.props;


        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (

                        <Form>
                            <h4>Informações do curso</h4>
                            <div className="form mt-2">
                                <Form.Label htmlFor="discipline">Nível/segmento do curso</Form.Label>
                                <Form.Select
                                    aria-label="Nível/segmento"
                                    defaultValue={form.courseLevelId}
                                    id="courseLevelId"
                                    name="courseLevelId"
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="courseLevelIdHelpBlock"
                                    required
                                >
                                    <option>Selecione um nível/segmento</option>
                                    {courseLevels.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                </Form.Select>
                                <Form.Text id="courseLevelIdHelpBlock" muted>
                                    Defina a qual nível segmento esse curso pertence
                                </Form.Text>
                            </div>
                            <div className="form">
                                <Form.Label htmlFor="name">Curso</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="name"
                                    name="name"
                                    defaultValue={form.name}
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="nameHelpBlock"
                                />
                                <Form.Text id="nameHelpBlock" muted>
                                    Informe nome do curso
                                </Form.Text>
                            </div>
                            <div className="form mt-2">
                                <Form.Label htmlFor="displayName">Nome de exibição do curso</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="displayName"
                                    name="displayName"
                                    defaultValue={form.displayName}
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="displayNameHelpBlock"
                                />
                                <Form.Text id="displayNameHelpBlock" muted>
                                    Escolha um nome de exibição
                                </Form.Text>
                            </div>
                            <div className="form mt-2">
                                <Form.Label htmlFor="displayName">Código do curso</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="code"
                                    name="code"
                                    defaultValue={form.code}
                                    onChange={(e) => handleChange(e, component)}
                                    aria-describedby="codeHelpBlock"
                                />
                                <Form.Text id="codeHelpBlock" muted>
                                    Escolha um código
                                </Form.Text>
                            </div>
                            <div className="mt-3">
                                <Button as={Link} to={'/cursos'} variant="secondary">Mudei de ideia</Button>
                                <Button disabled={loading || localError} variant="success" className="float-end" onClick={() => handleSubmit()}>Salvar</Button>
                            </div>
                        </Form>
                    )
                }
            </>
        );
    };
}

export default CourseForm;