import React from "react";
import { Container, Row, Form, Button } from "react-bootstrap";

import employeesService from "presentation/services/employees";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import EmployeeForm from 'presentation/pages/employees/elements/form';

import { handleChange, getGender, getDocumentFieldType, dateIsInvalid } from "presentation/utils/functions";

import moment from "moment";

class EmployeeAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      form: {},
      actionLoading: false,
      error: false,
      message: '',
      data: [],
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    const { location } = this.props;

    const { newRegistration, personName, personId, personEntity } = location.state || {};

    var form = {};

    if (personId && personEntity) {

      var genderInfo = personEntity.gender ? getGender(personEntity.gender.toString()) : undefined;
      var genderId = typeof (genderInfo) === 'undefined' ? -1 : genderInfo.id;


      var documentFieldTypeInfo = personEntity.documentFieldType ? getDocumentFieldType(personEntity.documentFieldType.toString().toLowerCase()) : undefined;
      var documentFieldTypeId = typeof (documentFieldTypeInfo) === 'undefined' ? -1 : documentFieldTypeInfo.id;

      if (!dateIsInvalid(personEntity.birthdate)) {
        form.birthdate = moment(personEntity.birthdate, 'YYYY-MM-DD').format("YYYY-MM-DD");
      }


      form.personId = personId;
      form.userId = personEntity.userId;
      form.name = personEntity.name;
      form.displayName = personEntity.displayName;
      form.genderId = genderId;
      form.documentField = personEntity.documentField;
      form.documentFieldType = documentFieldTypeId;


    } else {
      if (newRegistration && personName) {
        form.name = personName;
        form.displayName = personName;
      }
    }


    await employeesService.getNextRegistration().then((result) => {
      form.registration = result;
      this.setState({ form: form, loading: false });
    });
  }

  goTo = (destination) => {
    const { history } = this.props;

    history.push(destination);
  };

  handleSubmit = async () => {

    this.setState({ loading: true, actionLoading: true, error: '' });

    const {
      name,
    } = this.state.form;

    if (!name) {
      this.setState({ loading: false, error: true, message: "Por favor, preencha um nome..." });
    } else {
      let postData = this.state.form;

      if (dateIsInvalid(postData.birthdate)) {
        postData.birthdate = null;
      }

      if (dateIsInvalid(postData.registrationDate)) {
        postData.registrationDate = null;
      }

      postData.registration = "" + postData.registration;

      try {
        const response = await employeesService.add(postData);
        if (response.error == true) {
          this.setState({
            loading: false,
            actionLoading: false,
            error: true,
            message: response.message
          });
        } else {

          this.goTo("/colaboradores");
        }
      } catch (err) {
        this.setState({
          loading: false,
          actionLoading: false,
          error: true,
          message: err.message
        });
      }
    }
  };

  render() {
    let { location } = this.props;
    let { loading, error, message, actionLoading, form } = this.state;
    let { handleSubmit } = this;


    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <Container>
            <Row>
              <EmployeeForm
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                loading={loading}
                form={form}
                component={this}
              />
            </Row>
          </Container>
        )
        }
      </div>
    );
  };
}

export default EmployeeAdd;
