import React, { Component } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

import activitiesService from "presentation/services/activities";
import { handleResultData } from "presentation/utils/functions";

// Create styles
const styles = StyleSheet.create({
  page: {
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});

// Create Document Component
class ActivityResutsReportPdf extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      message: '',
      activities: [],
      data: [],
      resultsData: [],
      activityData: []
    };
  }
  componentDidMount() {
    this.loadActivity();
  }

  loadActivity = async () => {
    var errorMessages = [];
    var errorMessage = '';
    var hasError = false;

    let { activityId } = this.props.match.params;

    if (activityId != null && activityId > 0) {
      this.setState({ loading: true });
      let id = activityId;

      var baseData = await activitiesService.activityStudents(id).then((result) => {
        let response = handleResultData(result);

        let activityDates = [];

        if (response.length > 0) {
          response.map((e, i) => {
            let dateOverrideId = e.activityDateOverrideId || undefined;
            let item = {
              isRemove: false,
              id: dateOverrideId,
              key: e.studentId,
              studentId: e.studentId,
              activityId: e.activityId,
              classroomId: e.classroomId,
              disciplineId: e.disciplineId,
              startDate: dateOverrideId ? e.activityOverrideStartDate : undefined,
              endDate: dateOverrideId ? e.activityOverrideEndDate : undefined
            }
            activityDates.push(item);
          });
        }


        const activityData = response.length > 0 ? response[0] : {
          classroomName: null,
          disciplineName: null,
          classroomId: null,
          disciplineId: null,
          academicPeriodId: null,
          activityId: null,
          activityTitle: null,
          activityStartDate: null,
          activityEndDate: null,
        };

        const activityDateForm = {
          id: activityData.activityId,
          startDate: activityData.activityStartDate,
          endDate: activityData.activityEndDate,
          title: activityData.activityTitle,
          academicPeriodId: activityData.academicPeriodId,
          classroomId: activityData.classroomId,
          disciplineId: activityData.disciplineId,
        }

        if (response.error == true) {
          errorMessages.push(response.message);
        } else {
          this.setState({ data: response, activityDates: activityDates, activityData: activityData, activityDateForm: activityDateForm });
        }
      });

      var results = await activitiesService.activityResults({ activityId: [parseInt(id)] }).then((re) => {
        let handled = handleResultData(re);

        if (handled.error == true) {
          errorMessages.push(handled.message);
        } else {
          this.setState({ resultsData: handled });
        }

      });

      await Promise.all([results, baseData]);

      if (errorMessages.length > 0) {
        hasError = true;
        errorMessage = errorMessages.join(" / ");
      }

      this.setState({ loading: false, error: hasError, message: errorMessage });
    } else {
      this.setState({ activityData: [] });
    }

  }

  formatToExport = (data, handledResultData) => {
    let output = [];

    data.map((element, index) => {
      var hasAnswer = element.activityWasAnswered == true;
      var studentResults = handledResultData.filter(x => x.studentId == element.studentId)[0] || {};
      output.push({
        "Matrícula": element.studentRegistration,
        "Nome": element.studentName,
        "Realizada?": hasAnswer == true ? ' sim ' : 'não',
        "Nota": studentResults.evaluatedScore || ' - ',
        "Total acertos": studentResults.totalCorrectAnswers || ' - ',
        "% Aproveitamento": studentResults.evaluatedSuccessRate || ' - ',
      }
      );
    })
    return output;
  };

  render() {
    const { data, resultsData, activityData, loading } = this.state;

    const handledResultData = resultsData.length > 0 ? resultsData : [];
    return (
      <>
        {loading == true && (<center><p>Carregando...</p></center>)}
        {loading == false && typeof (activityData) !== 'undefined' && typeof (data) !== 'undefined' && typeof (resultsData) !== 'undefined' && (
          < PDFViewer style={styles.viewer} >
            {/* Start of the document*/}
            < Document >
              {/*render a single page*/}
              < Page size="A4" style={styles.page} >
                <View style={styles.section}>
                  <Text>Relação de atividades x Aproveitamento</Text>
                </View>
                <View style={styles.section}>
                  <Text>
                    <table>
                      <tr><td>Teste</td></tr>
                    </table>
                  </Text>
                </View>
              </Page >
            </Document >
          </PDFViewer >
        )}
      </>
    )
  };
}
export default ActivityResutsReportPdf;