import React from "react";
import { Container, Row, Form, Button } from "react-bootstrap";

import mediaFilesService from "presentation/services/media-files";
import mediaDirectoriesService from "presentation/services/media-directories";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import MediaFileForm from 'presentation/pages/media-files/elements/form';

import { getUser } from 'presentation/utils/authentication';

class MediaFileAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form: {
        mediaType: 1
      },
      actionLoading: false,
      error: false,
      message: '',
      selectedDirectories: [],
      directoriesPermissions: []
    };
  }



  goTo = (destination) => {
    const { history } = this.props;

    history.push(destination);
  };

  handleSubmit = async () => {

    this.setState({ loading: true, actionLoading: true, error: '' });

    const {
      title,
      uploadMediaFile,
      mediaType
    } = this.state.form;

    const { selectedDirectories, directoriesPermissions } = this.state;


    var validationError = false;
    var validationErrorMessages = [];

    if (!title) {
      validationError = true;
      validationErrorMessages.push("Por favor, preencha um título...");
    }

    if (mediaType == 1 && (!uploadMediaFile || !uploadMediaFile.name)) {
      validationError = true;
      validationErrorMessages.push("O campo de arquivo é obrigatório");
    }

    if (!(selectedDirectories.length > 0)) {
      validationError = true;
      validationErrorMessages.push("Por favor, selecione ao menos um diretório");
    }


    if (validationError) {
      this.setState({ loading: false, error: true, message: validationErrorMessages.join("<br />") });
    } else {
      try {

        const form = this.state.form;
        let hasError = false;

        const user = getUser();

        let postData = {
          mediaFile: {
            userId: user.id,
            title: form.title,
            description: form.description,
            mediaType: form.mediaType,
            storageUri: form.storageUri,
            storageIdentification: form.storageIdentification
          },
          selectedDirectories: selectedDirectories,
          directoriesPermissions: directoriesPermissions
        };

        if (mediaType == 1) {
          var uploadData = new FormData();
          uploadData.append("file", form.uploadMediaFile);

          const uploadResponse = await mediaFilesService.upload(uploadData);

          if (uploadResponse.error == true) {
            hasError = true;

            this.setState({
              loading: false,
              actionLoading: false,
              error: true,
              message: uploadResponse.message
            });
          } else {
            postData.mediaFile.storageUri = uploadResponse.storageUri;
            postData.mediaFile.storageIdentification = uploadResponse.storageIdentification;
          }
        }

        if (hasError == false) {
          const response = await mediaFilesService.save(postData);
          if (response.error == true) {
            this.setState({
              loading: false,
              actionLoading: false,
              error: true,
              message: response.message
            });
          } else {

            this.goTo("/materiais");
          }
        }
      } catch (err) {
        this.setState({
          loading: false,
          actionLoading: false,
          error: true,
          message: err.message
        });
      }
    }
  };

  render() {
    let { location } = this.props;
    let { loading, error, message, actionLoading, form, selectedDirectories } = this.state;
    let { handleSubmit } = this;

    return (
      <div>
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {loading === false && (
          <Container>
            <Row>
              <MediaFileForm
                handleSubmit={handleSubmit}
                loading={loading}
                form={form}
                selectedDirectories={selectedDirectories}
                component={this}
              />
            </Row>
          </Container>
        )
        }
      </div>
    );
  };
}

export default MediaFileAdd;
