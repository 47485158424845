import React from "react";
import { Toast } from "react-bootstrap";
import { setComponentFlag, componentFlagValue } from "presentation/utils/functions";

class QuestionTips extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        const { component, form } = this.props;

        const { isDiscursive } = form;
        let questionIsDiscursive = isDiscursive || false;


        return (
            <div className="tips-container">
                <Toast
                    onClose={() => setComponentFlag(component, 'deleteTip', false)}
                    show={componentFlagValue(component, 'deleteTip')}
                    delay={6000}
                    autohide
                    position={"top-end"}
                >
                    <Toast.Header className="toast-header-red">
                        <strong className="me-auto">BasicoApp</strong>
                    </Toast.Header>
                    <Toast.Body>{questionIsDiscursive == true ? 'Opa! Questões dissertativas precisam ter uma reposta padrão' : 'Opa! Questões precisam ter ao menos duas alternativas'}</Toast.Body>
                </Toast>
            </div>
        );
    };
}

export default QuestionTips;


