import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';

import paths from 'presentation/config/paths';

class SiteBreadcrumb extends React.Component {

    removePlaceHolders = (path) => {
        var output = path, placeHolders = path.match(/\/:[a-zA-Z0-9]*/g);
        if (placeHolders && placeHolders.length > 0) {
            for (var pidx = 0; pidx < placeHolders.length; pidx++) {
                output = output.replace(placeHolders[pidx], "");
            }
        }
        return output;
    }

    render() {

        const { location } = this.props;
        //Traz o endereço separado por "/""
        const currentPath = location.pathname.split('/');
        let links = currentPath.map((pathItem, pathItemIndex) => {
            var url = [];
            for (let i = 0; i <= pathItemIndex; i++) {
                url.push(currentPath[i]);
            }
            return url.join("/");
        });

        let breadcrumbLinks = [];
        //Verificando se caminhos exisem nas rotas válidas
        for (var idx = 0; idx < links.length; idx++) {
            var filteredRoute = paths.filter((i) => {
                let clearedPath = this.removePlaceHolders(i.path);
                if (clearedPath === links[idx]) {
                    return i;
                }
            });

            if (filteredRoute[0]) {
                breadcrumbLinks.push(filteredRoute[0]);
            }
        }
        return (
            <Breadcrumb className="site-breadcrumb">
                {breadcrumbLinks.map((breadcrumbItem, i, arr) => {
                    return (arr.length - 1 === i) ?
                        <Breadcrumb.Item key={breadcrumbItem.path} active>
                            <span className="me-1">{breadcrumbItem.icon}</span>
                            <span className="mt-1">{breadcrumbItem.title}</span>
                        </Breadcrumb.Item>
                        :
                        <Breadcrumb.Item linkAs={Link} linkProps={{ className: "link-primary", to: breadcrumbItem.path }} key={breadcrumbItem.path}>
                            <span className="me-1">{breadcrumbItem.icon}</span>
                            <span className="mt-1">{breadcrumbItem.title}</span>
                        </Breadcrumb.Item>
                        ;
                })}
            </Breadcrumb>
        );
    }
}

export default SiteBreadcrumb;
