// activity dates methods

// Carrega uma alternativa no state
export const loadActivityDate = (key, component) => {
    let { activityDates } = component.state;
    let output = undefined;
    if (activityDates.length > 0) {
        var activityDate = activityDates.find(x => { return x.key == key });
        output = activityDate !== undefined ? activityDate : undefined;
    }

    return output;
}


//Carrega as informações de um campo específico da alternativa no state
export const activityDatesFormValue = (key, formField, component) => {

    let output = undefined;

    var activityDate = loadActivityDate(key, component);

    output = activityDate !== undefined ? activityDate[formField] : undefined;

    return output;
}

//Tratativas para quando um campo muda de valor (geral)
export const handleChangeActivityDates = (key, event, component, extraInfoOnCreate = {}) => {
    const { value, checked, type } = event.target;

    const name = event.target.getAttribute('data-field-name');

    const changeValue = type == "checkbox" ? checked : value;

    handleFieldChange(key, name, changeValue, component, extraInfoOnCreate);
}


//Método genérico para acompanhar tratativas de mudança de valor dos campos
export const handleFieldChange = (key, field, value, component, extraInfoOnCreate = {}) => {
    var activityDate = loadActivityDate(key, component);

    if (typeof (activityDate) === 'undefined') {
        activityDate = { key: key, ...extraInfoOnCreate };
    }

    activityDate[field] = value;

    const newActivityDates = changeActivityDatesList(component, activityDate);

    component.setState({
        activityDates: newActivityDates
    });
}


//Prapara o objeto do state para a atualização com as informações das alternativas da questão
export const changeActivityDatesList = (component, targetInformation, remove = false) => {
    let { activityDates } = component.state;

    let optionIndex = activityDates.findIndex(option => option.key == targetInformation.key);

    if (optionIndex < 0) {
        optionIndex = activityDates.length;
    }

    if (remove === true) {
        let elementId = activityDates[optionIndex]['id'] || undefined;

        if (elementId) {
            activityDates[optionIndex]['isDeleteRequested'] = true;
            activityDates[optionIndex]['key'] = 'delete-requested-' + new Date().getTime();
        } else {
            activityDates.splice(optionIndex, 1);
        }
    } else {
        activityDates[optionIndex] = targetInformation;
    }
    return activityDates;
}



const activityQuestionFunctions = {
    loadActivityDate,
    activityDatesFormValue,
    handleChangeActivityDates,
    changeActivityDatesList
}

export default activityQuestionFunctions;