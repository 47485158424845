import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Nav, Col, Tab, Table, ButtonGroup, InputGroup } from "react-bootstrap";
import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";

import { getUser } from 'presentation/utils/authentication';

import { handleChange, handleChangeFile, handleRadioTableItems } from "presentation/utils/functions";

import ClassroomStudentModal from "presentation/pages/media-files/elements/classroom-student-modal";

import classroomsService from "presentation/services/classrooms";

import { mediaTypes } from 'presentation/model/constants';

import {MEDIA_DIRECTORY_MACRO_ALLOW , MEDIA_DIRECTORY_MACRO_DENY, MEDIA_DIRECTORY_MACRO_CUSTOM} from "presentation/model/constants";

import { connect } from "react-redux";

class MediaFileForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            localLoading: true,
            localError: false,
            message: '',
            groups: [],
            folders: [],
            showModal: false,
            classroomId: null,
            disciplineId: null,
            classroomName: '',
            disciplineName: ''
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        try {
            var directories = await this.loadDirectoriesData();

            this.setState({ localLoading: false, localError: false, folders: directories.directories, groups: directories.groups });
        } catch (err) {
            this.setState({
                localLoading: false,
                localError: true,
                message: err.message
            });
        }
    }

    openModal = (classroomId, disciplineId, classroomName, disciplineName) => {
        this.setState({ classroomId: classroomId, disciplineId: disciplineId, classroomName: classroomName, disciplineName: disciplineName, showModal: true });
    }

    closeModal = () => {
        this.setState({ showModal: false, classroomId: null, disciplineId: null, classroomName: '', disciplineName: '' });
    }


    loadDirectoriesData = async () => {
        const user = getUser();
        const { sessionParams } = this.props;
        const { currentAcademicPeriod: academicPeriodId } = sessionParams;

        let output = {
            groups: [],
            directories: [],
        };

        if (sessionParams) {
            if (user.isMaster) {
                await classroomsService.classroomDisciplines({ academicPeriodId: [academicPeriodId] }).then((result) => {
                    if (result.error == true) {
                        this.setState({
                            localLoading: false,
                            localError: true,
                            message: result.message
                        });
                    } else {

                        result.map((x, i) => {
                            var groupName = x.classroomName;
                            if (!output.groups.includes(groupName)) {
                                output.groups.push(groupName);
                            }
                            var directory = { disciplineId: x.disciplineId, classroomId: x.classroomId, directoryName: x.disciplineName, groupName: groupName };
                            output.directories.push(directory);
                        });


                    }
                });
            } else {
                if (user.isTeacher) {
                    var classrooms = user.teacherInfo?.filter(x => x != null && x.academicPeriodId == academicPeriodId);

                    classrooms.map((x, i) => {
                        if (x != null) {
                            var groupName = x.name;
                            if (!output.groups.includes(groupName)) {
                                output.groups.push(groupName);
                            }

                            var disciplines = x.disciplines;

                            disciplines.map((y, j) => {
                                if (y != null) {
                                    var directory = { disciplineId: y.id, classroomId: x.id, directoryName: y.name, groupName: groupName };
                                    output.directories.push(directory);
                                }
                            });
                        }
                    });
                }
            }
        }

        return output;
    }


    loadSelectedOption = (key, searchField, defaultValue) => {
        const { component } = this.props;
        const { selectedDirectories } = component.state;
        let output = defaultValue;

        if (selectedDirectories != null && selectedDirectories.length > 0) {
            let element = selectedDirectories.filter(x => x.key == key)[0];

            if (element != null && (typeof (element[searchField]) !== 'undefined')) {
                output = element[searchField];
            }
        }

        return output;
    }

    render() {
        var {
            localLoading,
            localError,
            message,
            folders,
            groups,
            classroomId,
            disciplineId,
            classroomName,
            disciplineName,
            showModal
        } = this.state;

        var { loading, form, handleSubmit, component, selectedDirectories } = this.props;
        var { loadSelectedOption, openModal, closeModal } = this;

        var hasAvailableDirectories = groups != null && groups.length > 0 && folders != null && folders.length > 0;
        var hasSelectedDirectories = selectedDirectories.length > 0;

        return (
            <>
                {loading === true || localLoading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                {(localLoading === false && localError === true) && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {
                    loading === false && localLoading === false && (
                        <Container>
                            <Row>
                                <Col>
                                    <Form>
                                        <Tab.Container id="left-tabs" defaultActiveKey="first">
                                            <Row>
                                                <Col sm={2}>
                                                    <Nav variant="pills" className="flex-column">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="first">Questão</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="second">Diretórios</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </Col>
                                                <Col sm={10}>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="first">
                                                            <h4>Informações do material</h4>
                                                            <div className="form">
                                                                <Form.Label htmlFor="name">Título</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    id="title"
                                                                    name="title"
                                                                    defaultValue={form.title}
                                                                    onChange={(e) => handleChange(e, component)}
                                                                    aria-describedby="titleHelpBlock"
                                                                />
                                                                <Form.Text id="nameHelpBlock" muted>
                                                                    Informe o título do material
                                                                </Form.Text>
                                                            </div>
                                                            <div className="form mt-2">
                                                                <Form.Label htmlFor="description">Descrição</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    id="description"
                                                                    name="description"
                                                                    as="textarea"
                                                                    rows={3}
                                                                    defaultValue={form.description}
                                                                    onChange={(e) => handleChange(e, component)}
                                                                    aria-describedby="descriptionHelpBlock"
                                                                />
                                                                <Form.Text id="descriptionHelpBlock" muted>
                                                                    Informe uma descrição
                                                                </Form.Text>
                                                            </div>
                                                            <div className="form mt-2">
                                                                <Form.Label htmlFor="mediaType">Tipo de material</Form.Label>
                                                                <Form.Select
                                                                    aria-label="Tipo"
                                                                    defaultValue={form.mediaType}
                                                                    id="mediaType"
                                                                    name="mediaType"
                                                                    onChange={(e) => handleChange(e, component)}
                                                                    aria-describedby="mediaTypeHelpBlock"
                                                                    required
                                                                >
                                                                    {mediaTypes.map((e, i) => { return <option key={e.id} value={e.id}>{e.name}</option> })}
                                                                </Form.Select>
                                                                <Form.Text id="genderHelpBlock" muted>
                                                                    Defina a qual é o tipo do material
                                                                </Form.Text>
                                                            </div>
                                                            {form.mediaType == 1 && (
                                                                <>
                                                                    {form.id == null &&
                                                                        (
                                                                            <div className="form mt-2">
                                                                                <Form.Group className="mb-3">
                                                                                    <Form.Label htmlFor="uploadMediaFile">Arquivo</Form.Label>
                                                                                    <Form.Control
                                                                                        required
                                                                                        type="file"
                                                                                        id="uploadMediaFile"
                                                                                        name="uploadMediaFile"
                                                                                        onChange={(e) => handleChangeFile(e, component)}
                                                                                        aria-describedby="uploadMediaFileHelpBlock"
                                                                                    />
                                                                                </Form.Group>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {form.id != null && (

                                                                        <div className="form mt-2">
                                                                            <Form.Label htmlFor="displayName">Arquivo</Form.Label>
                                                                            <br />
                                                                            <Button className="success" href={form.storageUri} target="_blank">Download</Button>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                            {form.mediaType != 1 && (
                                                                <div className="form mt-2">
                                                                    <Form.Label htmlFor="description">{form.mediaType == 2 ? 'Link' : 'Youtube'}</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        id="storageUri"
                                                                        name="storageUri"
                                                                        defaultValue={form.storageUri}
                                                                        onChange={(e) => handleChange(e, component)}
                                                                        aria-describedby="descriptionHelpBlock"
                                                                    />
                                                                    <Form.Text id="storageUriHelpBlock" muted>
                                                                        Informe um endereço válido
                                                                    </Form.Text>
                                                                </div>
                                                            )}
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="second">
                                                            <h4>Diretórios</h4>
                                                            {!hasAvailableDirectories && (<p className="text-danger">Não há diretórios disponíveis... Por favor, verifique o seu cadastro...</p>)}
                                                            {hasAvailableDirectories && (
                                                                <>
                                                                    {groups.map((d, i) => {
                                                                        var groupDirectories = folders.filter(x => x.groupName == d);
                                                                        return (
                                                                            <div key={i}>
                                                                                <h5>{d}</h5>
                                                                                <Table bordered borderless className="mt-4">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th rowSpan={2} width="40%">Disciplina</th>
                                                                                            <th colSpan={4} width="60%" className="text-center">Incluir nesse diretório?</th>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th className="text-center" width="20%">Sim</th>
                                                                                            <th className="text-center" width="20%">Não</th>
                                                                                            <th className="text-center" width="20%" colSpan={2}>Configurar por aluno</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {groupDirectories.map((f, j) => {
                                                                                            let extraFields = {
                                                                                                classroomId: f.classroomId,
                                                                                                disciplineId: f.disciplineId
                                                                                            };

                                                                                            let radioKey = `${f.classroomId}-${f.disciplineId}`;

                                                                                            let selectedOption = loadSelectedOption(radioKey, 'permissionType', MEDIA_DIRECTORY_MACRO_DENY);
                                                                                            
                                                                                            return (
                                                                                                <tr key={j}>
                                                                                                    <td>{f.directoryName}</td>
                                                                                                    <td className="text-center"><Form.Check className="row-checkbox" type="radio" checked={selectedOption == MEDIA_DIRECTORY_MACRO_ALLOW} name={`access-option-${f.classroomId}-${f.disciplineId}`} onChange={(e) => handleRadioTableItems( radioKey, 'permissionType', MEDIA_DIRECTORY_MACRO_ALLOW, extraFields, component, 'selectedDirectories')} /></td>
                                                                                                    <td className="text-center"><Form.Check className="row-checkbox" type="radio" checked={selectedOption == MEDIA_DIRECTORY_MACRO_DENY} name={`access-option-${f.classroomId}-${f.disciplineId}`} onChange={(e) => handleRadioTableItems( radioKey, 'permissionType', MEDIA_DIRECTORY_MACRO_DENY, extraFields, component, 'selectedDirectories')} /></td>
                                                                                                    <td className="text-center">
                                                                                                        <Form.Check className="row-checkbox" type="radio" checked={selectedOption == MEDIA_DIRECTORY_MACRO_CUSTOM} name={`access-option-${f.classroomId}-${f.disciplineId}`} onChange={(e) => handleRadioTableItems(radioKey, 'permissionType', MEDIA_DIRECTORY_MACRO_CUSTOM, extraFields, component, 'selectedDirectories')} />
                                                                                                    </td>
                                                                                                    <td className="text-center"><Button size="sm" onClick={() => {handleRadioTableItems( radioKey, 'permissionType', MEDIA_DIRECTORY_MACRO_CUSTOM, extraFields, component, 'selectedDirectories'); openModal(f.classroomId, f.disciplineId, d, f.directoryName);} }>Gerenciar</Button></td>
                                                                                                </tr>
                                                                                            );
                                                                                        })}
                                                                                    </tbody>
                                                                                </Table>
                                                                            </div>

                                                                        )
                                                                    })}
                                                                </>
                                                            )}
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                    <div className="mt-3">
                                                        <Button as={Link} to={'/materiais'} variant="secondary">Mudei de ideia</Button>
                                                        <Button disabled={loading || localError || !hasSelectedDirectories} variant="success" className="float-end" onClick={() => handleSubmit()}>Salvar</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                        {showModal == true && (
                                            <ClassroomStudentModal
                                                show={showModal}
                                                onClose={closeModal}
                                                classroomId={classroomId}
                                                disciplineId={disciplineId}
                                                classroomName={classroomName}
                                                disciplineName={disciplineName}
                                                component={component}
                                            />
                                        )}
                                    </Form>
                                </Col>
                            </Row>
                        </Container >
                    )
                }
            </>
        );
    };
}
//Configs Redux para este component
//
//Define quais atributos vou pegar do
//state do Redux
const mapStateToProps = (state) => {
    const { data } = state.sessionParams;
    return { sessionParams: data };
};

//Define quais ações esse component
//vai usar para interagir com o Redux
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(MediaFileForm);