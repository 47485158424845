import React from "react";
import { Container, Row, Col, Table, Button, Card, CardGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import PageModal from "presentation/components/page-modal";
import { handleResultData } from "presentation/utils/functions";

import activitiesService from "presentation/services/activities";

import faker from 'faker';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

class DefaultDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            actionLoading: false,
            error: false,
            message: '',
            data: [],
            showConfirmModal: false,
            targetDeleteId: null,
        };
        this.loadData = this.loadData.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        await activitiesService.myActivities().then((result) => {
            let handledResult = handleResultData(result);
            this.setState({ data: handledResult, loading: false });
        });
    }

    openModal = (id) => {
        this.setState({ targetDeleteId: id, showConfirmModal: true });
    }

    closeModal = () => {
        this.setState({ showConfirmModal: false, targetDeleteId: null });
    }



    render() {
        let { location } = this.props;

        const { loading, actionLoading, error, message, showConfirmModal } = this.state;

        const { deleteItem, closeModal, openModal } = this;

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
            
            },
        };

        const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

        const data = {
            labels,
            datasets: [
                {
                    label: 'Dataset 1',
                    data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: 'Dataset 2',
                    data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
            ],
        };

        return (
            <div>
                {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
                {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
                {/* {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />} */}
                {loading === false && (
                    <Container>
                        <Row>
                            <Col xs={12} md={4}>
                                <Card className="mt-2 home-first-indicator" bg="secondary" text="light">
                                    <Card.Body>
                                        <div className="leading display-3 home-card-indicator">
                                            999
                                        </div>
                                        <br />
                                        <small className="text-light">Alunos cadastrados</small>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card className="mt-2 home-second-indicator" bg="secondary" text="light">
                                    <Card.Body>
                                        <div className="leading display-3 home-card-indicator">
                                            999
                                        </div>
                                        <br />
                                        <small className="text-light">Atividades cadastradas</small>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card className="mt-2 home-third-indicator" bg="secondary" text="light">
                                    <Card.Body>
                                        <div className="leading display-3 home-card-indicator">
                                            999
                                        </div>
                                        <br />
                                        <small className="text-light">Questões cadastradas</small>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="mt-4 mb-4">
                                    <Card.Body>
                                        <Line options={options} data={data} redraw={true} style={{maxHeight: 200}}/>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        );
    };
}

export default DefaultDashboard;