import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";

class StudentUserForm extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        let { loading, form, handleChange, handleSubmit, component } = this.props;
        return (
            <Form className="mb-5">
                <h4>Informações do aluno</h4>
                <Row>
                    <Col md={6}>
                        <div className="form mt-2">
                            <Form.Label htmlFor="registration">Matrícula</Form.Label>
                            <Form.Control
                                disabled
                                defaultValue={form.registration}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form mt-2">
                            <Form.Label htmlFor="name">Nome</Form.Label>
                            <Form.Control
                                type="text"
                                disabled
                                defaultValue={form.name}
                                aria-describedby="nameHelpBlock"
                            />
                        </div>
                    </Col>
                </Row>
                <hr />
                <h4>Dados de acesso</h4>
                <Row>
                    <Col md={6}>
                        <div className="form mt-2">
                            <Form.Label htmlFor="username">Nome de usuário</Form.Label>
                            <Form.Control
                                type="text"
                                id="username"
                                name="username"
                                defaultValue={form.username}
                                onChange={(e) => handleChange(e, component)}
                                aria-describedby="usernameHelpBlock"
                            />
                            <Form.Text id="usernameHelpBlock" muted>
                                Informe um nome de usuário
                            </Form.Text>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form mt-2">
                            <Form.Label htmlFor="email">E-mail</Form.Label>
                            <Form.Control
                                type="text"
                                id="email"
                                name="email"
                                defaultValue={form.email}
                                onChange={(e) => handleChange(e, component)}
                                aria-describedby="emailHelpBlock"
                            />
                            <Form.Text id="emailHelpBlock" muted>
                                Informe um e-mail válido
                            </Form.Text>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="form mt-2">
                            <Form.Label htmlFor="displayName">Senha</Form.Label>
                            <Form.Control
                                type="password"
                                id="newPassword"
                                name="newPassword"
                                onChange={(e) => handleChange(e, component)}
                                aria-describedby="passwordHelpBlock"
                            />
                            <Form.Text id="emailHelpBlock" muted>
                                Informe uma senha. {`${form.userId ? 'Caso queira manter a senha atual, deixe o campo vazio.' : ''}`}
                            </Form.Text>
                        </div>
                    </Col>
                </Row>





                <div className="mt-3">
                    <Button as={Link} to={'/alunos'} variant="secondary">Mudei de ideia</Button>
                    <Button disabled={loading} variant="success" className="float-end" onClick={() => handleSubmit()}>Salvar</Button>
                </div>
            </Form>
        );
    };
}

export default StudentUserForm;