import React from "react";
import { Container, Row, Col, Button, Card, Form, } from "react-bootstrap";
import { Link } from "react-router-dom";
import loginLogo from 'presentation/assets/img/login-logo.png';

import PageLoadingIndicator from "presentation/components/page-loading-indicator";
import PageAlert from "presentation/components/page-alert";
import { handleChange, handleResultData } from "presentation/utils/functions";
import { getUser, login, identifyRoles, mergeWithSession } from "presentation/utils/authentication";

import usersService from "presentation/services/users";

import disciplinesEmployeesService from "presentation/services/disciplines-employees";
import responsiblesService from "presentation/services/responsibles";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      actionLoading: false,
      error: false,
      validated: false,
      message: '',
      form: {}
    };
  }
  componentDidMount() {
    var currentUser = getUser();
    if (currentUser !== null) {
      this.goTo('/');
    }
  }
  goTo = (destination) => {
    const { history } = this.props;

    history.push(destination);
  };

  formatTeacherData = (rawData) => {
    let data = [];
    rawData.map((e, i) => {
      if (!data[e.classroomId]) {
        data[e.classroomId] = {
          id: e.classroomId,
          academicPeriodId: e.academicPeriodId,
          name: e.classroomName,
          disciplines: []
        };
      }

      if (!data[e.classroomId].disciplines[e.disciplineId]) {
        data[e.classroomId].disciplines[e.disciplineId] = {
          id: e.disciplineId,
          name: e.disciplineName,
        };
      }
    });

    return data;
  }

  handleSubmit = async (event) => {
    let { form } = this.state;
    let { formatTeacherData } = this;
    const formElement = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (formElement.checkValidity() === false) {
      this.setState({ validated: true });
      return;
    }

    this.setState({ loading: true, actionLoading: true, error: '' });

    let postData = form;
    try {
      const response = await usersService.authenticate(postData);

      if (response.error == true) {
        this.setState({
          loading: false,
          actionLoading: false,
          error: true,
          message: response.message
        });
      } else {
        login(response);

        var userInfo = identifyRoles(response);

        if (userInfo.isTeacher) {
          await disciplinesEmployeesService.employeeDisciplines().then((result) => {
            let handledResult = handleResultData(result);
            let data = formatTeacherData(handledResult);
            userInfo.teacherInfo = data
          });
        }

        if (userInfo.isResponsible) {
          await responsiblesService.myDependents().then((result) => {
            let responsiblesResult = handleResultData(result);

            userInfo.responsibleInfo = {
              dependents: responsiblesResult
            }
          });
        }
        mergeWithSession(userInfo);

        this.goTo("/");
      }
    } catch (err) {
      this.setState({
        loading: false,
        actionLoading: false,
        error: true,
        message: err.message
      });
    }
  }

  render() {
    const { loading, actionLoading, error, message, validated } = this.state;

    const { handleSubmit } = this;

    return (
      <Container fluid id="login-page">
        {loading === true && <PageLoadingIndicator loadingText={actionLoading ? "Por favor, aguarde..." : "Carregando informações..."} />}
        {loading === false && error === true && <PageAlert className="m-b-20" message={"Erro"} description={message} type="danger" />}
        {/* {loading === false && error === false && data.length <= 0 && <PageAlert className="m-b-20" message={"Sem informações"} description={"Não há dados para exibir no momento..."} type="info" dismissible />} */}
        {loading === false && (
          <Container>
            <Row>
              <Col xs={12} lg={6} className="login-left-panel d-none d-sm-none d-md-none d-lg-block">
                <p className="font-promo-medium mt-3 welcome-text"> Bem vindo a nossa plataforma, onde <br /> o conhecimento e colaboração se encontram.</p>
              </Col>
              <Col xs={12} lg={6} className="login-right-panel">
                <div id="login-form">
                  <div className="text-center">
                    <img src={loginLogo} id="login-logo" />
                  </div>
                  <h3 className="section-header font-promo-bolder">Faça o seu login:</h3>
                  <Form noValidate validated={validated} onSubmit={(e) => handleSubmit(e)}>
                    <Form.Group as={Row} className="mb-4">
                      <Col sm="12">
                        <Form.Label className="custom-input-label font-promo-light">E-mail</Form.Label>
                        <Form.Control className="custom-input-text border-radius-50" required type="email" name="email" defaultValue={this.state.form.email} placeholder="Digite aqui" onChange={(e) => handleChange(e, this)} />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-4">
                      <Col sm="12">
                        <Form.Label className="custom-input-label  font-promo-light">Senha</Form.Label>
                        <Form.Control className="custom-input-text border-radius-50" required type="password" name="password" defaultValue={this.state.form.password} placeholder="Digite aqui" onChange={(e) => handleChange(e, this)} />
                      </Col>
                    </Form.Group>
                    <Button className="mt-4 custom-primary-btn border-radius-50 font-promo-bold" variant="success" type="submit"> Conectar</Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container >
        )
        }
      </Container>
    );
  };
}

export default LoginPage;