import React from "react";
import { Modal, Button, Table, Form } from "react-bootstrap";

import { handleResultData, handleTaleCheckAll, handleCheckTableItems } from "presentation/utils/functions";

import disciplinesService from "presentation/services/disciplines";
import courseDisciplinesService from "presentation/services/course-disciplines";

import PageLoadingIndicator from "presentation/components/page-loading-indicator";


class AddCoursesDisciplineModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            filterForm: {},
            selectedItems: [],
            data: []
        };

        this.tBodyRef = React.createRef(null);
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        await disciplinesService.findAll().then(async (result) => {
            let disciplines = handleResultData(result);

            this.setState({ data: disciplines, loading: false });
        });
    }

    onSubmit = async () => {

        let { entityId, academicPeriodId, onClose, loadData } = this.props;
        let { selectedItems } = this.state;

        this.setState({ loading: true, error: '' });


        if (selectedItems.length < 1) {
            this.setState({ loading: false, error: true, message: "Por favor, selecione ao menos uma disciplina..." });
        } else {

            let postData = selectedItems.map((e, i) => {
                return {
                    courseId: entityId,
                    disciplineId: e,
                    academicPeriodId: academicPeriodId
                }
            });

            try {
                const response = await courseDisciplinesService.batchAdd(postData);
                if (response.error == true) {
                    this.setState({
                        loading: false,
                        error: true,
                        message: response.message
                    });
                } else {
                    this.setState({
                        loading: false,
                        error: '',
                    }, () => {
                        onClose();
                        loadData(entityId);
                    });

                }
            } catch (err) {
                this.setState({
                    loading: false,
                    actionLoading: false,
                    error: true,
                    message: err.message
                });
            }
        }
    };

    render() {
        let { show, onClose, entityName } = this.props;
        let { data, loading, selectedItems } = this.state;
        const { onSubmit, tBodyRef } = this;
        const hasSelection = selectedItems.length < 1 ? false : true;
        return (
            <Modal fullscreen backdrop="static" show={show || false} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar disciplinas na matriz curricular do curso {entityName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading === true && <PageLoadingIndicator loadingText={"Carregando informações..."} />}
                    {loading === false && data.length > 0 && (
                        <>
                            {data.length > 0 && (
                                <>
                                    <p className="text-center"><strong>Selecione um ou mais disciplinas para incluir no curso</strong></p>
                                    <Table bordered borderless className="mt-4">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "5%" }}><Form.Check className="row-checkbox" type="checkbox" onChange={(e) => handleTaleCheckAll(e, this, tBodyRef)} /></th>
                                                <th>Código</th>
                                                <th>Nome</th>
                                            </tr>
                                        </thead>
                                        <tbody ref={tBodyRef}>
                                            {data.length > 0 && data.map((element, index) => {
                                                return (
                                                    <tr key={element.id}>
                                                        <td><Form.Check className="row-checkbox" type="checkbox" onChange={(e) => handleCheckTableItems(e, element.id, this)} data-option-value={element.id} /></td>
                                                        <td>{element.code}</td>
                                                        <td>{element.name}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="secondary" onClick={onClose}>
                        Mudei de ideia
                    </Button>
                    <Button disabled={loading || !hasSelection} variant="success" onClick={() => onSubmit()}>
                        Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };
}

export default AddCoursesDisciplineModal;